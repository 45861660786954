import {LoadingButton} from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItem,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import React, {ReactNode, useState} from 'react';

import API from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {CloseSnackbarAction} from '../../common/CloseSnackbarButton';

interface SectionItemDeleteButtonProps {
  id: number;
  prefetch?: boolean;
  component?: React.FC;
  componentProps?: any;
  onDeleted?: Function;
  children?: ReactNode;
}

export const SentroGatewayDeleteButton: React.FC<
  SectionItemDeleteButtonProps
> = ({id, component = ListItem, componentProps = {}, onDeleted, children}) => {
  const {enqueueSnackbar} = useSnackbar();
  const Component = component;

  const [isOpened, setIsOpened] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const openModal = () => setIsOpened(true);
  const closeModal = () => setIsOpened(false);

  const submit = async () => {
    setIsSubmitting(true);

    try {
      await API.delete(`${apiBaseUrl}/gateway/${id}`);
      const message = 'Sentro Gateway successfully deleted';

      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      onDeleted?.();
      closeModal();
    } catch (error: any) {
      const message = error?.response?.data?.message ?? 'There is an error';
      enqueueSnackbar(message, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }

    setIsSubmitting(false);
  };

  return (
    <>
      <Component {...componentProps} onClick={openModal}>
        {children}
      </Component>

      <Dialog open={isOpened} onClose={closeModal}>
        <DialogTitle>Delete Sentro 1 Gateway</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this Sentro 1 Gateway?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={closeModal}>Cancel</Button>

          <LoadingButton
            variant="contained"
            loading={isSubmitting}
            autoFocus
            onClick={() => submit()}
            color="error"
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
