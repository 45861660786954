import {LoadingButton} from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppDispatch} from '../../hooks/redux';
import {MachineInput} from '../../interfaces/MachineInput';
import reduxActions from '../../redux/actions';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  item: MachineInput;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onDeleted?: () => void;
}

const MachineInputItemDeleteButton = <T extends ComponentType = typeof Button>({
  item,
  component,
  componentProps,
  children,
  onDeleted,
}: Props<T>) => {
  const Component = component ?? Button;
  const {enqueueSnackbar} = useSnackbar();

  const [isOpened, setIsOpened] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const openModal = () => setIsOpened(true);
  const closeModal = () => setIsOpened(false);
  const reduxDispatch = useAppDispatch();

  const submit = async (pk: number) => {
    setIsSubmitting(true);
    try {
      await API.delete(`${apiBaseUrl}/machine-input/${pk}`);
      const message = 'Machine Input successfully deleted';
      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      reduxDispatch(reduxActions.assets.fetchMachineInputs);
      onDeleted?.();
      closeModal();
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <Component {...componentProps} onClick={openModal}>
        {children}
      </Component>

      <Dialog open={isOpened} onClose={closeModal}>
        <DialogTitle>Delete Machine Input</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this machine input?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>Cancel</Button>
          <LoadingButton
            variant="contained"
            loading={isSubmitting}
            autoFocus
            onClick={() => submit(item.id)}
            color="error"
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MachineInputItemDeleteButton;
