/* eslint-disable complexity */
import CellTowerIcon from '@mui/icons-material/CellTower';
import CloseIcon from '@mui/icons-material/Close';
import {LoadingButton} from '@mui/lab';
import {Box, Button, IconButton, TextField} from '@mui/material';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {useEffect, useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {WifiPoint, WifiPointCreateInputBody} from '../../interfaces/WifiPoint';
import {wifiPointCreateInputSchema} from '../../scheme/yup/wifi-point';
import {
  getIsWifiIpVisible,
  getIsWifiMacRadio1Visible,
  getIsWifiMacRadio2Visible,
  getIsWifiMacSwitchVisible,
} from '../../utils/nodes';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import {MapLatLangCoordinates} from '../common/Map';
import SnackbarMessages from '../common/SnackbarMessages';
import StatusSelect from '../selectors/StatusSelect';
import WifiPointTypeSelect from '../selectors/WifiPointTypeSelect';
import {ZoneSelect} from '../selectors/ZoneSelect';

interface Props {
  locationCoordinates?: MapLatLangCoordinates;
  isActiveModal?: boolean;
  onCancel?: () => void;
  onSubmitted?: (item: WifiPoint) => void;
}

const WifiPointItemCreate = ({
  locationCoordinates,
  isActiveModal,
  onCancel,
  onSubmitted,
}: Props) => {
  /**********/
  /* submit */
  /**********/

  const {enqueueSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async (data: WifiPointCreateInputBody) => {
    setSubmittedInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/node/wifi-point`;
      const resp = await API.post<WifiPoint>(endpoint, {
        ...data,
        ip: getIsWifiIpVisible(data.type) ? data.ip : undefined,
        mac_switch: getIsWifiMacSwitchVisible(data.type)
          ? data.mac_switch
          : undefined,
        mac_radio_1: getIsWifiMacRadio1Visible(data.type)
          ? data.mac_radio_1
          : undefined,
        mac_radio_2: getIsWifiMacRadio2Visible(data.type)
          ? data.mac_radio_2
          : undefined,
      });
      const message = `WiFi Point has been created`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      onSubmitted && onSubmitted(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/

  const getFormikValues = (): WifiPointCreateInputBody => ({
    name: null,
    status: 'active',
    type: 'A',
    zone: null,
    ip: null,
    mac_radio_1: null,
    mac_radio_2: null,
    mac_switch: null,
    serial_number: null,
    latitude: locationCoordinates?.lat
      ? +locationCoordinates.lat.toFixed(6)
      : null,
    longitude: locationCoordinates?.lng
      ? +locationCoordinates.lng.toFixed(6)
      : null,
  });

  const formik = useFormik<WifiPointCreateInputBody>({
    initialValues: getFormikValues(),
    validationSchema: wifiPointCreateInputSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  useEffect(() => {
    if (isActiveModal && locationCoordinates?.lat && locationCoordinates?.lng) {
      const newPosLat = +locationCoordinates.lat.toFixed(6);
      const newPosLong = +locationCoordinates.lng.toFixed(6);
      if (
        formik.values.latitude !== newPosLat ||
        formik.values.longitude !== newPosLong
      ) {
        formik.setFieldValue('latitude', newPosLat);
        formik.setFieldValue('longitude', newPosLong);
      }
    }
  }, [locationCoordinates]);

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      p={3}
      onSubmit={formik.handleSubmit}
    >
      <Box display="flex" justifyContent="space-between">
        <Box
          className="dragHandle"
          display="flex"
          alignItems="center"
          width="100%"
          gap={1.5}
          sx={{cursor: 'grab'}}
        >
          <CellTowerIcon sx={{color: 'primary.main'}} />
          <Box fontSize={24}>Create Wifi Point</Box>
        </Box>
        {onCancel ? (
          <IconButton onClick={() => onCancel()}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </Box>
      <Box display="flex" flexDirection="column" gap={3}>
        <TextField
          value={formik.values.name ?? ''}
          label="Name"
          size="small"
          name="name"
          fullWidth
          error={!!formik.touched.name && !!formik.errors.name}
          helperText={formik.touched.name && formik.errors.name}
          onChange={formik.handleChange}
        />
        <WifiPointTypeSelect
          value={formik.values.type}
          label="Type"
          size="small"
          name="type"
          fullWidth
          error={!!formik.touched.type && !!formik.errors.type}
          helperText={formik.touched.type && formik.errors.type}
          onChange={formik.handleChange}
        />
        {getIsWifiIpVisible(formik.values.type) ? (
          <TextField
            value={formik.values.ip ?? ''}
            label="IP"
            size="small"
            name="ip"
            fullWidth
            error={!!formik.touched.ip && !!formik.errors.ip}
            helperText={formik.touched.ip && formik.errors.ip}
            onChange={formik.handleChange}
          />
        ) : null}
        <TextField
          value={formik.values.serial_number ?? ''}
          label="Serial Number"
          size="small"
          name="serial_number"
          fullWidth
          error={
            !!formik.touched.serial_number && !!formik.errors.serial_number
          }
          helperText={
            formik.touched.serial_number && formik.errors.serial_number
          }
          onChange={formik.handleChange}
        />
        {getIsWifiMacSwitchVisible(formik.values.type) ? (
          <TextField
            value={formik.values.mac_switch ?? ''}
            label="Switch Mac Address"
            size="small"
            name="mac_switch"
            fullWidth
            error={!!formik.touched.mac_switch && !!formik.errors.mac_switch}
            helperText={formik.touched.mac_switch && formik.errors.mac_switch}
            onChange={formik.handleChange}
          />
        ) : null}
        {getIsWifiMacRadio1Visible(formik.values.type) ? (
          <TextField
            value={formik.values.mac_radio_1 ?? ''}
            label="Radio 1 Mac Address"
            size="small"
            name="mac_radio_1"
            fullWidth
            error={!!formik.touched.mac_radio_1 && !!formik.errors.mac_radio_1}
            helperText={formik.touched.mac_radio_1 && formik.errors.mac_radio_1}
            onChange={formik.handleChange}
          />
        ) : null}
        {getIsWifiMacRadio2Visible(formik.values.type) ? (
          <TextField
            value={formik.values.mac_radio_2 ?? ''}
            label="Radio 2 Mac Address"
            size="small"
            name="mac_radio_2"
            fullWidth
            error={!!formik.touched.mac_radio_2 && !!formik.errors.mac_radio_2}
            helperText={formik.touched.mac_radio_2 && formik.errors.mac_radio_2}
            onChange={formik.handleChange}
          />
        ) : null}
        <StatusSelect
          value={formik.values.status}
          fullWidth
          name="status"
          label="Status"
          size="small"
          select
          error={!!formik.touched.status && !!formik.errors.status}
          helperText={formik.touched.status && formik.errors.status}
          onChange={formik.handleChange}
        />
        <TextField
          value={formik.values.latitude ?? ''}
          label="Latitude (click on map or enter manually)"
          size="small"
          name="latitude"
          type="number"
          fullWidth
          error={!!formik.touched.latitude && !!formik.errors.latitude}
          helperText={formik.touched.latitude && formik.errors.latitude}
          onChange={formik.handleChange}
        />
        <TextField
          value={formik.values.longitude ?? ''}
          label="Longitude (click on map or enter manually)"
          size="small"
          name="longitude"
          type="number"
          fullWidth
          error={!!formik.touched.longitude && !!formik.errors.longitude}
          helperText={formik.touched.longitude && formik.errors.longitude}
          onChange={formik.handleChange}
        />
        <ZoneSelect
          value={formik.values.zone}
          label="Section"
          size="small"
          fullWidth
          error={!!formik.touched.zone && !!formik.errors.zone}
          helperText={formik.touched.zone && formik.errors.zone}
          onChange={(v) => formik.setFieldValue('zone', v)}
        />
      </Box>

      <Box display="flex" justifyContent="end" gap={1}>
        {onCancel ? <Button onClick={() => onCancel()}>Cancel</Button> : null}
        <Box>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={submittedInProgress}
          >
            Create
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default WifiPointItemCreate;
