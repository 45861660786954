import {LoadingButton} from '@mui/lab';
import {Box} from '@mui/material';
import dayjs from 'dayjs';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {saveFile} from '../../../utils/file';
import {CloseSnackbarAction} from '../../common/CloseSnackbarButton';
import ExportFieldForm from '../../common/ExportFieldForm';
import ModalFixed from '../../common/ModalFixed';
import SnackbarMessages from '../../common/SnackbarMessages';
import {EventsReportData} from '../ConnectView/EventsReport';

interface Props {
  value?: EventsReportData;
}

const ObjectsByCommunicationNodeTrackingExportExcel = ({value}: Props) => {
  const {enqueueSnackbar} = useSnackbar();
  const [isFetching, setIsFetching] = useState(false);

  const [isOpened, setIsOpened] = useState(false);

  const exportFieldOptions = [
    {field: 'id', label: 'ID', hidden: false},
    {field: 'nickname', label: 'Nickname', hidden: false},
    {field: 'first_name', label: 'First Name', hidden: false},
    {field: 'network_id', label: 'Network ID', hidden: false},
    {field: 'timestamp', label: 'Timestamp', hidden: false},
    {field: 'message_type', label: 'Message Type', hidden: false},
    {field: 'motion_sensor_value', label: 'Motion State', hidden: false},
    {field: 'status', label: 'Status', hidden: false},
    {field: 'commtrac_current_zone_name', label: 'Section', hidden: false},
    {field: 'strongest_cn_name', label: 'Strongest Node', hidden: false},
    {field: 'pos_lon', label: 'Longitude', hidden: false},
    {field: 'pos_lat', label: 'Latitude', hidden: false},
    {field: 'beacon_data', label: 'Beacon Data', hidden: false},
  ];

  const checkFields = () => {
    setIsOpened(true);
  };

  const fetchData = async (fields: string[]) => {
    setIsOpened(false);
    setIsFetching(true);
    console.log(value);
    console.log(fields);
    console.log('yes');
    try {
      const params = {
        export: 'excel',
        fields,
        commtrac_external_id: value?.commtrac_external_id,
        date_start: value?.params.date_start,
        date_end: value?.params.date_end,
        message_type: value?.params.message_type,
        by_strongest_cn_only: value?.by_strongest_cn_only,
        event_type: value?.event_type,
        asset_type: value?.asset_type,
      };

      const resp = await API.get(`${apiBaseUrl}/communication-node/events`, {
        params,
        responseType: 'blob',
      });
      saveFile(
        resp.data,
        `events_${value?.params.date_start ?? dayjs().format('YYYY-MM-DD')}_${value?.params.date_end ?? dayjs().format('YYYY-MM-DD')}.xlsx`
      );
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setIsFetching(false);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" justifyContent="end">
        <LoadingButton
          fullWidth
          size="large"
          variant="contained"
          loading={isFetching}
          onClick={() => checkFields()}
        >
          Export to Excel
        </LoadingButton>
      </Box>
      <ModalFixed open={isOpened} onClose={() => setIsOpened(false)}>
        <Box
          display="flex"
          flexDirection="column"
          position="relative"
          gap={3}
          p={3}
        >
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center" width="100%" gap={1.5}>
              <ExportFieldForm
                exportFields={exportFieldOptions}
                onChange={fetchData}
                close={() => setIsOpened(false)}
                loading={isFetching}
                columns={3}
              ></ExportFieldForm>
            </Box>
          </Box>
        </Box>
      </ModalFixed>
    </Box>
  );
};

export default ObjectsByCommunicationNodeTrackingExportExcel;
