import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  MenuItem,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import _ from 'lodash';
import {useSnackbar} from 'notistack';
import {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';
import * as yup from 'yup';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppDispatch} from '../../hooks/redux';
import {Layer, LayerInputBody, LayerItemResponse} from '../../interfaces/Layer';
import reduxActions from '../../redux/actions';
import assetsSelectors from '../../redux/assets/selectors';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

interface LayerItemUpsertProps {
  pk?: Layer['id'];
  item?: Layer;
  prefetch?: boolean;
  onCancel?: Function;
  onSubmitted?: () => void;
}

export const LayerItemUpsert: React.FC<LayerItemUpsertProps> = ({
  pk,
  item,
  prefetch,
  onCancel,
  onSubmitted,
}) => {
  const assets = useSelector(assetsSelectors.getAssets);
  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] = useState<LayerItemResponse | undefined>(
    _.cloneDeep(item ? item : undefined)
  );
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const resp = await API.get<LayerItemResponse>(
        `${apiBaseUrl}/layer/${pk}`
      );
      setFetchedData(resp.data);
    } catch (error: any) {
      const message = error?.response?.data?.message ?? 'There is an error';
      setFetchedErrors([message]);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }

    return () => {
      setFetchedData(undefined);
      setFetchedErrors([]);
    };
  }, [pk, prefetch]);

  useEffect(() => {
    const newFetchedData = item;
    if (!_.isEqual(newFetchedData, fetchedData)) {
      setFetchedData(newFetchedData);
    }
  }, [item]);

  /**********/
  /* submit */
  /**********/
  const {enqueueSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);
  const reduxDispatch = useAppDispatch();

  const submitData = async (data: LayerInputBody) => {
    setSubmittedInProgress(true);

    try {
      const endpoint = pk ? `${apiBaseUrl}/layer/${pk}` : `${apiBaseUrl}/layer`;
      if (pk) {
        await API.patch(endpoint, data);
      } else {
        await API.post(endpoint, data);
      }

      const message = `Layer has been ${pk ? 'updated' : 'created'}`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      reduxDispatch(reduxActions.assets.fetchLayers);
      onSubmitted?.();
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }

    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/
  const inputValidationSchema = useMemo(() => {
    const fields: any = {
      name: yup.string().nullable().required('Field is required'),
      status: yup.string().nullable().required('Field is required'),
    };

    return yup.object().shape(fields);
  }, [pk]);

  const getFormikValues = (item?: Layer): LayerInputBody => ({
    name: item?.name ?? null,
    status: item?.status ?? 'active',
    z_index: 0,
    layer_map_id: item?.layer_map_id ?? null,
  });

  const formik = useFormik<LayerInputBody>({
    initialValues: getFormikValues(fetchedData),
    validationSchema: inputValidationSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  useEffect(() => {
    const newFormikValues = getFormikValues(fetchedData);
    if (!_.isEqual(newFormikValues, formik.values)) {
      formik.setValues(newFormikValues);
    }
  }, [fetchedData]);

  return (
    <Box component="form" position="relative" onSubmit={formik.handleSubmit}>
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box my={4}>
        <TextField
          value={formik.values.name ?? ''}
          fullWidth
          name="name"
          label="Name"
          size="small"
          error={!!formik.touched.name && !!formik.errors.name}
          helperText={formik.touched.name && formik.errors.name}
          onChange={(event) => {
            formik.setFieldValue('name', event.target.value || null);
          }}
        />
      </Box>

      <Box my={4}>
        <TextField
          value={formik.values.status ?? ''}
          fullWidth
          name="status"
          label="Status"
          size="small"
          select
          error={!!formik.touched.status && !!formik.errors.status}
          helperText={formik.touched.status && formik.errors.status}
          onChange={formik.handleChange}
        >
          {[
            {value: 'active', name: 'Active'},
            {value: 'inactive', name: 'Inactive'},
          ].map((i) => (
            <MenuItem key={i.value} value={i.value}>
              {i.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <Box my={4}>
        <TextField
          value={formik.values.layer_map_id ?? 'No Map'}
          fullWidth
          name="layer_map_id"
          label="Map"
          size="small"
          select
          error={!!formik.touched.layer_map_id && !!formik.errors.layer_map_id}
          helperText={formik.touched.layer_map_id && formik.errors.layer_map_id}
          onChange={(event) => {
            const value =
              event.target.value === 'No Map' ? null : event.target.value;
            formik.setFieldValue('layer_map_id', value);
          }}
        >
          <MenuItem value="No Map">No Map</MenuItem>

          {assets.maps.map((i) => (
            <MenuItem key={i.id} value={i.id}>
              {i.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error" sx={{my: 2}}>
          {error}
        </Alert>
      ))}

      <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button onClick={() => onCancel?.()}>Cancel</Button>

        <LoadingButton
          sx={{ml: 1}}
          variant="contained"
          loading={submittedInProgress}
          type="submit"
        >
          Submit
        </LoadingButton>
      </Box>
    </Box>
  );
};
