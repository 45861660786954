import {LoadingButton} from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {AssetHuman} from '../../interfaces/AssetHuman';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

interface Props extends Omit<DialogProps, 'onClose'> {
  item: AssetHuman;
  onSubmitted?: (item: AssetHuman) => void;
  onClose?: () => void;
}

const AssetHumanItemActivationModal = ({
  item,
  onSubmitted,
  onClose,
  ...props
}: Props) => {
  const {enqueueSnackbar} = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const actionName = item.status === 'active' ? 'Deactivate' : 'Activate';

  const submit = async (pk: number) => {
    setIsSubmitting(true);
    try {
      const resp = await API.patch<AssetHuman>(
        `${apiBaseUrl}/asset-human/${pk}`,
        {
          status: actionName === 'Activate' ? 'active' : 'inactive',
          is_commtrac: !!item?.commtrac_external_id || !!item?.mac_address,
          is_proximity: !!item?.external_id,
        }
      );
      const message = `Employee successfully ${actionName === 'Activate' ? 'activated' : 'deactivated'}`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      onClose?.();
      onSubmitted?.(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setIsSubmitting(false);
  };

  return (
    <Dialog {...props} onClose={onClose}>
      <DialogTitle>{actionName} Employee</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Are you sure you want to {actionName} this employee?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose?.()}>Cancel</Button>
        <LoadingButton
          variant="contained"
          loading={isSubmitting}
          autoFocus
          onClick={() => submit(item.id)}
          color={actionName === 'Activate' ? 'success' : 'warning'}
        >
          {actionName}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AssetHumanItemActivationModal;
