import {LoadingButton} from '@mui/lab';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {useMemo, useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {AssetHuman} from '../../interfaces/AssetHuman';
import {
  CommtracNode,
  CommutracNodeMinerAcknowledgeInputBody,
} from '../../interfaces/CommtracNode';
import reduxActions from '../../redux/actions';
import reduxSelectors from '../../redux/selectors';
import {commtracNodeMinerAcknowledgeInputSchema} from '../../scheme/yup/commtrac-node';
import {maxPersonIdLength, maxProximityId} from '../../scheme/yup/utils';
import {getBooleanValue} from '../../utils/boolean';
import {
  getIsEmployeeAtestVisible,
  getIsEmployeeHasMotionSensorVisible,
  getIsEmployeeMc2FlagVisible,
  getIsEmployeeSensorNumberVisible,
  getIsMaxVoltageVisible,
  getIsMinVoltageVisible,
} from '../../utils/commtrac-nodes';
import AssetHumanSelect from '../asset-human/AssetHumanSelect';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import NumberTextField from '../common/NumberTextField';
import SnackbarMessages from '../common/SnackbarMessages';
import {AssetHumanTypeSelect} from '../selectors/AssetHumanTypeSelect';
import {DateSelect} from '../selectors/DateSelect';
import {ShiftSelect} from '../selectors/ShiftSelect';
import StatusSelect from '../selectors/StatusSelect';
import {ZoneSelect} from '../selectors/ZoneSelect';
import CommtracNodeItemMinerMoveToAssetButton from './CommtracNodeItemMinerMoveToAssetButton';

interface Props {
  item: CommtracNode;
  onCancel?: () => void;
  onSubmitted?: (item: CommtracNode) => void;
}

type AcknowledgeInputBody = CommutracNodeMinerAcknowledgeInputBody & {
  wifi_enabled: boolean | null;
  is_proximity: boolean;
  asset_human_id: number | null;
  assign_to_human: boolean | null;
};

// eslint-disable-next-line complexity
const CommtracNodeItemMinerAcknowledge = ({
  item,
  onCancel,
  onSubmitted,
}: Props) => {
  const reduxDispatch = useAppDispatch();

  /**********/
  /* submit */
  /**********/

  const {enqueueSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const minerAddressMask = useAppSelector(
    ({assets}) => assets.constants?.miner.address_mask
  );
  const company = useAppSelector(({assets}) => assets.company);

  const getHumanReadable = (commtrac_external_id: number | null) => {
    if (commtrac_external_id && minerAddressMask) {
      // eslint-disable-next-line no-bitwise
      return commtrac_external_id & minerAddressMask;
    }

    return null;
  };

  const submitData = async (data: AcknowledgeInputBody) => {
    setSubmittedInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/commtrac-node/${item.id}/miner/acknowledge`;
      const resp = await API.patch<CommtracNode>(endpoint, {
        ...data,
        birth_date: data.birth_date
          ? dayjs(data.birth_date).format('YYYY-MM-DD')
          : null,
        wifi_enabled: undefined,
        mc2_flag: isEmployeeMc2FlagVisible ? data.mc2_flag : undefined,
        atest: isEmployeeAtestVisible ? data.atest : undefined,
        sensors_number: isEmployeeSensorNumberVisible
          ? data.sensors_number
          : undefined,
        has_motion_sensor: isEmployeeHasMotionSensorVisible
          ? data.has_motion_sensor
          : undefined,
        min_voltage: isMinVoltageVisible ? data.min_voltage : undefined,
        max_voltage: isMaxVoltageVisible ? data.max_voltage : undefined,
      });
      const message = `Employees has been acknowledged`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      onSubmitted?.(resp.data);
      reduxDispatch(reduxActions.assets.fetchAssetHumans);
      reduxDispatch(reduxActions.assets.fetchCommtracNodes);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/

  const shifts = useAppSelector(({assets}) => assets.shifts);
  const zones = useAppSelector(({assets}) => assets.zones);
  const assetHumanTypes = useAppSelector(
    ({assets}) => assets.asset_human_types
  );
  const assets = useAppSelector(reduxSelectors.assets.getAssets);
  const assetHumans = useMemo(() => assets.asset_humans, [assets.asset_humans]);
  const [selectedHuman, setSelectedHuman] = useState<
    AssetHuman | null | undefined
  >(null);

  const getFormikValues = (item: CommtracNode): AcknowledgeInputBody => ({
    assign_to_human: null,
    asset_human_id: null,
    is_proximity: false,
    wifi_enabled: !!item.wifi_enabled,
    nickname: item.h_nickname ?? null,
    first_name: item.h_first_name ?? null,
    last_name: item.h_last_name ?? null,
    birth_date: item.h_birth_date ? dayjs(item.h_birth_date).toDate() : null,
    description: item.description ?? '',
    type_id: item?.h_type_id
      ? assetHumanTypes.find((i) => i.id === item.h_type_id)?.id ?? null
      : null,
    zone_id: item?.commtrac_current_zone_id
      ? zones.find((i) => i.id === item.commtrac_current_zone_id)?.id ?? null
      : null,
    shift_id: item?.h_shift_id
      ? shifts.find((i) => i.id === item.h_shift_id)?.id ?? null
      : null,
    status: 'active',
    personal_id: item.h_personal_id ?? null,
    external_id: item.external_id ?? null,
    mc2_flag: item?.mc2_flag ?? null,
    atest: item?.atest === '1' ? 1 : 0,
    sensors_number: item.sensors_number ?? null,
    has_motion_sensor: item?.has_motion_sensor ?? 0,
    min_voltage: item.min_voltage ?? null,
    max_voltage: item.max_voltage ?? null,
  });

  const formik = useFormik<AcknowledgeInputBody>({
    initialValues: getFormikValues(item),
    validationSchema: commtracNodeMinerAcknowledgeInputSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  const isEmployeeMc2FlagVisible = getIsEmployeeMc2FlagVisible(
    true,
    formik.values.wifi_enabled
  );
  const isEmployeeAtestVisible =
    false && getIsEmployeeAtestVisible(true, formik.values.wifi_enabled);
  const isEmployeeSensorNumberVisible = getIsEmployeeSensorNumberVisible(
    true,
    formik.values.wifi_enabled,
    formik.values.atest
  );
  const isEmployeeHasMotionSensorVisible = getIsEmployeeHasMotionSensorVisible(
    true,
    formik.values.wifi_enabled
  );
  const isMinVoltageVisible = getIsMinVoltageVisible(
    true,
    formik.values.wifi_enabled
  );
  const isMaxVoltageVisible = getIsMaxVoltageVisible(
    true,
    formik.values.wifi_enabled
  );

  const displayNewMachineFields = useMemo(
    () =>
      formik.values.assign_to_human !== null && !formik.values.assign_to_human,
    [formik.values]
  );
  const displayAssignMachineFields = useMemo(
    () =>
      formik.values.assign_to_human !== null && formik.values.assign_to_human,
    [formik.values]
  );
  const displayProximityIdField = useMemo(
    () =>
      company?.proximity_enabled &&
      ((formik.values.assign_to_human &&
        selectedHuman &&
        !selectedHuman?.external_id) ||
        (!formik.values.assign_to_human && formik.values.is_proximity)),
    [formik.values, selectedHuman]
  );

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      onSubmit={formik.handleSubmit}
    >
      <Box display="flex" flexDirection="column" gap={3}>
        {formik.values.wifi_enabled ? (
          <TextField
            value={item.mac_address}
            label="Mac Address"
            size="small"
            name="name"
            fullWidth
            disabled
          />
        ) : (
          <TextField
            value={getHumanReadable(item.commtrac_external_id)}
            label="Network ID"
            size="small"
            name="name"
            fullWidth
            disabled
          />
        )}

        <FormControl>
          <FormLabel id="demo-form-control-label-placement">
            Assign this device to an existing employee?
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-form-control-label-placement"
            name="position"
            defaultValue="top"
          >
            <FormControlLabel
              label="Yes"
              control={
                <Radio
                  checked={
                    formik.values.assign_to_human !== null &&
                    formik.values.assign_to_human
                  }
                  onChange={() => {
                    formik.setFieldValue('assign_to_human', true);
                  }}
                  value="true"
                  name="radio-buttons"
                  inputProps={{'aria-label': 'Yes'}}
                />
              }
            />
            <FormControlLabel
              label="No"
              control={
                <Radio
                  checked={
                    formik.values.assign_to_human !== null &&
                    !formik.values.assign_to_human
                  }
                  onChange={() => {
                    formik.setFieldValue('assign_to_human', false);
                  }}
                  value="true"
                  name="radio-buttons"
                  inputProps={{'aria-label': 'No'}}
                />
              }
            />
          </RadioGroup>
          <FormHelperText error>
            {formik.errors.assign_to_human ? 'Field is required' : ''}
          </FormHelperText>
        </FormControl>

        {displayAssignMachineFields ? (
          <AssetHumanSelect
            value={formik.values.asset_human_id}
            size="small"
            label="Select an Employee"
            fullWidth
            nullLabel=""
            assetHumans={assetHumans}
            error={
              !!formik.touched.asset_human_id && !!formik.errors.asset_human_id
            }
            onChange={(_, item) => {
              formik.setFieldValue('asset_human_id', item?.id ?? null);
              setSelectedHuman(item);
            }}
          />
        ) : null}

        {displayNewMachineFields ? (
          <TextField
            value={formik.values.nickname ?? ''}
            label="Nickname"
            size="small"
            name="nickname"
            fullWidth
            error={!!formik.touched.nickname && !!formik.errors.nickname}
            helperText={formik.touched.nickname && formik.errors.nickname}
            onChange={(e) =>
              formik.setFieldValue(e.target.name, e.target.value || null)
            }
          />
        ) : null}

        {displayNewMachineFields ? (
          <TextField
            value={formik.values.first_name ?? ''}
            label="First Name"
            size="small"
            name="first_name"
            fullWidth
            error={!!formik.touched.first_name && !!formik.errors.first_name}
            helperText={formik.touched.first_name && formik.errors.first_name}
            onChange={(e) =>
              formik.setFieldValue(e.target.name, e.target.value || null)
            }
          />
        ) : null}

        {displayNewMachineFields ? (
          <TextField
            value={formik.values.last_name ?? ''}
            label="Last Name"
            size="small"
            name="last_name"
            fullWidth
            error={!!formik.touched.last_name && !!formik.errors.last_name}
            helperText={formik.touched.last_name && formik.errors.last_name}
            onChange={(e) =>
              formik.setFieldValue(e.target.name, e.target.value || null)
            }
          />
        ) : null}

        {displayNewMachineFields ? (
          <DateSelect
            value={formik.values.birth_date}
            renderInput={{
              label: 'Date of Birth',
              size: 'small',
              fullWidth: true,
              error: !!formik.touched.birth_date && !!formik.errors.birth_date,
              helperText: formik.touched.birth_date && formik.errors.birth_date,
            }}
            onChange={(value) => formik.setFieldValue('birth_date', value)}
          />
        ) : null}

        {displayNewMachineFields ? (
          <TextField
            value={formik.values.description ?? ''}
            label="Description"
            size="small"
            name="description"
            multiline
            rows={3}
            fullWidth
            error={!!formik.touched.description && !!formik.errors.description}
            helperText={formik.touched.description && formik.errors.description}
            onChange={(e) =>
              formik.setFieldValue(e.target.name, e.target.value || null)
            }
          />
        ) : null}

        {displayNewMachineFields ? (
          <AssetHumanTypeSelect
            value={formik.values.type_id}
            label="Job Type"
            size="small"
            fullWidth
            error={!!formik.touched.type_id && !!formik.errors.type_id}
            helperText={formik.touched.type_id && formik.errors.type_id}
            onChange={(v) => formik.setFieldValue('type_id', v)}
          />
        ) : null}

        {displayNewMachineFields ? (
          <ZoneSelect
            value={formik.values.zone_id}
            label="Assigned Section"
            size="small"
            fullWidth
            error={!!formik.touched.zone_id && !!formik.errors.zone_id}
            helperText={formik.touched.zone_id && formik.errors.zone_id}
            onChange={(v) => formik.setFieldValue('zone_id', v)}
          />
        ) : null}

        {displayNewMachineFields ? (
          <ShiftSelect
            value={formik.values.shift_id}
            label="Assigned Shift"
            size="small"
            fullWidth
            error={!!formik.touched.shift_id && !!formik.errors.shift_id}
            helperText={formik.touched.shift_id && formik.errors.shift_id}
            onChange={(v) => formik.setFieldValue('shift_id', v)}
          />
        ) : null}

        {displayNewMachineFields ? (
          <StatusSelect
            value={formik.values.status}
            fullWidth
            name="status"
            label="Status"
            size="small"
            select
          />
        ) : null}

        {displayNewMachineFields ? (
          <TextField
            value={formik.values.personal_id ?? ''}
            label="Employee ID"
            size="small"
            name="personal_id"
            fullWidth
            error={!!formik.touched.personal_id && !!formik.errors.personal_id}
            helperText={formik.touched.personal_id && formik.errors.personal_id}
            inputProps={{maxLength: maxPersonIdLength}}
            onChange={(e) =>
              formik.setFieldValue(e.target.name, e.target.value || null)
            }
          />
        ) : null}

        {formik.values.assign_to_human !== null && isEmployeeMc2FlagVisible ? (
          <FormControl>
            <FormLabel id="demo-form-control-label-placement">MC2</FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              defaultValue="top"
            >
              <FormControlLabel
                label="Yes"
                control={
                  <Radio
                    checked={!!formik.values.mc2_flag}
                    onChange={() => {
                      formik.setFieldValue('mc2_flag', 1);
                    }}
                    value="true"
                    name="radio-buttons"
                    inputProps={{'aria-label': 'Yes'}}
                  />
                }
              />
              <FormControlLabel
                label="No"
                control={
                  <Radio
                    checked={!formik.values.mc2_flag}
                    onChange={() => {
                      formik.setFieldValue('mc2_flag', 0);
                    }}
                    value="true"
                    name="radio-buttons"
                    inputProps={{'aria-label': 'No'}}
                  />
                }
              />
            </RadioGroup>
          </FormControl>
        ) : null}

        {isEmployeeAtestVisible ? (
          <TextField
            value={getBooleanValue(formik.values.atest, 0, 1) ?? ''}
            label="ATest"
            size="small"
            name="atest"
            select
            fullWidth
            error={!!formik.touched.atest && !!formik.errors.atest}
            helperText={formik.touched.atest && formik.errors.atest}
            onChange={formik.handleChange}
          >
            {[
              {value: 1, name: 'Yes'},
              {value: 0, name: 'No'},
            ].map((i) => (
              <MenuItem key={i.name} value={i.value}>
                {i.name}
              </MenuItem>
            ))}
          </TextField>
        ) : null}

        {isEmployeeSensorNumberVisible ? (
          <TextField
            value={formik.values.sensors_number ?? ''}
            label="Number of Sensors"
            size="small"
            name="sensors_number"
            select
            fullWidth
            error={
              !!formik.touched.sensors_number && !!formik.errors.sensors_number
            }
            helperText={
              formik.touched.sensors_number && formik.errors.sensors_number
            }
            onChange={formik.handleChange}
          >
            {[0, 1, 2, 3, 4].map((i) => (
              <MenuItem key={i} value={i}>
                {i}
              </MenuItem>
            ))}
          </TextField>
        ) : null}

        {formik.values.assign_to_human !== null &&
        isEmployeeHasMotionSensorVisible ? (
          <FormControl>
            <FormLabel id="demo-form-control-label-placement">
              Has a Motion Sensor
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              defaultValue="top"
            >
              <FormControlLabel
                label="Yes"
                control={
                  <Radio
                    checked={!!formik.values.has_motion_sensor}
                    onChange={() => {
                      formik.setFieldValue('has_motion_sensor', 1);
                    }}
                    value="true"
                    name="radio-buttons"
                    inputProps={{'aria-label': 'Yes'}}
                  />
                }
              />
              <FormControlLabel
                label="No"
                control={
                  <Radio
                    checked={!formik.values.has_motion_sensor}
                    onChange={() => {
                      formik.setFieldValue('has_motion_sensor', 0);
                    }}
                    value="true"
                    name="radio-buttons"
                    inputProps={{'aria-label': 'No'}}
                  />
                }
              />
            </RadioGroup>
          </FormControl>
        ) : null}

        {formik.values.assign_to_human !== null && isMinVoltageVisible ? (
          <NumberTextField
            value={
              formik.values.min_voltage
                ? formik.values.min_voltage / 10
                : formik.values.min_voltage
            }
            decimalPlaces={1}
            min={0}
            max={10}
            step={0.1}
            label="Min Voltage"
            size="small"
            name="min_voltage"
            fullWidth
            error={!!formik.touched.min_voltage && !!formik.errors.min_voltage}
            helperText={formik.touched.min_voltage && formik.errors.min_voltage}
            onChange={(v) =>
              formik.setFieldValue('min_voltage', v ? v * 10 : v)
            }
          />
        ) : null}

        {formik.values.assign_to_human !== null && isMaxVoltageVisible ? (
          <NumberTextField
            value={
              formik.values.max_voltage
                ? formik.values.max_voltage / 10
                : formik.values.max_voltage
            }
            decimalPlaces={1}
            min={0}
            max={10}
            step={0.1}
            label="Max Voltage"
            size="small"
            name="max_voltage"
            fullWidth
            error={!!formik.touched.max_voltage && !!formik.errors.max_voltage}
            helperText={formik.touched.max_voltage && formik.errors.max_voltage}
            onChange={(v) =>
              formik.setFieldValue('max_voltage', v ? v * 10 : v)
            }
          />
        ) : null}

        {displayNewMachineFields && company?.proximity_enabled ? (
          <FormControl>
            <FormLabel id="demo-form-control-label-placement">
              Proximity enabled employee
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              defaultValue="top"
            >
              <FormControlLabel
                label="Yes"
                control={
                  <Radio
                    checked={!!formik.values.is_proximity}
                    onChange={() => {
                      formik.setFieldValue('is_proximity', true);
                    }}
                    value="true"
                    name="radio-buttons"
                    inputProps={{'aria-label': 'Yes'}}
                  />
                }
              />
              <FormControlLabel
                label="No"
                control={
                  <Radio
                    checked={!formik.values.is_proximity}
                    onChange={() => {
                      formik.setFieldValue('is_proximity', false);
                    }}
                    value="true"
                    name="radio-buttons"
                    inputProps={{'aria-label': 'No'}}
                  />
                }
              />
            </RadioGroup>
          </FormControl>
        ) : null}

        {displayProximityIdField ? (
          <NumberTextField
            value={formik.values.external_id}
            min={1}
            max={maxProximityId}
            label="Proximity ID"
            size="small"
            name="external_id"
            fullWidth
            error={!!formik.touched.external_id && !!formik.errors.external_id}
            helperText={formik.touched.external_id && formik.errors.external_id}
            onChange={(v) => formik.setFieldValue('external_id', v)}
          />
        ) : null}
      </Box>

      <Box display="flex" justifyContent="space-between">
        {item.wifi_enabled ? (
          <CommtracNodeItemMinerMoveToAssetButton
            pk={item.id}
            onSubmitted={onSubmitted}
          >
            Move to Asset
          </CommtracNodeItemMinerMoveToAssetButton>
        ) : (
          <Box />
        )}

        <Box display="flex" justifyContent="end" gap={1}>
          {onCancel ? <Button onClick={() => onCancel()}>Cancel</Button> : null}
          <Box>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={submittedInProgress}
            >
              Acknowledge
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CommtracNodeItemMinerAcknowledge;
