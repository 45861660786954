import {LoadingButton} from '@mui/lab';
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import _ from 'lodash';
import {useSnackbar} from 'notistack';
import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import * as yup from 'yup';

import {useAppDispatch} from '../../hooks/redux';
import {
  HumanGroupEmployee,
  HumanGroupGridItem,
} from '../../interfaces/HumanGroup';
import {
  createHumanGroup,
  fetchEmployees,
  fetchSelectedHumanTypes,
  fetchSelectedSections,
  updateHumanGroup,
} from '../../redux/admin/actions';
import {
  humanGroupEmployees,
  humanGroupSelectedSections,
  humanGroupSelectedTypes,
  humanGroupTabLoading,
} from '../../redux/admin/selectors';
import {getEmployessIdsByField} from '../../utils/humanGroups';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import {HumaGroupSections} from './HumaGroupSections';
import {HumaGroupTypes} from './HumaGroupTypes';
import {HumanGroupTable} from './HumanGroupTable';

interface GroupItemUpsertProps {
  itemId?: HumanGroupGridItem['id'];
  groupName: string | undefined;
  onCancel?: Function;
  onSubmitted?: () => void;
}

interface GroupSelectedField {
  sections: number[];
  types: number[];
  employees: number[];
}

interface GroupForm extends GroupSelectedField {
  name: string;
  order: string;
  dir: string;
  page: number;
  limit: number;
}

const inputValidationSchema = yup.object().shape({
  name: yup
    .string()
    .nullable()
    .matches(
      /^[a-zA-Z]/,
      'This' +
        ' field should start from letter, only contain letters, numbers, spaces and special characters: -_'
    )
    .min(2, 'Minimum length 2')
    .required('Field is required'),
  sections: yup.array(),
  types: yup.array(),
  employees: yup.array(),
});

export const GroupItemUpsert: React.FC<GroupItemUpsertProps> = ({
  itemId,
  groupName,
  onCancel,
  onSubmitted,
}) => {
  const dispatch = useAppDispatch();

  const types = useSelector(humanGroupSelectedTypes);
  const sections = useSelector(humanGroupSelectedSections);
  const employees = useSelector(humanGroupEmployees);
  const loading = useSelector(humanGroupTabLoading);

  const [shownTable, setShownTable] = useState<boolean>(true);

  const {enqueueSnackbar} = useSnackbar();

  const getFormikValues = (item?: any) => ({
    name: item?.groupName ?? '',
    sections: item?.sections ?? [],
    types: item?.types ?? [],
    employees: item?.employees ?? [],
    order: 'id',
    dir: 'ASC',
    page: 0,
    limit: 25,
  });

  const formik = useFormik<GroupForm>({
    initialValues: getFormikValues({groupName}),
    validationSchema: inputValidationSchema,
    onSubmit: async (values, formikHelpers) => {
      await formikHelpers.validateForm(values);
      const hasMembers =
        [
          ...formik.values.types,
          ...formik.values.sections,
          ...formik.values.employees,
        ].length > 0;
      if (!hasMembers) {
        enqueueSnackbar('Group should contain at least one member.', {
          variant: 'error',
          action: CloseSnackbarAction,
        });
        return;
      }
      if (shownTable && values.employees.length > 10) {
        enqueueSnackbar("Group shouldn't contain more than 10 members.", {
          variant: 'error',
          action: CloseSnackbarAction,
        });
        return;
      }
      const message = `Group has been ${itemId ? 'updated' : 'created'}`;
      //@ts-ignore
      const getEmployeeAssetIdsForRequest: number[] = employees.reduce(
        (acc: number[], e: HumanGroupEmployee) => {
          if (e.employee_asset_id && formik.values.employees.includes(e.id)) {
            acc.push(e.employee_asset_id);
          }
          return acc;
        },
        []
      );

      try {
        if (itemId) {
          dispatch(
            updateHumanGroup({
              groupId: itemId,
              formikValues: {
                name: values.name,
                sections: values.sections,
                types: values.types,
                employees: getEmployeeAssetIdsForRequest,
              },
            })
          );
        } else {
          dispatch(
            createHumanGroup({
              name: values.name,
              sections: values.sections,
              types: values.types,
              employees: getEmployeeAssetIdsForRequest,
            })
          );
        }

        enqueueSnackbar(message, {
          variant: 'success',
          action: CloseSnackbarAction,
        });
        onSubmitted?.();
      } catch (error: any) {
        const message = error?.response?.data?.message ?? 'There is an error';
        enqueueSnackbar(message, {
          variant: 'error',
          action: CloseSnackbarAction,
        });
      }
    },
  });

  useEffect(() => {
    dispatch(fetchSelectedHumanTypes(itemId));
    dispatch(fetchSelectedSections(itemId));
    dispatch(fetchEmployees(itemId));

    return () => formik.resetForm();
  }, []);

  useEffect(() => {
    const ids: number[] = [];
    employees.forEach((item) => {
      if (item.checked) {
        ids.push(item.id);
      }
    });
    formik.setFieldValue('employees', ids);
  }, [employees]);

  useEffect(() => {
    if (types.length > 0) {
      const ids: number[] = [];
      types.forEach((item) => {
        if (item.checked) {
          ids.push(item.id);
        }
      });
      formik.setFieldValue('types', ids);
    }
  }, [types]);

  useEffect(() => {
    if (sections) {
      const ids: number[] = [];
      sections.forEach((item) => {
        if (item.checked) {
          ids.push(item.id);
        }
      });
      formik.setFieldValue('sections', ids);
    }
  }, [sections]);

  useEffect(() => {
    setShownTable(formik.values.types.length !== 1);
  }, [formik.values.types]);

  const fieldHandle = useCallback(
    (fieldName: string, itemId: number) => {
      if (
        formik.values[fieldName as keyof GroupSelectedField].includes(itemId)
      ) {
        formik.setFieldValue(
          fieldName,
          formik.values[fieldName as keyof GroupSelectedField].filter(
            (el) => el !== itemId
          )
        );
        if (fieldName !== 'employees') {
          const ids = getEmployessIdsByField(fieldName, itemId, employees);
          formik.setFieldValue(
            'employees',
            _.difference(formik.values.employees, ids)
          );
        }
      } else {
        formik.setFieldValue(fieldName, [
          ...formik.values[fieldName as keyof GroupSelectedField],
          itemId,
        ]);
        if (fieldName !== 'employees') {
          const ids = getEmployessIdsByField(fieldName, itemId, employees);
          formik.setFieldValue(
            'employees',
            _.uniq([...formik.values.employees, ...ids])
          );
        }
      }
    },
    [
      formik.values.types,
      formik.values.sections,
      formik.values.employees,
      employees,
    ]
  );

  return (
    <Box component="form" position="relative" onSubmit={formik.handleSubmit}>
      <Backdrop open={loading === 'pending'} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box my={4}>
        <TextField
          value={formik.values.name}
          fullWidth
          name="name"
          label="Group Name"
          size="small"
          helperText={
            formik.touched.name &&
            formik.errors.name &&
            typeof formik.errors.name === 'string' &&
            formik.errors.name
          }
          error={!!formik.touched.name && !!formik.errors.name}
          onChange={formik.handleChange}
        />
      </Box>

      <HumaGroupSections
        sections={formik.values.sections}
        sectionsList={sections}
        fieldHandle={fieldHandle}
      />
      <HumaGroupTypes
        types={formik.values.types}
        typesList={types}
        fieldHandle={fieldHandle}
      />

      {shownTable ? (
        <HumanGroupTable
          allEmployees={employees}
          employees={formik.values.employees}
          setFieldValue={formik.setFieldValue}
        />
      ) : (
        <Box>Dynamic group does not need employee selection</Box>
      )}

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button onClick={() => onCancel?.()}>Cancel</Button>
        <LoadingButton
          sx={{ml: 1}}
          variant="contained"
          loading={false}
          type="submit"
        >
          Save
        </LoadingButton>
      </Box>
    </Box>
  );
};
