/* eslint-disable no-bitwise */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
} from '@mui/material';
import {useFormik} from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {useSnackbar} from 'notistack';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {
  AMSNodeNetworkdIDInputBody,
  amsSensorAddressMask,
  GasMonitoringNode,
} from '../../../interfaces/GasMonitoringNode';
import reduxActions from '../../../redux/actions';
import {amsNodeNetworkdIDInputSchema} from '../../../scheme/yup/ams-node';
import {CloseSnackbarAction} from '../../common/CloseSnackbarButton';
import NumberTextField from '../../common/NumberTextField';
import SnackbarMessages from '../../common/SnackbarMessages';

interface Props {
  pk: number;
  mode?:
    | 'view'
    | 'update'
    | 'ack'
    | 'update_from_info'
    | 'update_networkd_id'
    | 'update_networkd_id_from_info';
  item?: GasMonitoringNode;
  prefetch?: boolean;
  onCancel?: Function;
  onSubmitted?: (item: GasMonitoringNode) => void;
}

const AMSSensorNodeItemNetworkdIdUpdate = ({
  pk,
  mode,
  item,
  prefetch,
  onCancel,
  onSubmitted,
}: Props) => {
  /*********/
  /* fetch */
  /*********/
  const reduxDispatch = useDispatch();
  const [fetchedData, setFetchedData] = useState<GasMonitoringNode | undefined>(
    cloneDeep(item)
  );
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const resp = await API.get<GasMonitoringNode>(
        `${apiBaseUrl}/ams/${pk}/netword_id`
      );
      setFetchedData(resp.data);
      formik.setValues(getFormikValues(resp.data));
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (!isEqual(item, fetchedData)) {
      setFetchedData(item);
      formik.setValues(getFormikValues(item));
    }
  }, [item]);

  /**********/
  /* submit */
  /**********/

  const {enqueueSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async (data: AMSNodeNetworkdIDInputBody) => {
    setSubmittedInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/ams/${pk}/network_id`;
      const resp = await API.patch<GasMonitoringNode>(endpoint, data);
      await reduxActions.assets.fetchGasMonitorNodes(reduxDispatch);
      const message = `Network Id successfully updated`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      onSubmitted?.(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/
  const getFormikValues = (
    item?: GasMonitoringNode
  ): AMSNodeNetworkdIDInputBody => ({
    commtrac_external_id: item?.commtrac_external_id
      ? item?.commtrac_external_id & amsSensorAddressMask
      : null,
  });

  const formik = useFormik<AMSNodeNetworkdIDInputBody>({
    initialValues: getFormikValues(fetchedData),
    validationSchema: amsNodeNetworkdIDInputSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  useEffect(() => {
    const newInput = getFormikValues(fetchedData);
    if (!isEqual(formik.values, newInput)) {
      formik.setValues(newInput);
    }
  }, [fetchedData]);

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      onSubmit={formik.handleSubmit}
    >
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}
      <Grid spacing={3} container>
        <Grid item xs={12} lg={12} xl={12}>
          <NumberTextField
            step={1}
            value={formik.values.commtrac_external_id ?? null}
            label="Network ID"
            size="small"
            name="commtrac_external_id"
            fullWidth
            error={
              !!formik.touched.commtrac_external_id &&
              !!formik.errors.commtrac_external_id
            }
            helperText={
              formik.touched.commtrac_external_id &&
              formik.errors.commtrac_external_id
            }
            onChange={(v) => formik.setFieldValue('commtrac_external_id', v)}
          />
        </Grid>
      </Grid>

      <Box display="flex" justifyContent="end" gap={1.5}>
        {onCancel ? (
          mode === 'update_from_info' ? (
            <Button onClick={() => onCancel()} startIcon={<ArrowBackIcon />}>
              Back
            </Button>
          ) : (
            <Button onClick={() => onCancel()}>Cancel</Button>
          )
        ) : null}
        <Box>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={submittedInProgress}
          >
            Update Network ID
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default AMSSensorNodeItemNetworkdIdUpdate;
