import {LoadingButton} from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {GasMonitoringNode} from '../../../interfaces/GasMonitoringNode';
import {CloseSnackbarAction} from '../../common/CloseSnackbarButton';
import SnackbarMessages from '../../common/SnackbarMessages';

interface Props extends Omit<DialogProps, 'onClose'> {
  item?: GasMonitoringNode;
  onSubmitted?: (item: GasMonitoringNode) => void;
  onClose?: () => void;
}

const AMSSensorItemActivationModal = ({
  item,
  onSubmitted,
  onClose,
  ...props
}: Props) => {
  const {enqueueSnackbar} = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const actionName = item?.status === 'active' ? 'DeActivate' : 'Activate';

  const submit = async (pk: number) => {
    setIsSubmitting(true);
    try {
      const resp = await API.patch<GasMonitoringNode>(
        `${apiBaseUrl}/ams/${pk}`,
        {
          status: actionName === 'Activate' ? 'active' : 'inactive',
        }
      );
      const message = `Sensor successfully ${actionName === 'Activate' ? 'activated' : 'deactivated'}`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      onSubmitted?.(resp.data);
      onClose?.();
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setIsSubmitting(false);
  };

  return (
    <Dialog {...props} onClose={onClose}>
      <DialogTitle>{actionName} Sensor</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Are you sure you want to {actionName} this sensor?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose?.()}>Cancel</Button>
        <LoadingButton
          variant="contained"
          loading={isSubmitting}
          autoFocus
          onClick={() => submit(item?.id ?? -1)}
          color={actionName === 'Activate' ? 'success' : 'warning'}
        >
          {actionName}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AMSSensorItemActivationModal;
