import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {LoadingButton} from '@mui/lab';
import {Alert, Box, Button, TextField} from '@mui/material';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppDispatch} from '../../hooks/redux';
import reduxActions from '../../redux/actions';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

interface Props {
  onSubmitted?: () => void;
}

const BeltImport = ({onSubmitted}: Props) => {
  const reduxDispatch = useAppDispatch();

  const {enqueueSnackbar} = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formik = useFormik<{file?: File | null}>({
    initialValues: {file: null},
    onSubmit: async (value) => {
      if (value.file) {
        await submitData(value.file);
      }
    },
  });

  const submitData = async (file: File) => {
    setIsSubmitting(true);
    try {
      const formData = new FormData();
      formData.set('import_file', file);
      await API.post(`${apiBaseUrl}/belt?import_data=1`, formData);
      const message = 'belt imported successfully';
      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      onSubmitted?.();
      formik.setFieldValue('file', null);
      reduxDispatch(reduxActions.assets.fetchBeltNodes);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setIsSubmitting(false);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box position="relative" border="1px solid" borderColor="grey.600">
        <Box
          p={2}
          textAlign="center"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Box display="flex" alignItems="center" height="100px">
            <Box>
              <Box>
                <CloudUploadIcon fontSize="large" />
              </Box>

              <Box>
                {formik.values.file
                  ? formik.values.file.name
                  : 'Choose file to upload'}
              </Box>
            </Box>

            {!formik.values.file && (
              <TextField
                type="file"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  opacity: 0,
                }}
                InputProps={{
                  inputProps: {
                    accept: '.csv',
                    sx: {
                      height: '100%',
                      cursor: 'pointer',
                    },
                  },
                  sx: {height: '100%'},
                }}
                onChange={(e: any) =>
                  formik.setFieldValue('file', e.target.files[0])
                }
              />
            )}
          </Box>
          <Box>
            {!!formik.values.file && (
              <Box textAlign="center">
                <Box>
                  <Button onClick={() => formik.setFieldValue('file', null)}>
                    Clear
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <Alert severity="warning">
        Please don't change first column values. If it contains existing ID,
        record will be updated. If it is empty, new record will be added.
      </Alert>

      <Box display="flex" justifyContent="end">
        <LoadingButton
          fullWidth
          variant="contained"
          size="large"
          disabled={!formik.values.file}
          loading={isSubmitting}
          onClick={() => formik.submitForm()}
        >
          Import
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default BeltImport;
