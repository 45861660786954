import {SafeyeNode} from '../../interfaces/SafeyeNode';
import {OpenedSafeyeNodeMode} from '../../utils/hazardAIPanel';
import SafeyeNodeItem from './SafeyeNodeItem';
import SafeyeNodeItemCreate from './SafeyeNodeItemCreate';

interface Props {
  pk?: number;
  item?: SafeyeNode;
  mode?: OpenedSafeyeNodeMode;
  prefetch?: boolean;
  onClose?: () => void;
  onSubmitted?: () => void;
}

const SafeyeNodeItemUpsert = ({
  pk,
  item,
  mode,
  prefetch = true,
  onClose,
  onSubmitted,
}: Props) =>
  pk ? (
    <SafeyeNodeItem
      pk={pk}
      item={item}
      mode={mode}
      prefetch={prefetch}
      onClose={onClose}
      onSubmitted={onSubmitted}
    />
  ) : (
    <SafeyeNodeItemCreate onCancel={onClose} onSubmitted={onSubmitted} />
  );

export default SafeyeNodeItemUpsert;
