import {LoadingButton} from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {BeltMonitoringNode} from '../../../interfaces/BeltMonitoringNode';
import {CloseSnackbarAction} from '../../common/CloseSnackbarButton';
import SnackbarMessages from '../../common/SnackbarMessages';

interface Props extends Omit<DialogProps, 'onClose'> {
  open: boolean;
  pk: number;
  onSubmitted?: () => void;
  onClose?: () => void;
}

const BeltNodeItemReset = ({pk, onSubmitted, onClose, ...props}: Props) => {
  /**********/
  /* submit */
  /**********/
  const {enqueueSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async () => {
    setSubmittedInProgress(true);

    const data = {id: pk};
    try {
      const endpoint = `${apiBaseUrl}/belt/${pk}/reset`;
      await API.patch<BeltMonitoringNode>(endpoint, data);
      const message = `Reset Belt successfully.`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });

      onSubmitted?.();
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setSubmittedInProgress(false);
  };

  return (
    <Dialog {...props} onClose={onClose}>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to reset selected devices?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose?.()}>Cancel</Button>
        <LoadingButton
          variant="contained"
          loading={submittedInProgress}
          autoFocus
          onClick={() => submitData()}
          color="warning"
        >
          Reset
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default BeltNodeItemReset;
