import {LoadingButton} from '@mui/lab';
import {Box} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {saveFile} from '../../utils/file';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import ExportFieldForm from '../common/ExportFieldForm';
import ModalFixed from '../common/ModalFixed';
import SnackbarMessages from '../common/SnackbarMessages';
import {NodesReportData} from '../dashboard-panels/ConnectView/NodesReport';

interface Props {
  value?: NodesReportData;
}

const CommunicationNodeExportExcel = ({value}: Props) => {
  const {enqueueSnackbar} = useSnackbar();
  const [isFetching, setIsFetching] = useState(false);
  const [showExportFieldModal, setShowExportFieldModal] = useState(false);

  const fetchData = async (fields: string[]) => {
    setShowExportFieldModal(false);
    setIsFetching(true);
    try {
      const resp = await API.get(`${apiBaseUrl}/node-export`, {
        params: {
          ...value?.params,
          fields,
        },
        responseType: 'blob',
      });
      const filename =
        resp?.headers['content-disposition']?.split('filename=')?.[1] ||
        'communication_node_list.xlsx';
      saveFile(resp.data, filename);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setIsFetching(false);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" justifyContent="end">
        <LoadingButton
          fullWidth
          size="large"
          variant="contained"
          loading={isFetching}
          onClick={() => setShowExportFieldModal(true)}
        >
          Export to Excel
        </LoadingButton>
      </Box>

      <ModalFixed
        open={showExportFieldModal}
        onClose={() => setShowExportFieldModal(false)}
      >
        <Box
          display="flex"
          flexDirection="column"
          position="relative"
          gap={3}
          p={3}
        >
          <ExportFieldForm
            exportFields={value?.exportFields}
            loading={isFetching}
            onChange={fetchData}
            close={() => setShowExportFieldModal(false)}
          />
        </Box>
      </ModalFixed>
    </Box>
  );
};

export default CommunicationNodeExportExcel;
