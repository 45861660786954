/* eslint-disable no-bitwise */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import concat from 'lodash/concat';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import isNull from 'lodash/isNull';
import uniq from 'lodash/uniq';
import {useSnackbar} from 'notistack';
import {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useAppSelector} from '../../../hooks/redux';
import {AMSEModuleNode} from '../../../interfaces/AMSEModuleNode';
import {
  //AMSNodeBaseInputBody,
  amsSensorAddressMask,
  AMSSensorExtendInputBody,
  GasMonitoringNode,
  SentroType,
} from '../../../interfaces/GasMonitoringNode';
import reduxSelectors from '../../../redux/selectors';
import {amsNodeBaseInputSchema} from '../../../scheme/yup/ams-node';
import {CloseSnackbarAction} from '../../common/CloseSnackbarButton';
import {MapLatLangCoordinates} from '../../common/HazardMap';
import SnackbarMessages from '../../common/SnackbarMessages';
import StatusSelect from '../../selectors/StatusSelect';
import {ZoneSelect} from '../../selectors/ZoneSelect';

interface Props {
  pk: number;
  item?: GasMonitoringNode;
  prefetch?: boolean;
  locationCoordinates?: MapLatLangCoordinates;
  isActiveModal?: boolean;
  onCancel?: Function;
  onSubmitted?: (item: GasMonitoringNode) => void;
}

const AMSSensorNodeItemAcknowledge = ({
  pk,
  item,
  prefetch,
  locationCoordinates,
  isActiveModal = true,
  onCancel,
  onSubmitted,
}: Props) => {
  /*********/
  /* fetch */
  /*********/

  const assets = useSelector(reduxSelectors.assets.getAssets);
  const ams_nodes = assets.ams_nodes;

  const ava_nodes = useMemo(() => {
    return ams_nodes?.filter((it) => !!it?.ava);
  }, [ams_nodes]);

  const typeIdLabels = useAppSelector(({assets}) => assets.emoduleTypeIdLabel);
  const ams_emodules = useAppSelector(({assets}) => assets.ams_emodules);

  const ams_emodule_enable_selected = useMemo(
    () =>
      uniq(
        concat(
          ams_emodules.filter(
            (item) =>
              ams_nodes.filter(
                (sn) =>
                  sn.sensor_serial_number === item.serial_number &&
                  sn.commtrac_external_id === item.commtrac_external_id
              ).length === 0
          ),
          ams_emodules.filter(
            (it) =>
              item?.sensor_serial_number === it.serial_number &&
              item?.commtrac_external_id === it.commtrac_external_id
          )
        )
      ),
    [ams_emodules, ams_nodes, item]
  );

  const [assignAVAID, setAssignAVAID] = useState<string[] | undefined>([]);

  const [fetchedData, setFetchedData] = useState<GasMonitoringNode | undefined>(
    cloneDeep(item)
  );
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const resp = await API.get<GasMonitoringNode>(`${apiBaseUrl}/ams/${pk}`);
      setFetchedData(resp.data);
      formik.setValues(getFormikValues(resp.data));
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (!isEqual(item, fetchedData)) {
      setFetchedData(item);
      formik.setValues(getFormikValues(item));
    }
  }, [item]);

  /**********/
  /* submit */
  /**********/

  const {enqueueSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async (data: AMSSensorExtendInputBody) => {
    setSubmittedInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/ams/${pk}/acknowledge`;
      const resp = await API.patch<GasMonitoringNode>(endpoint, data);
      if (!data?.ava && !data?.intrinsically_safe) {
        // Assign AVA
        if (assignAVAID?.length) {
          await API.patch<GasMonitoringNode>(
            `${apiBaseUrl}/ams/${resp.data.id}/ava`,
            {
              ava_commtrac_external_id: assignAVAID,
              assign: true,
            }
          );
        }
      }
      const message = `Sensor Node successfully acknowledged`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      onSubmitted?.(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setSubmittedInProgress(false);
  };

  const getSerialNumber = (
    item?: GasMonitoringNode
  ): AMSEModuleNode | undefined => {
    return find(ams_emodules, {
      commtrac_external_id: item?.commtrac_external_id,
      serial_number: item?.sensor_serial_number,
    });
  };

  /*********/
  /* input */
  /*********/
  const getFormikValues = (
    item?: GasMonitoringNode
  ): AMSSensorExtendInputBody => ({
    name: item?.name ?? '',
    sensor_serial_number: getSerialNumber(item)?.serial_number ?? null,
    pos_lat: item?.pos_lat ?? null,
    pos_lon: item?.pos_lon ?? null,
    status: item?.status ?? null,
    zone_id: item?.zone_id ?? null,
    commtrac_external_id: item?.commtrac_external_id
      ? item?.commtrac_external_id & amsSensorAddressMask
      : null,
    re_commtrac_external_id: item?.commtrac_external_id
      ? item?.commtrac_external_id & amsSensorAddressMask
      : null,
    intrinsically_safe: item?.intrinsically_safe ?? 0,
    ava: item?.ava ?? 0,
    non_sensing: item?.non_sensing ?? 0,
  });

  const formik = useFormik<AMSSensorExtendInputBody>({
    initialValues: getFormikValues(fetchedData),
    validationSchema: amsNodeBaseInputSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  const can_assign_emodule = useMemo(() => {
    return fetchedData?.sentro_type === SentroType.SENTRO_1;
  }, [fetchedData]);

  useEffect(() => {
    const newInput = getFormikValues(fetchedData);
    if (!isEqual(formik.values, newInput)) {
      formik.setValues(newInput);
    }
  }, [fetchedData]);

  useEffect(() => {
    if (isActiveModal && locationCoordinates?.lat && locationCoordinates?.lng) {
      const newPosLat = +locationCoordinates.lat.toFixed(6);
      const newPosLong = +locationCoordinates.lng.toFixed(6);
      if (
        formik.values.pos_lat !== newPosLat ||
        formik.values.pos_lon !== newPosLong
      ) {
        formik.setFieldValue('pos_lat', newPosLat);
        formik.setFieldValue('pos_lon', newPosLong);
      }
    }
  }, [locationCoordinates]);

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      onSubmit={formik.handleSubmit}
    >
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}
      <Grid spacing={3} container>
        <Grid item xs={12} lg={12} xl={12}>
          <TextField
            value={formik.values.name ?? ''}
            label="Name"
            size="small"
            name="name"
            fullWidth
            error={!!formik.touched.name && !!formik.errors.name}
            helperText={formik.touched.name && formik.errors.name}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={12} xl={12}>
          <StatusSelect
            value={formik.values.status}
            fullWidth
            name="status"
            label="Status"
            size="small"
            select
            error={!!formik.touched.status && !!formik.errors.status}
            helperText={formik.touched.status && formik.errors.status}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={12} xl={12}>
          <ZoneSelect
            value={formik.values.zone_id}
            label="Section"
            size="small"
            fullWidth
            error={!!formik.touched.zone_id && !!formik.errors.zone_id}
            helperText={formik.touched.zone_id && formik.errors.zone_id}
            onChange={(v) => formik.setFieldValue('zone_id', v)}
          />
        </Grid>
        {can_assign_emodule ? (
          <Grid item xs={12} lg={12} xl={12}>
            <TextField
              value={formik.values.sensor_serial_number}
              fullWidth
              label="Associated eModule"
              select
              size="small"
              SelectProps={{
                multiple: false,
              }}
              onChange={(el) => {
                formik.setFieldValue('sensor_serial_number', el.target.value);
              }}
              error={
                !!formik.touched.sensor_serial_number &&
                !!formik.errors.sensor_serial_number
              }
              helperText={
                formik.touched.sensor_serial_number &&
                formik.errors.sensor_serial_number
              }
              InputLabelProps={{
                shrink: !!formik.values.sensor_serial_number, // Ensures label is always floated if there's a value
              }}
            >
              {ams_emodule_enable_selected?.map((it) => (
                <MenuItem
                  key={it.id ?? ''}
                  value={it.serial_number ?? ''}
                  selected={
                    it.serial_number === getSerialNumber(item)?.serial_number
                  }
                >
                  {`${it.serial_number}-${!isNull(it.type) ? typeIdLabels?.[it.type] : 'None'}`}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        ) : null}
        <Grid item xs={12} lg={12} xl={12}>
          <TextField
            value={formik.values.pos_lat ?? ''}
            label="Latitude (click on map or enter manually)"
            size="small"
            name="pos_lat"
            type="number"
            fullWidth
            error={!!formik.touched.pos_lat && !!formik.errors.pos_lat}
            helperText={formik.touched.pos_lat && formik.errors.pos_lat}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={12} xl={12}>
          <TextField
            value={formik.values.pos_lon ?? ''}
            label="Longitude (click on map or enter manually)"
            size="small"
            name="pos_lon"
            type="number"
            fullWidth
            error={!!formik.touched.pos_lon && !!formik.errors.pos_lon}
            helperText={formik.touched.pos_lon && formik.errors.pos_lon}
            onChange={formik.handleChange}
          />
        </Grid>
        <Grid item xs={12} lg={4} xl={4}>
          <FormControl disabled={formik.values.ava === 1}>
            <FormLabel id="demo-form-control-label-placement">
              Intrinsically Safe
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-form-control-label-placement"
              name="position"
              defaultValue="top"
            >
              <FormControlLabel
                label="Yes"
                control={
                  <Radio
                    checked={!!formik.values.intrinsically_safe}
                    onChange={() => {
                      formik.setFieldValue('ava', 0);
                      formik.setFieldValue('non_sensing', 0);
                      formik.setFieldValue('intrinsically_safe', 1);
                    }}
                    value="true"
                    name="radio-buttons"
                    inputProps={{'aria-label': 'Yes'}}
                  />
                }
              />
              <FormControlLabel
                label="No"
                control={
                  <Radio
                    checked={!formik.values.intrinsically_safe}
                    onChange={() => {
                      formik.setFieldValue('intrinsically_safe', 0);
                    }}
                    value="true"
                    name="radio-buttons"
                    inputProps={{'aria-label': 'No'}}
                  />
                }
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={4} xl={4}>
          <FormControl disabled={formik.values.intrinsically_safe === 1}>
            <FormLabel id="ava-label-placement">AVA</FormLabel>
            <RadioGroup
              row
              aria-labelledby="ava-label-placement"
              name="ava"
              defaultValue="top"
            >
              <FormControlLabel
                label="Yes"
                control={
                  <Radio
                    checked={!!formik.values.ava}
                    onChange={() => {
                      formik.setFieldValue('ava', 1);
                      formik.setFieldValue('intrinsically_safe', 0);
                    }}
                    value="true"
                    name="ava-buttons"
                    inputProps={{'aria-label': 'Yes'}}
                  />
                }
              />
              <FormControlLabel
                label="No"
                control={
                  <Radio
                    checked={!formik.values.ava}
                    onChange={() => {
                      formik.setFieldValue('ava', 0);
                    }}
                    value="true"
                    name="ava-buttons"
                    inputProps={{'aria-label': 'No'}}
                  />
                }
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={4} xl={4}>
          <FormControl
            disabled={
              formik.values.intrinsically_safe === 1 || formik.values.ava === 0
            }
          >
            <FormLabel id="non-sensing-label-placement">Non Sensing</FormLabel>
            <RadioGroup
              row
              aria-labelledby="non-sensing-label-placement"
              name="non-sensing"
              defaultValue="top"
            >
              <FormControlLabel
                label="Yes"
                control={
                  <Radio
                    checked={!!formik.values.non_sensing}
                    onChange={() => {
                      formik.setFieldValue('non_sensing', 1);
                    }}
                    value="true"
                    name="non_sensing-buttons"
                    inputProps={{'aria-label': 'Yes'}}
                  />
                }
              />
              <FormControlLabel
                label="No"
                control={
                  <Radio
                    checked={!formik.values.non_sensing}
                    onChange={() => {
                      formik.setFieldValue('non_sensing', 0);
                    }}
                    value="true"
                    name="non_sensing-buttons"
                    inputProps={{'aria-label': 'No'}}
                  />
                }
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        {!formik.values.ava && !formik.values.intrinsically_safe && (
          <Grid item xs={12} lg={12}>
            <Box display="flex" alignItems="center">
              <TextField
                fullWidth
                label="AVAs"
                select
                size="small"
                value={assignAVAID}
                SelectProps={{
                  multiple: true,
                }}
                onChange={(el) => {
                  const v = el.target.value as unknown as string[];

                  setAssignAVAID(v);
                }}
              >
                {ava_nodes
                  ?.map((it) => ({
                    id: it?.commtrac_external_id,
                    name: it?.name,
                  }))
                  .map((option) => (
                    <MenuItem key={option.id ?? ''} value={option.id ?? ''}>
                      {option.name}
                    </MenuItem>
                  ))}
              </TextField>
            </Box>
          </Grid>
        )}
      </Grid>

      <Box display="flex" justifyContent="end" gap={1.5}>
        {onCancel && fetchedData?.ack === '1' ? (
          <Button onClick={() => onCancel()} startIcon={<ArrowBackIcon />}>
            Back
          </Button>
        ) : null}
        <Box>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={submittedInProgress}
            color="warning"
          >
            Acknowledge
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default AMSSensorNodeItemAcknowledge;
