/* eslint-disable no-bitwise */
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import PrintIcon from '@mui/icons-material/Print';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RefreshIcon from '@mui/icons-material/Refresh';
import {LoadingButton} from '@mui/lab';
import {Box, Button, ButtonGroup, Checkbox} from '@mui/material';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import inRange from 'lodash/inRange';
import {useSnackbar} from 'notistack';
import {useEffect, useMemo, useRef, useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useConfiguration} from '../../../hooks/configuration';
import {useGetMinerNetworkId} from '../../../hooks/get-miner-network-id';
import {useAppSelector} from '../../../hooks/redux';
import {beltAddressMask} from '../../../interfaces/BeltMonitoringNode';
import {
  Event,
  EventListQuery,
  EventListResponse,
} from '../../../interfaces/Event';
import {ExportField} from '../../../interfaces/Export';
import {amsSensorAddressMask} from '../../../interfaces/GasMonitoringNode';
import {eventBaseTypesSelect} from '../../../redux/assets/selectors';
import reduxSelectors from '../../../redux/selectors';
import {getEventDescription} from '../../../utils/events';
import {getHumanReadable} from '../../../utils/macAddress';
import AccessControl from '../../common/AccessControl';
import {AutoRefreshSettingsSelect} from '../../common/AutoRefreshSettingsSelect';
import {CloseSnackbarAction} from '../../common/CloseSnackbarButton';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import SnackbarMessages from '../../common/SnackbarMessages';
import EventExportButton from '../../events/EventExportButton';
import {DateRangeSelect} from '../../selectors/DateRangeSelect';
import {EventTypeSelect} from '../../selectors/EventTypeSelect';
import {AcknowledgedTypeSelect} from './AcknowledgedTypeSelect';
import {ClearedTypeSelect} from './ClearedTypeSelect';

interface Props {
  value?: EventsReportData;
  fetchedData?: EventListResponse;
  loading?: boolean;
  eventIds: {
    id: Number;
    product: string;
    ack: '0' | '1' | null;
  }[];
  onChange?: (value?: EventsReportData) => void;
  onRefresh?: () => void;
}

export interface EventsReportData {
  params: Partial<EventListQuery>;
  selectedIds: number[] | null;
  shownFields: string[];
  exportFields: ExportField[];
  commtrac_external_id?: string;
  by_strongest_cn_only?: boolean;
  event_type?: string[] | null;
  asset_type?: string[] | null;
}

const DEFAULT_SHOWN_FIELDS = [
  'date',
  'ack',
  'text_replacements',
  'name',
  'network_id',
  'base_type',
  'cleared',
];

export const getEventsReportData = (): EventsReportData => {
  return {
    params: {
      date_start: dayjs().format('YYYY-MM-DD'),
      date_end: dayjs().format('YYYY-MM-DD'),
      limit: 10,
      page: 0,
    },
    selectedIds: [],
    shownFields: DEFAULT_SHOWN_FIELDS,
    exportFields: [],
  };
};

const EventsReport = ({
  value,
  fetchedData,
  loading,
  eventIds,
  onChange,
  onRefresh,
}: Props) => {
  const config = useMemo(() => value ?? getEventsReportData(), [value]);

  /*************/
  /* data grid */
  /*************/
  const eventBaseTypes = useAppSelector(eventBaseTypesSelect);
  const isCompactMode = useAppSelector(reduxSelectors.app.getIsCompactMode);
  const getMinerNetworkId = useGetMinerNetworkId();
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = fetchedData?.items ?? [];
  const columns: DataGridColumn<Event>[] = [
    {
      field: 'select',
      type: 'select',
      renderHeader: () => (
        <Checkbox
          color="primary"
          disabled={rows.length === 0}
          checked={selectedItems.length > 0 && selectedAll}
          indeterminate={selectedItems.length > 0 && !selectedAll}
          onChange={() => toggleSelectAllItems()}
        />
      ),
      renderCell: ({row}) => (
        <Checkbox
          color="primary"
          checked={selectedItems.includes(row.id)}
          onChange={() => toggleSelectItem(row.id)}
        />
      ),
      doNotExport: true,
    },
    {
      field: 'id',
      headerName: 'ID',
      sortable: true,
    },
    {
      field: 'date',
      headerName: 'Timestamp',
      sortable: true,
      width: 200,
    },
    {
      field: 'ack',
      headerName: 'Ack',
      sortable: true,
      valueGetter: ({row}: any) => {
        return row.ack === '1';
      },
      renderCell: ({value}) => {
        if (value) {
          return <CheckCircleIcon color="success" />;
        } else {
          return <RadioButtonUncheckedIcon color="success" />;
        }
      },
    },
    {
      field: 'ack_comment',
      headerName: 'Ack Comment',
      sortable: true,
      width: 200,
    },
    {
      field: 'text_replacements',
      headerName: 'Event Description',
      sortable: true,
      valueGetter: ({row}: any) => {
        return getEventDescription(row.text_template, row.text_replacements);
      },
    },
    {
      field: 'name',
      headerName: 'Nickname',
      sortable: true,
    },
    {
      field: 'network_id',
      headerName: 'Network ID',
      sortable: true,
      valueGetter: ({row}) => {
        if (row.network_id) {
          if (inRange(row.base_type, 200, 299)) {
            if (row.wifi_enabled) {
              return getHumanReadable(row.network_id as string);
            } else {
              return getMinerNetworkId(+row.network_id);
            }
          }
          if (row.product === 'alarm') {
            return getHumanReadable(row.network_id as string);
          }
          if (inRange(row.base_type, 401, 488)) {
            return +row.network_id & amsSensorAddressMask;
          }
          if (inRange(row.base_type, 600, 633)) {
            return +row.network_id & beltAddressMask;
          }
          return +row.network_id;
        }
      },
    },
    {
      field: 'base_type',
      headerName: 'Type',
      sortable: true,
      valueGetter: ({row}: any) => {
        const idx = eventBaseTypes.findIndex((t) => t.type === row.base_type);
        if (idx === -1) {
          return 'Unknown Event Type';
        }
        return eventBaseTypes[idx].name;
      },
    },
    {
      field: 'cleared',
      headerName: 'Cleared',
      sortable: true,
      valueGetter: ({row}: any) => {
        if (row.cleared === '1') {
          return 'Yes';
        } else {
          return 'No';
        }
      },
    },
  ];
  /*******************/
  /* multiple select */
  /*******************/
  const selectedItems = config?.selectedIds ?? [];

  const selectedRows = useMemo(
    () => rows.filter((i) => selectedItems?.includes(i.id)),
    [rows, selectedItems]
  );

  const allowAck = useMemo(() => {
    return selectedRows.find((e) => e.ack !== '1');
  }, [selectedRows]);

  const selectedAll = useMemo(
    () => rows.length === selectedRows.length,
    [rows, selectedRows]
  );

  const toggleSelectItem = (id: number) => {
    if (selectedItems?.includes(id)) {
      onChange?.(
        update(config, {
          selectedIds: {
            $set: selectedItems.filter((i) => i !== id),
          },
        })
      );
    } else {
      onChange?.(
        update(config, {
          selectedIds: {
            $set: [...(selectedItems ?? []), id],
          },
        })
      );
    }
  };

  const selectAll = () => {
    onChange?.(
      update(config, {
        selectedIds: {
          $set: rows?.map((i) => i.id) ?? [],
        },
      })
    );
  };

  const unselectAll = () => {
    onChange?.(
      update(config, {
        selectedIds: {
          $set: [],
        },
      })
    );
  };

  const toggleSelectAllItems = () => {
    if (selectedItems.length >= rows.length) {
      unselectAll();
    } else {
      selectAll();
    }
  };

  /********************/
  /* refresh interval */
  /********************/
  const refreshPeriod = useConfiguration(
    'auto-refresh',
    'event_summary_autorefresh_rate'
  );

  /**************/
  /* submit ack */
  /**************/
  const {enqueueSnackbar} = useSnackbar();
  const [submittedAckInProgress, setSubmittedAckInProgress] = useState(false);

  const submitAckEvent = async () => {
    setSubmittedAckInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/event/acknowledge`;
      const ids: {
        connect?: number[];
        ams?: number[];
        alarm?: number[];
        belt?: number[];
        shaft_clearance?: number[];
      } = {};

      if (
        config.selectedIds?.filter(
          (it) =>
            eventIds?.findIndex(
              (_it) =>
                _it.id === it && _it.product === 'connect' && _it.ack !== '1'
            ) > -1
        ).length
      ) {
        ids.connect = config.selectedIds?.filter(
          (it) =>
            eventIds?.findIndex(
              (_it) =>
                _it.id === it && _it.product === 'connect' && _it.ack !== '1'
            ) > -1
        );
      }

      if (
        config.selectedIds?.filter(
          (it) =>
            eventIds?.findIndex(
              (_it) => _it.id === it && _it.product === 'ams' && _it.ack !== '1'
            ) > -1
        ).length
      ) {
        ids.ams = config.selectedIds?.filter(
          (it) =>
            eventIds?.findIndex(
              (_it) => _it.id === it && _it.product === 'ams' && _it.ack !== '1'
            ) > -1
        );
      }

      if (
        config.selectedIds?.filter(
          (it) =>
            eventIds?.findIndex(
              (_it) =>
                _it.id === it && _it.product === 'alarm' && _it.ack !== '1'
            ) > -1
        ).length
      ) {
        ids.alarm = config.selectedIds?.filter(
          (it) =>
            eventIds?.findIndex(
              (_it) =>
                _it.id === it && _it.product === 'alarm' && _it.ack !== '1'
            ) > -1
        );
      }

      if (
        config.selectedIds?.filter(
          (it) =>
            eventIds?.findIndex(
              (_it) =>
                _it.id === it && _it.product === 'belt' && _it.ack !== '1'
            ) > -1
        ).length
      ) {
        ids.belt = config.selectedIds?.filter(
          (it) =>
            eventIds?.findIndex(
              (_it) =>
                _it.id === it && _it.product === 'belt' && _it.ack !== '1'
            ) > -1
        );
      }

      if (
        config.selectedIds?.filter(
          (it) =>
            eventIds?.findIndex(
              (_it) =>
                _it.id === it &&
                _it.product === 'shaft_clearance' &&
                _it.ack !== '1'
            ) > -1
        ).length
      ) {
        ids.shaft_clearance = config.selectedIds?.filter(
          (it) =>
            eventIds?.findIndex(
              (_it) =>
                _it.id === it &&
                _it.product === 'shaft_clearance' &&
                _it.ack !== '1'
            ) > -1
        );
      }

      await API.patch(endpoint, {
        ids,
      });
      const message = `Event(s) was successfully changed`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      onRefresh?.();
      unselectAll();
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setSubmittedAckInProgress(false);
  };

  useEffect(() => {
    onChange?.(
      update(config, {
        exportFields: {
          $set: columns
            .filter((col) => !col.doNotExport)
            .map((col) => ({
              field: col.field,
              label: col.headerName,
              hidden: config.shownFields?.indexOf(col.field) === -1,
            })),
        },
      })
    );
  }, [config.shownFields]);

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        gap={2}
        height={isCompactMode ? 56 : 70}
        p={isCompactMode ? 1 : 2}
      >
        <Box display="flex" gap={1}>
          <Box minWidth={400}>
            <DateRangeSelect
              value={[
                dayjs(config.params.date_start).toDate(),
                dayjs(config.params.date_end).toDate(),
              ]}
              size="small"
              onChange={(v) => {
                onChange?.(
                  update(config, {
                    params: {
                      date_start: {
                        $set: v?.[0]
                          ? dayjs(v?.[0]).format('YYYY-MM-DD')
                          : undefined,
                      },
                      date_end: {
                        $set: v?.[0]
                          ? dayjs(v?.[1]).format('YYYY-MM-DD')
                          : undefined,
                      },
                      page: {
                        $set: 0,
                      },
                    },
                  })
                );
              }}
            />
          </Box>

          <AcknowledgedTypeSelect
            size="small"
            sx={{width: 170}}
            value={config.params.acknowledged ?? 'all'}
            onChange={(v) => {
              onChange?.(
                update(config, {
                  params: {
                    acknowledged: {
                      $set: v,
                    },
                    page: {
                      $set: 0,
                    },
                  },
                })
              );
            }}
          />

          <ClearedTypeSelect
            size="small"
            sx={{width: 130}}
            value={config.params.cleared ?? 'all'}
            onChange={(v) => {
              onChange?.(
                update(config, {
                  params: {
                    cleared: {
                      $set: v,
                    },
                    page: {
                      $set: 0,
                    },
                  },
                })
              );
            }}
          />

          <Box width={300}>
            <EventTypeSelect
              value={config.params.type}
              onChange={(v) => {
                onChange?.(
                  update(config, {
                    params: {
                      type: {
                        $set: v,
                      },
                      page: {
                        $set: 0,
                      },
                    },
                  })
                );
                onRefresh?.();
              }}
            />
          </Box>
        </Box>

        <Box display="flex">
          <ButtonGroup>
            <AccessControl permissions={['patch::/event/acknowledge']}>
              <LoadingButton
                size="small"
                disabled={!allowAck}
                loading={submittedAckInProgress}
                variant="outlined"
                onClick={submitAckEvent}
              >
                ACK
              </LoadingButton>
            </AccessControl>

            <EventExportButton
              value={value}
              component={Button}
              componentProps={{
                color: 'primary',
              }} /*onSubmitted={() => onRefresh?.()}*/
            >
              <ImportExportIcon />
            </EventExportButton>

            <Button size="small" onClick={() => onRefresh?.()}>
              <RefreshIcon />
            </Button>

            {refreshPeriod ? (
              <AutoRefreshSettingsSelect refreshPeriod={refreshPeriod} />
            ) : null}

            <Button
              size="small"
              onClick={() => dataGridRef.current?.printTable()}
            >
              <PrintIcon />
            </Button>
          </ButtonGroup>
        </Box>
      </Box>

      <DataGrid
        ref={dataGridRef}
        rows={rows}
        columns={columns}
        loading={loading}
        shownFields={config.shownFields}
        pagination
        paginationMode="server"
        size="small"
        sortBy={
          config.params.order
            ? {
                field: config.params.order,
                dir: config.params.dir === 'DESC' ? 'desc' : 'asc',
              }
            : null
        }
        sortingMode="server"
        page={config.params.page}
        pageSize={config.params.limit}
        rowCount={fetchedData?.count}
        sxFooter={{
          bgcolor: (theme) =>
            theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
        }}
        onPageChange={(v) => {
          onChange?.(
            update(config, {
              params: {
                $set: {
                  ...config.params,
                  page: v,
                },
              },
            })
          );
        }}
        onPageSizeChange={(v) => {
          onChange?.(
            update(config, {
              params: {
                $set: {
                  ...config.params,
                  page: 0,
                  limit: v,
                },
              },
            })
          );
        }}
        onSort={(v) => {
          if (v) {
            onChange?.(
              update(config, {
                params: {
                  $set: {
                    ...config.params,
                    order: v.field,
                    dir: v.dir === 'desc' ? 'DESC' : 'ASC',
                  },
                },
              })
            );
          }
        }}
        onShownFieldsChange={(v) => {
          onChange?.(
            update(config, {
              shownFields: {
                $set: v,
              },
            })
          );
        }}
      />
    </Box>
  );
};

export default EventsReport;
