/* eslint-disable complexity */
import CloseIcon from '@mui/icons-material/Close';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import {LoadingButton} from '@mui/lab';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import {omit} from 'lodash';
import {useSnackbar} from 'notistack';
import {useMemo, useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppSelector} from '../../hooks/redux';
import {
  SafeyeNode,
  SafeyeNodeCreateInputBody,
} from '../../interfaces/SafeyeNode';
import reduxSelectors from '../../redux/selectors';
import {safeyeNodeInputSchema} from '../../scheme/yup/safeye-node';
import AssetMachineSelect from '../asset-machine/AssetMachineSelect';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';
import {MachineTypeSelect} from '../selectors/MachineTypeSelect';
import {SafeyeNodeTypeSelect} from '../selectors/SafeyeNodeTypeSelect';
import StatusSelect from '../selectors/StatusSelect';

interface Props {
  onCancel?: () => void;
  onSubmitted?: (item: SafeyeNode) => void;
}

type CreateInputBody = SafeyeNodeCreateInputBody & {
  is_existing_node: string;
  is_unassigned: boolean;
};

const SafeyeNodeItemCreate = ({onCancel, onSubmitted}: Props) => {
  // Constants
  const assets = useAppSelector(reduxSelectors.assets.getAssets);

  const assetMachines = useMemo(
    () => assets.asset_machines.filter((i) => i.external_id),
    [assets.asset_machines]
  );

  // Submit
  const {enqueueSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async (data: CreateInputBody) => {
    setSubmittedInProgress(true);
    try {
      const normalizedData = {
        ...omit(data, [
          'is_existing_node',
          'is_unassigned',
          ...(isUnassigned ? ['asset_machine_id', 'type_id'] : []),
          ...(isExistingNode ? ['type_id'] : ['asset_machine_id']),
        ]),
      };
      console.log({normalizedData});
      const endpoint = `${apiBaseUrl}/safeye-nano-node`;
      const resp = await API.post<SafeyeNode>(endpoint, {
        ...normalizedData,
      });
      const message = `Asset successfully created`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      onSubmitted?.(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setSubmittedInProgress(false);
  };

  //Input
  const getFormikValues = (): CreateInputBody => ({
    asset_machine_id: null,
    description: '',
    is_existing_node: '',
    name: '',
    safeye_node_id: '',
    status: 'active',
    type_id: null,
    type: null,
    is_unassigned: false,
  });

  const formik = useFormik<CreateInputBody>({
    initialValues: getFormikValues(),
    validationSchema: safeyeNodeInputSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  const isExistingNode = formik.values.is_existing_node === 'YES';
  const isUnassigned = formik.values.is_unassigned;

  return (
    <>
      <Box
        component="form"
        display="flex"
        flexDirection="column"
        position="relative"
        gap={3}
        p={3}
        onSubmit={formik.handleSubmit}
      >
        <Box display="flex" justifyContent="space-between">
          <Box
            className="dragHandle"
            display="flex"
            alignItems="center"
            width="100%"
            gap={1.5}
            sx={{cursor: 'grab'}}
          >
            <LocalShippingIcon sx={{color: 'primary.main'}} />
            <Box fontSize={24}>Create Safeye Node</Box>
          </Box>
          {onCancel ? (
            <IconButton onClick={() => onCancel()}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </Box>
        <Box display="flex" flexDirection="column" gap={3}>
          <TextField
            value={formik.values.name}
            label="Name"
            size="small"
            name="name"
            fullWidth
            error={!!formik.touched.name && !!formik.errors.name}
            helperText={formik.touched.name && formik.errors.name}
            onChange={formik.handleChange}
          />
          <SafeyeNodeTypeSelect
            value={formik.values.type}
            label="Safeye Node Type"
            size="small"
            fullWidth
            error={!!formik.touched.type && !!formik.errors.type}
            helperText={formik.touched.type && formik.errors.type}
            onChange={(v) => {
              formik.setFieldValue('type', v);
            }}
          />
          <StatusSelect
            value={formik.values.status}
            fullWidth
            name="status"
            label="Status"
            size="small"
            select
            error={!!formik.touched.status && !!formik.errors.status}
            helperText={formik.touched.status && formik.errors.status}
            onChange={formik.handleChange}
          />
          <FormControlLabel
            control={<Checkbox name="is_unassigned" />}
            label="Create Camera without assigning it to a machine"
            checked={formik.values.is_unassigned}
            onChange={formik.handleChange}
          />
          {isUnassigned ? null : (
            <TextField
              value={formik.values.is_existing_node}
              label="Should be used for existing machine?"
              size="small"
              name="is_existing_node"
              select
              fullWidth
              error={
                !!formik.touched.is_existing_node &&
                !!formik.errors.is_existing_node
              }
              helperText={
                formik.touched.is_existing_node &&
                formik.errors.is_existing_node
              }
              onChange={formik.handleChange}
            >
              {[
                {value: 'YES', name: 'Yes'},
                {value: 'NO', name: 'No'},
              ].map((i) => (
                <MenuItem key={i.name} value={i.value}>
                  {i.name}
                </MenuItem>
              ))}
            </TextField>
          )}
          {isExistingNode && !isUnassigned ? (
            <AssetMachineSelect
              value={formik.values.asset_machine_id}
              size="small"
              label="Machine"
              fullWidth
              nullLabel=""
              assetMachines={assetMachines}
              error={
                !!formik.touched.asset_machine_id &&
                !!formik.errors.asset_machine_id
              }
              onChange={(_, item) => {
                console.log('item?.asset_machine_id', item?.id);
                formik.setFieldValue('asset_machine_id', item?.id ?? null);
              }}
            />
          ) : null}{' '}
          {!isExistingNode && !isUnassigned ? (
            <MachineTypeSelect
              value={formik.values.type_id}
              label="Machine Type"
              size="small"
              fullWidth
              error={!!formik.touched.type_id && !!formik.errors.type_id}
              helperText={formik.touched.type_id && formik.errors.type_id}
              onChange={(v) => formik.setFieldValue('type_id', v)}
            />
          ) : null}
          <TextField
            value={formik.values.safeye_node_id}
            label="Safeye Node Id"
            size="small"
            name="safeye_node_id"
            rows={3}
            fullWidth
            error={
              !!formik.touched.safeye_node_id && !!formik.errors.safeye_node_id
            }
            helperText={
              formik.touched.safeye_node_id && formik.errors.safeye_node_id
            }
            onChange={formik.handleChange}
          />
          <TextField
            value={formik.values.description}
            label="Description"
            size="small"
            name="description"
            multiline
            rows={3}
            fullWidth
            error={!!formik.touched.description && !!formik.errors.description}
            helperText={formik.touched.description && formik.errors.description}
            onChange={formik.handleChange}
          />
        </Box>

        <Box display="flex" justifyContent="end" gap={1}>
          {onCancel ? <Button onClick={() => onCancel()}>Cancel</Button> : null}
          <Box>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={submittedInProgress}
            >
              Create
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SafeyeNodeItemCreate;
