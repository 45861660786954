import {LoadingButton} from '@mui/lab';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@mui/material';
import {Box} from '@mui/system';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {Company} from '../../interfaces/Company';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

interface Props {
  item: Company;
  open: boolean;
  onSubmitted?: () => void;
  onClose?: () => void;
}

type PurgeMode = 'all' | 'assets' | 'transactions';

export const CompanyItemPurgeDialog = ({
  item,
  open,
  onSubmitted,
  onClose,
}: Props) => {
  const {enqueueSnackbar} = useSnackbar();
  const [modeInProgress, setModeInProgress] = useState<PurgeMode>();

  const submit = async (mode: PurgeMode) => {
    setModeInProgress(mode);

    try {
      await API.patch(`${apiBaseUrl}/company/${item.id}/purge`, {mode});
      const message = `Company successfully purged (${mode})`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      onSubmitted?.();
      onClose?.();
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }

    setModeInProgress(undefined);
  };

  return (
    <Dialog open={open} onClose={() => onClose?.()}>
      <DialogTitle>Purge Company</DialogTitle>

      <DialogContent>
        <DialogContentText sx={{width: 250}}>
          <Box my={2}>
            <LoadingButton
              fullWidth
              loading={modeInProgress === 'all'}
              disabled={!!modeInProgress}
              variant="contained"
              color="error"
              onClick={() => submit('all')}
            >
              All
            </LoadingButton>
          </Box>

          <Divider />

          <Box my={2}>
            <LoadingButton
              fullWidth
              loading={modeInProgress === 'assets'}
              disabled={!!modeInProgress}
              variant="contained"
              color="error"
              onClick={() => submit('assets')}
            >
              Assets
            </LoadingButton>
          </Box>

          <Box my={2}>
            <LoadingButton
              fullWidth
              loading={modeInProgress === 'transactions'}
              disabled={!!modeInProgress}
              variant="contained"
              color="error"
              onClick={() => submit('transactions')}
            >
              Transactions
            </LoadingButton>
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
