import {LoadingButton} from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItem,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {ReactNode, useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {User} from '../../interfaces/User';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

interface UserItemDeleteButtonProps {
  item: User;
  prefetch?: boolean;
  component?: React.FC;
  componentProps?: any;
  onDeleted?: () => void;
  children?: ReactNode;
}

export const UserItemDeleteButton: React.FC<UserItemDeleteButtonProps> = ({
  item,
  component = ListItem,
  componentProps = {},
  onDeleted,
  children,
}) => {
  const {enqueueSnackbar} = useSnackbar();
  const Component = component;

  const [isOpened, setIsOpened] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const openModal = () => setIsOpened(true);
  const closeModal = () => setIsOpened(false);

  const submit = async (pk: number) => {
    setIsSubmitting(true);

    try {
      await API.delete(`${apiBaseUrl}/user/${pk}`);
      const message = 'User successfully deleted';
      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      onDeleted?.();
      closeModal();
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }

    setIsSubmitting(false);
  };

  return (
    <>
      <Component {...componentProps} onClick={openModal}>
        {children}
      </Component>

      <Dialog open={isOpened} onClose={closeModal}>
        <DialogTitle>Delete User</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={closeModal}>Cancel</Button>

          <LoadingButton
            variant="contained"
            loading={isSubmitting}
            autoFocus
            onClick={() => submit(item.id)}
            color="error"
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
