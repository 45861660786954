import {useSnackbar} from 'notistack';
import {useState} from 'react';
import {useDispatch} from 'react-redux';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppSelector} from '../../hooks/redux';
import {
  ConfigurationInput,
  ConfigurationResponse,
  ConfigurationResponseItem,
} from '../../interfaces/Configuration';
import reduxActions from '../../redux/actions';
import {getMergedConfigurations} from '../../utils/configuration';
import {AutoRefreshSelect} from './AutoRefreshSelect';
import {CloseSnackbarAction} from './CloseSnackbarButton';
import SnackbarMessages from './SnackbarMessages';

interface Props {
  refreshPeriod?: ConfigurationResponseItem | null;
  options?: {value: null | number; label: string}[];
  isForUserConfiguration?: boolean;
}

const defaultOptions = [
  {value: null, label: 'None'},
  {value: 30000, label: '30'},
  {value: 60000, label: '60'},
  {value: 120000, label: '120'},
];

export const AutoRefreshSettingsSelect = ({
  refreshPeriod,
  options = defaultOptions,
  isForUserConfiguration = false,
}: Props) => {
  const reduxDispatch = useDispatch();
  const myConfigurations = useAppSelector(({app}) => app.myConfigurations);

  /**********/
  /* submit */
  /**********/

  const {enqueueSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);
  const submitData = async (data: ConfigurationInput) => {
    setSubmittedInProgress(true);
    try {
      const resp = await API.post<ConfigurationResponse>(
        `${apiBaseUrl}/${isForUserConfiguration ? 'user-configuration' : 'configuration'}`,
        data
      );
      const newConfigurations = resp.data.filter(
        (i) =>
          i.section === refreshPeriod?.section && i.name === refreshPeriod.name
      );
      reduxActions.app.setApp(reduxDispatch, {
        myConfigurations: getMergedConfigurations(
          myConfigurations,
          newConfigurations
        ),
      });
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setSubmittedInProgress(false);
  };

  const interval = refreshPeriod?.value ? +refreshPeriod?.value * 1000 : null;
  const handleChangeInterval = (v: number | null) => {
    if (refreshPeriod) {
      submitData({
        section: [refreshPeriod.section],
        configuration: [
          {
            section: refreshPeriod.section,
            name: refreshPeriod.name,
            value: v ? v / 1000 : null,
          },
        ],
      });
    }
  };

  return (
    <AutoRefreshSelect
      value={interval}
      loading={submittedInProgress}
      options={options}
      variant="outlined"
      onChange={handleChangeInterval}
    />
  );
};
