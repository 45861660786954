import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {useSnackbar} from 'notistack';
import {useEffect, useMemo, useState} from 'react';
import {useSelector} from 'react-redux';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useConfiguration} from '../../hooks/configuration';
import {useAppDispatch} from '../../hooks/redux';
import {AlarmModuleNode} from '../../interfaces/AlarmModuleNode';
import reduxActions from '../../redux/actions';
import reduxSelectors from '../../redux/selectors';
import {getAlarmCreateSchema} from '../../scheme/yup/alarm-module';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import {MapLatLangCoordinates} from '../common/Map';
import NumberTextField from '../common/NumberTextField';
import SnackbarMessages from '../common/SnackbarMessages';
import StatusSelect from '../selectors/StatusSelect';
import {ZoneSelect} from '../selectors/ZoneSelect';

interface Props {
  pk?: number;
  mode?: 'view' | 'update' | 'chat' | 'ack' | 'update_from_info';
  item?: AlarmModuleNode;
  prefetch?: boolean;
  locationCoordinates?: MapLatLangCoordinates;
  isActiveModal?: boolean;
  onCancel?: () => void;
  onSubmitted?: (item: AlarmModuleNode) => void;
}

type UpdateInputBody = {
  name: string | null;
  mac_address: string | null;
  status: 'active' | 'inactive';
  zone_id: number | null;
  latitude: number | null;
  longitude: number | null;
  description: string | null;
  local_button_press_action: string | null;
  local_button_press_action_group_id: number | null;
  local_button_press_action_zone_id: number | null;
  local_button_group_zone_id: number | null;
};

// eslint-disable-next-line complexity
const AlarmItemUpdate = ({
  pk,
  mode,
  item,
  prefetch,
  locationCoordinates,
  isActiveModal,
  onCancel,
  onSubmitted,
}: Props) => {
  const reduxDispatch = useAppDispatch();

  /*********/
  /* fetch */
  /*********/

  const [fetchedData, setFetchedData] = useState(cloneDeep(item));
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);
  const assets = useSelector(reduxSelectors.assets.getAssets);
  const localButtonPressAction = assets.localButtonPressAction;
  const {zones, alarm_groups} = assets;
  const zoneGroupList = useMemo(() => {
    const groupList = alarm_groups?.map((it, index) => ({
      id: index,
      v_id: it.id,
      name: it.name,
      type: 'group',
    }));

    const zoneList = zones?.map((it, index) => ({
      id: index + groupList.length,
      name: it.name,
      v_id: it.id,
      type: 'zone',
    }));
    return [...groupList, ...zoneList]?.sort(
      (a, b) => -b.type.localeCompare(a.type)
    );
  }, [zones, alarm_groups]);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const resp = await API.get<AlarmModuleNode>(
        `${apiBaseUrl}/alarm-module/${pk}`
      );
      setFetchedData(resp.data);
      formik.setValues(getFormikValues(resp.data ?? null));
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (!isEqual(item, fetchedData)) {
      setFetchedData(item);
      formik.setValues(getFormikValues(item));
    }
  }, [item]);

  /**********/
  /* submit */
  /**********/

  const {enqueueSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const submitData = async (data: UpdateInputBody) => {
    setSubmittedInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/alarm-module/${pk}`;
      // Remove the field if value is null
      const resp = await API.patch<AlarmModuleNode>(
        endpoint,
        Object.fromEntries(Object.entries(data).filter(([_, v]) => v != null))
      );

      const message = `Alarm has been updated`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      onSubmitted?.(resp.data);
      reduxDispatch(reduxActions.assets.fetchAlarms);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setSubmittedInProgress(false);
  };

  const controlRoomMonitored =
    useConfiguration('global', 'control_room_is_monitored')?.value === '1';

  const localPressButtonValue = useMemo(() => {
    return !controlRoomMonitored &&
      item?.local_button_press_action === 'user_intervention_required'
      ? null
      : item?.local_button_press_action || null;
  }, [item, controlRoomMonitored]);

  /*********/
  /* input */
  /*********/

  const getFormikValues = (item?: AlarmModuleNode): UpdateInputBody => ({
    name: item?.name || '',
    mac_address: item?.mac_address || '',
    zone_id: item?.zone_id || null,
    status: item?.status === 'active' ? 'active' : 'inactive',
    latitude: item?.latitude || null,
    longitude: item?.longitude || null,
    description: item?.description || '',
    local_button_press_action: localPressButtonValue,
    local_button_press_action_group_id:
      item?.local_button_press_action_group_id || null,
    local_button_press_action_zone_id:
      item?.local_button_press_action_zone_id || null,
    local_button_group_zone_id: item?.local_button_press_action_group_id
      ? zoneGroupList?.find(
          (it) =>
            it.v_id === item?.local_button_press_action_group_id &&
            it.type === 'group'
        )?.id || null
      : item?.local_button_press_action_zone_id
        ? zoneGroupList?.find(
            (it) =>
              it.v_id === item?.local_button_press_action_zone_id &&
              it.type === 'zone'
          )?.id || null
        : null,
  });

  const formik = useFormik<UpdateInputBody>({
    initialValues: getFormikValues(fetchedData),
    validationSchema: getAlarmCreateSchema(),
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  useEffect(() => {
    const newInput = getFormikValues(fetchedData);
    if (!isEqual(formik.values, newInput)) {
      formik.setValues(newInput);
    }
  }, [fetchedData, zoneGroupList]);
  useEffect(() => {
    if (isActiveModal && locationCoordinates?.lat && locationCoordinates?.lng) {
      const newPosLat = +locationCoordinates.lat.toFixed(6);
      const newPosLong = +locationCoordinates.lng.toFixed(6);
      if (
        formik.values.latitude !== newPosLat ||
        formik.values.longitude !== newPosLong
      ) {
        formik.setFieldValue('latitude', newPosLat);
        formik.setFieldValue('longitude', newPosLong);
      }
    }
  }, [locationCoordinates]);

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      onSubmit={formik.handleSubmit}
    >
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}
      <Box display="flex" flexDirection="column" gap={3}>
        <TextField
          value={formik.values.name}
          label="Name"
          size="small"
          name="name"
          fullWidth
          error={!!formik.touched.name && !!formik.errors.name}
          helperText={formik.touched.name && formik.errors.name}
          onChange={formik.handleChange}
        />

        <TextField
          value={formik.values.mac_address}
          label="Mac Address"
          size="small"
          name="mac_address"
          fullWidth
          error={!!formik.touched.mac_address && !!formik.errors.mac_address}
          helperText={formik.touched.mac_address && formik.errors.mac_address}
          onChange={formik.handleChange}
        />

        <ZoneSelect
          value={formik.values.zone_id}
          label="Assigned Section"
          size="small"
          fullWidth
          error={!!formik.touched.zone_id && !!formik.errors.zone_id}
          helperText={formik.touched.zone_id && formik.errors.zone_id}
          onChange={(v) => formik.setFieldValue('zone_id', v)}
        />

        <StatusSelect
          value={formik.values.status}
          fullWidth
          name="status"
          label="Status"
          size="small"
          select
          error={!!formik.touched.status && !!formik.errors.status}
          helperText={formik.touched.status && formik.errors.status}
          onChange={formik.handleChange}
        />

        <NumberTextField
          value={formik.values.latitude}
          label="Latitude"
          size="small"
          name="latitude"
          fullWidth
          decimalPlaces={2}
          error={!!formik.touched.latitude && !!formik.errors.latitude}
          helperText={formik.touched.latitude && formik.errors.latitude}
          onChange={(v) => formik.setFieldValue('latitude', v)}
        />
        <NumberTextField
          value={formik.values.longitude}
          label="Longitude"
          size="small"
          name="longitude"
          fullWidth
          decimalPlaces={2}
          error={!!formik.touched.longitude && !!formik.errors.longitude}
          helperText={formik.touched.longitude && formik.errors.longitude}
          onChange={(v) => formik.setFieldValue('longitude', v)}
        />

        <TextField
          value={formik.values.description}
          label="Description"
          size="small"
          name="description"
          multiline
          rows={3}
          fullWidth
          error={!!formik.touched.description && !!formik.errors.description}
          helperText={formik.touched.description && formik.errors.description}
          onChange={formik.handleChange}
        />
        <Autocomplete
          value={
            localButtonPressAction.find(
              (i) => i.key === formik.values.local_button_press_action
            ) ?? null
          }
          fullWidth
          options={localButtonPressAction}
          isOptionEqualToValue={(option, value) => option.key === value?.key}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Action"
              size="small"
              error={
                !!formik.touched.local_button_press_action &&
                !!formik.errors.local_button_press_action
              }
              helperText={
                formik.touched.local_button_press_action &&
                formik.errors.local_button_press_action
              }
            />
          )}
          onChange={(event, value) =>
            formik.setFieldValue('local_button_press_action', value?.key)
          }
        />
        {(formik.values.local_button_press_action ===
          'activate_warning_group' ||
          formik.values.local_button_press_action ===
            'activate_alarm_group') && (
          <>
            <Autocomplete
              value={
                zoneGroupList.find(
                  (i) => i.id === formik.values.local_button_group_zone_id
                ) ?? null
              }
              fullWidth
              options={zoneGroupList}
              groupBy={(option) => option.type}
              isOptionEqualToValue={(option, value) => option.id === value?.id}
              getOptionLabel={(option) => option?.name ?? ''}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Group/Zone selector"
                  size="small"
                  error={
                    !!formik.touched.local_button_group_zone_id &&
                    !!formik.errors.local_button_group_zone_id
                  }
                  helperText={
                    formik.touched.local_button_group_zone_id &&
                    formik.errors.local_button_group_zone_id
                  }
                />
              )}
              onChange={(event, value) => {
                console.log(value);
                if (value?.type === 'group') {
                  formik.setFieldValue(
                    'local_button_press_action_group_id',
                    value?.v_id
                  );
                  formik.setFieldValue(
                    'local_button_press_action_zone_id',
                    null
                  );
                } else {
                  formik.setFieldValue(
                    'local_button_press_action_group_id',
                    null
                  );
                  formik.setFieldValue(
                    'local_button_press_action_zone_id',
                    value?.v_id
                  );
                }
                formik.setFieldValue('local_button_group_zone_id', value?.id);
              }}
            />
          </>
        )}
      </Box>

      <Box display="flex" justifyContent="end" gap={1.5}>
        {onCancel ? (
          mode === 'update_from_info' ? (
            <Button onClick={() => onCancel()} startIcon={<ArrowBackIcon />}>
              Back
            </Button>
          ) : (
            <Button onClick={() => onCancel()}>Cancel</Button>
          )
        ) : null}
        <Box>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={submittedInProgress}
          >
            Update
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default AlarmItemUpdate;
