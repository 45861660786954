/* eslint-disable complexity */
/* eslint-disable no-bitwise */
import {TabContext, TabList, TabPanel} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Tab,
  TextField,
} from '@mui/material';
import {isNull} from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {useEffect, useMemo, useState} from 'react';
import {useDispatch} from 'react-redux';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useAppSelector} from '../../../hooks/redux';
import {
  amsSensorAddressMask,
  GasMonitoringNode,
} from '../../../interfaces/GasMonitoringNode';
import reduxActions from '../../../redux/actions';
import {getEmoduleUnitsLabel} from '../../../utils/ams';
import AccessControl from '../../common/AccessControl';
import StatusSelect from '../../selectors/StatusSelect';
import AMSSensorItemActionsButton from './AMSSensorItemActionsButton';
import AMSSensorNodeItemAVA from './AMSSensorNodeItemAVA';

interface Props {
  pk: number;
  item?: GasMonitoringNode;
  prefetch?: boolean;
  onCancel?: () => void;
  onEdit?: () => void;
  onAck?: () => void;
  onPurged?: () => void;
  onFetched?: (item: GasMonitoringNode) => void;
  onSubmitted?: () => void;
  onOpenHistory?: (
    id: number,
    type: 'amsShortTerm' | 'amsLongTerm' | 'amsLocation'
  ) => void;
}

type TabValue = 'general' | 'sensing' | 'status';
interface TabItem {
  value: TabValue;
  label: string;
}

const AMSSensorNodeItemView = ({
  pk,
  item,
  prefetch,
  onCancel,
  onEdit,
  onAck,
  onPurged,
  onFetched,
  onOpenHistory,
  onSubmitted,
}: Props) => {
  /*********/
  /* fetch */
  /*********/
  const reduxDispatch = useDispatch();
  const tabs: TabItem[] = [
    {
      value: 'general',
      label: 'General',
    },
    {
      value: 'sensing',
      label: 'Sensing',
    },
    {
      value: 'status',
      label: 'Status',
    },
  ];
  const [activeTab, setActiveTab] = useState('general');
  const typeIdLabels = useAppSelector(({assets}) => assets.emoduleTypeIdLabel);

  const [fetchedData, setFetchedData] = useState<GasMonitoringNode | undefined>(
    cloneDeep(item)
  );
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const resp = await API.get<GasMonitoringNode>(`${apiBaseUrl}/ams/${pk}`);
      setFetchedData(resp.data);
      onFetched?.(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    reduxActions.assets.fetchGasMonitorNodes(reduxDispatch);
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (!isEqual(item, fetchedData)) {
      setFetchedData(item);
    }
  }, [item]);

  const typeII = useMemo(() => {
    const intrinsically_safe = fetchedData?.intrinsically_safe ? 'IS' : null;
    const ava = fetchedData?.ava ? 'A' : null;
    const non_sensing = fetchedData?.non_sensing ? 'N' : null;
    return [intrinsically_safe, ava, non_sensing]
      ?.filter((it) => !!it)
      ?.join(', ');
  }, [fetchedData]);

  return (
    <Box display="flex" flexDirection="column" position="relative" gap={3}>
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}

      <TabContext value={activeTab}>
        <TabList variant="fullWidth">
          {tabs.map((tab) => (
            <Tab
              key={tab.value}
              label={tab.label}
              value={tab.value}
              onClick={() => setActiveTab(tab.value)}
            />
          ))}
          {fetchedData && !fetchedData?.ava && (
            <Tab
              label="Assigned AVA"
              value="ava"
              onClick={() => setActiveTab('ava')}
            />
          )}
        </TabList>
        <TabPanel value="general" sx={{p: 0}}>
          <Box display="flex" flexDirection="column" gap={3}>
            <TextField
              value={fetchedData?.name ?? ''}
              label="Name"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={
                fetchedData?.commtrac_external_id
                  ? fetchedData?.commtrac_external_id & amsSensorAddressMask
                  : ''
              }
              label="Network ID"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={typeII}
              label="Type II"
              size="small"
              disabled
              fullWidth
            />
            <StatusSelect
              value={fetchedData?.status === 'active' ? 'active' : 'inactive'}
              fullWidth
              name="status"
              label="Status"
              size="small"
              disabled
            />

            <TextField
              value={fetchedData?.pos_lat ?? ''}
              label="Latitude"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.pos_lon ?? ''}
              label="Longitude"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.battery_voltage ?? ''}
              label="Battery Voltage"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.external_voltage ?? ''}
              label="External Voltage"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.power_source ?? ''}
              label="Power Source"
              size="small"
              disabled
              fullWidth
            />
          </Box>
        </TabPanel>
        <TabPanel value="sensing" sx={{p: 0}}>
          <Box display="flex" flexDirection="column" gap={3}>
            <TextField
              value={fetchedData?.date ?? ''}
              label="Timestamp"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={
                fetchedData && !isNull(fetchedData?.type_id)
                  ? typeIdLabels?.[fetchedData.type_id]
                  : 'None'
              }
              label="Type"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.value ?? ''}
              label="Value"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={
                fetchedData && !isNull(fetchedData?.unit_id)
                  ? getEmoduleUnitsLabel(
                      fetchedData?.type_id,
                      fetchedData?.unit_id
                    )
                  : ''
              }
              label="Units"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.sensor_serial_number ?? ''}
              label="Sensor Serial Number"
              size="small"
              disabled
              fullWidth
            />
          </Box>
        </TabPanel>
        <TabPanel value="status" sx={{p: 0}}>
          <Box display="flex" flexDirection="column" gap={3}>
            <TextField
              value={fetchedData?.callibration_date ?? ''}
              label="Calibration Date"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.module_absent ?? ''}
              label="Module Absent"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.module_warning ?? ''}
              label="Module Warming Up"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.point1_tripped ?? ''}
              label="Set point 1 tripped"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.point2_tripped ?? ''}
              label="Set point 2 tripped"
              size="small"
              disabled
              fullWidth
            />

            <TextField
              value={fetchedData?.stel_alarm ?? ''}
              label="STEL Alarm"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.twa_alarm ?? ''}
              label="TWA Alarm"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.fault ?? ''}
              label="Fault"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.pellister_over ?? ''}
              label="Pellistor Over"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.input1 ?? ''}
              label="Input 1"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.input2 ?? ''}
              label="Input 2"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.output1 ?? ''}
              label="Output 1"
              size="small"
              disabled
              fullWidth
            />
            <TextField
              value={fetchedData?.output2 ?? ''}
              label="Output 2"
              size="small"
              disabled
              fullWidth
            />
          </Box>
        </TabPanel>
        {fetchedData && !fetchedData?.ava && (
          <TabPanel value="ava" sx={{p: 0}}>
            <AMSSensorNodeItemAVA
              pk={pk}
              item={fetchedData}
              onFetched={fetchData}
            />
          </TabPanel>
        )}
      </TabContext>

      <Box display="flex" justifyContent="space-between">
        <Box display="flex" justifyContent="start" gap={0.5}>
          {item && (
            <AMSSensorItemActionsButton
              item={item}
              onOpenHistory={onOpenHistory}
              onCancel={onCancel}
              onSubmitted={() => {
                onSubmitted?.();
                fetchData();
              }}
              onPurged={onPurged}
            />
          )}

          {fetchedData ? (
            <>
              {onAck && fetchedData?.ack !== '1' ? (
                <Button onClick={() => onAck()}>Acknowledge</Button>
              ) : null}
            </>
          ) : null}
        </Box>
        <Box display="flex" justifyContent="end" gap={0.5}>
          {onCancel ? <Button onClick={() => onCancel()}>Cancel</Button> : null}
          {onEdit ? (
            <AccessControl permissions={['patch::/ams/:id(\\d+)']}>
              <Button onClick={() => onEdit()}>Edit Sensor</Button>
            </AccessControl>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default AMSSensorNodeItemView;
