import {LoadingButton} from '@mui/lab';
import {Box} from '@mui/material';
import dayjs from 'dayjs';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {saveFile} from '../../../utils/file';
import {CloseSnackbarAction} from '../../common/CloseSnackbarButton';
import ExportFieldForm from '../../common/ExportFieldForm';
import ModalFixed from '../../common/ModalFixed';
import SnackbarMessages from '../../common/SnackbarMessages';
import {EventsReportData} from '../ConnectView/EventsReport';

interface Props {
  value?: EventsReportData;
}

const CommunicationNodeTrackingExportExcel = ({value}: Props) => {
  const {enqueueSnackbar} = useSnackbar();
  const [isFetching, setIsFetching] = useState(false);

  const [isOpened, setIsOpened] = useState(false);

  const exportFieldOptions = [
    {field: 'id', label: 'ID', hidden: false},
    {field: 'timestamp', label: 'Timestamp', hidden: false},
    {field: 'message_type', label: 'Message Type', hidden: false},
    {field: 'message', label: 'Message', hidden: false},
    {field: 'message_id', label: 'Message ID', hidden: false},
    {
      field: 'routing_root_address',
      label: 'Root Address',
      hidden: false,
    },
    {
      field: 'routing_parent_address',
      label: 'Parent Address',
      hidden: false,
    },
    {field: 'routing_parent_rssi', label: 'Parent RSSI', hidden: false},
    {
      field: 'routing_number_of_hops',
      label: 'Hops to Root',
      hidden: false,
    },
    {field: 'timing_root_address', label: 'Timing Root Address', hidden: false},
    {
      field: 'timing_parent_address',
      label: 'Timing Parent Address',
      hidden: false,
    },
    {field: 'timing_parent_rssi', label: 'Timing Parent RSSI', hidden: false},
    {
      field: 'timing_number_of_hops',
      label: 'Timing Hops to Root',
      hidden: false,
    },
    {
      field: 'lost_routing_parents',
      label: 'Lost Routing Parents',
      hidden: false,
    },
    {field: 'lost_timing_parents', label: 'Lost Timing Parents', hidden: false},
    {
      field: 'changed_routing_parents',
      label: 'Changed Routing Parents',
      hidden: false,
    },
    {
      field: 'changed_timing_parents',
      label: 'Changed Timing Parents',
      hidden: false,
    },
    {field: 'network_entry', label: 'Network Entry', hidden: false},
    {
      field: 'network_entry_reason',
      label: 'Network Entry Reason',
      hidden: false,
    },
    {field: 'idle_subframes', label: 'Idle Subframes', hidden: false},
    {field: 'cc1110_version', label: 'CC1110 Version', hidden: false},
    {field: 'pic_version', label: 'Pic Version', hidden: false},
    {field: 'heartbeat_count', label: 'Heartbeat Count', hidden: false},
    {
      field: 'message_overflow_count',
      label: 'Message Overflow Count',
      hidden: false,
    },
    {
      field: 'neighbor_table_count',
      label: 'Neighbor Table Count',
      hidden: false,
    },
    {field: 'neighbor_table_full', label: 'Neighbor Table Full', hidden: false},
    {
      field: 'routing_above_threshold',
      label: 'Routing Above Threshold',
      hidden: false,
    },
    {
      field: 'timing_above_threshold',
      label: 'Timing Above Threshold',
      hidden: false,
    },
  ];

  const checkFields = () => {
    setIsOpened(true);
  };

  const fetchData = async (fields: string[]) => {
    setIsOpened(false);
    setIsFetching(true);

    console.log(value);
    console.log('yes');
    try {
      const params = {
        export: 'excel',
        fields,
        external_id: value?.params.external_id,
        date_start: value?.params.date_start,
        date_end: value?.params.date_end,
        message_type: value?.params.message_type,
      };

      const resp = await API.get(
        `${apiBaseUrl}/communication-node/event-grid`,
        {
          params,
          responseType: 'blob',
        }
      );
      saveFile(
        resp.data,
        `events_${value?.params.date_start ?? dayjs().format('YYYY-MM-DD')}_${value?.params.date_end ?? dayjs().format('YYYY-MM-DD')}.xlsx`
      );
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setIsFetching(false);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" justifyContent="end">
        <LoadingButton
          fullWidth
          size="large"
          variant="contained"
          loading={isFetching}
          onClick={() => checkFields()}
        >
          Export to Excel
        </LoadingButton>
      </Box>
      <ModalFixed open={isOpened} onClose={() => setIsOpened(false)}>
        <Box
          display="flex"
          flexDirection="column"
          position="relative"
          gap={3}
          p={3}
        >
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center" width="100%" gap={1.5}>
              <ExportFieldForm
                exportFields={exportFieldOptions}
                onChange={fetchData}
                close={() => setIsOpened(false)}
                loading={isFetching}
                columns={3}
              ></ExportFieldForm>
            </Box>
          </Box>
        </Box>
      </ModalFixed>
    </Box>
  );
};

export default CommunicationNodeTrackingExportExcel;
