import {LoadingButton} from '@mui/lab';
import {Box} from '@mui/material';
import dayjs from 'dayjs';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {saveFile} from '../../../utils/file';
import {CloseSnackbarAction} from '../../common/CloseSnackbarButton';
import ExportFieldForm from '../../common/ExportFieldForm';
import ModalFixed from '../../common/ModalFixed';
import SnackbarMessages from '../../common/SnackbarMessages';
import {EventsReportData} from '../ConnectView/EventsReport';

interface Props {
  value?: EventsReportData;
  id?: number;
}

const CommtracNodeTrackingExportExcel = ({value}: Props) => {
  const {enqueueSnackbar} = useSnackbar();
  const [isFetching, setIsFetching] = useState(false);

  const [isOpened, setIsOpened] = useState(false);

  const checkFields = () => {
    setIsOpened(true);
  };

  const fetchData = async (fields: string[]) => {
    setIsOpened(false);
    setIsFetching(true);
    console.log(value);
    try {
      const params = {
        //...value?.params,
        export: 'excel',
        fields,
        id: value?.params.id,
        date_start: value?.params.date_start,
        date_end: value?.params.date_end,
      };

      const resp = await API.get(
        `${apiBaseUrl}/commtrac-node/${params.id}/history/report`,
        {
          params,
          responseType: 'blob',
        }
      );
      saveFile(
        resp.data,
        `events_${value?.params.date_start ?? dayjs().format('YYYY-MM-DD')}_${value?.params.date_end ?? dayjs().format('YYYY-MM-DD')}.xlsx`
      );
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setIsFetching(false);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" justifyContent="end">
        <LoadingButton
          fullWidth
          size="large"
          variant="contained"
          loading={isFetching}
          onClick={() => checkFields()}
        >
          Export to Excel
        </LoadingButton>
      </Box>
      <ModalFixed open={isOpened} onClose={() => setIsOpened(false)}>
        <Box
          display="flex"
          flexDirection="column"
          position="relative"
          gap={3}
          p={3}
        >
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center" width="100%" gap={1.5}>
              <ExportFieldForm
                exportFields={value?.exportFields}
                onChange={fetchData}
                close={() => setIsOpened(false)}
                loading={isFetching}
                columns={1}
              ></ExportFieldForm>
            </Box>
          </Box>
        </Box>
      </ModalFixed>
    </Box>
  );
};

export default CommtracNodeTrackingExportExcel;
