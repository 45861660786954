/* eslint-disable complexity */
import CloseIcon from '@mui/icons-material/Close';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  IconButton,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {useMemo, useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {UseGetLabel} from '../../hooks/label';
import {useAppDispatch, useAppSelector} from '../../hooks/redux';
import {
  AssetMachine,
  AssetMachineCreateInputBody,
} from '../../interfaces/AssetMachine';
import reduxActions from '../../redux/actions';
import {assetMachineCreateInputSchema} from '../../scheme/yup/asset-machine';
import {maxNetworkId, maxProximityId} from '../../scheme/yup/utils';
import {
  getIsCommtracExternalIdVisible,
  getIsHazardAIFieldsVisible,
  getIsMacAddressVisible,
  getIsMaxVoltageVisible,
  getIsMinVoltageVisible,
  getIsWifiEnabledVisible,
} from '../../utils/commtrac-nodes';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import NumberTextField from '../common/NumberTextField';
import SnackbarMessages from '../common/SnackbarMessages';
import {MachineTypeSelect} from '../selectors/MachineTypeSelect';
import {SiteSelect} from '../selectors/SiteSelect';
import StatusSelect from '../selectors/StatusSelect';
import {ZoneSelect} from '../selectors/ZoneSelect';
import AssetMachineReassignDialog from './AssetMachineReassignDialog';

interface Props {
  onCancel?: () => void;
  onSubmitted?: (item: AssetMachine) => void;
}

type CreateInputBody = AssetMachineCreateInputBody & {
  proximity_enabled: boolean | undefined;
  hazard_ai_enabled: boolean | undefined;
  commtrac_enabled: boolean | undefined;
  is_commtrac: boolean | null;
  is_proximity: boolean | null;
};

const AssetMachineItemCreate = ({onCancel, onSubmitted}: Props) => {
  const reduxDispatch = useAppDispatch();

  /**********/
  /* submit */
  /**********/

  const {enqueueSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const company = useAppSelector(({assets}) => assets.company);

  const submitData = async (data: CreateInputBody) => {
    setSubmittedInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/asset-machine`;
      const resp = await API.post<AssetMachine>(endpoint, {
        ...data,
        is_commtrac: undefined,
        commtrac_external_id: isCommtracExternalIdVisible
          ? data.commtrac_external_id
          : undefined,
        mac_address: isMacAddressVisible ? data.mac_address : undefined,
        wifi_enabled: isWifiEnabledVisible ? data.wifi_enabled : undefined,
        min_voltage: isMinVoltageVisible ? data.min_voltage : undefined,
        max_voltage: isMaxVoltageVisible ? data.max_voltage : undefined,
      });
      const message = `Asset successfully created`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      onSubmitted?.(resp.data);
      reduxDispatch(reduxActions.assets.fetchAssetMachines);
      reduxDispatch(reduxActions.assets.fetchCommtracNodes);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/
  const getFormikValues = (): CreateInputBody => ({
    proximity_enabled: company?.proximity_enabled,
    hazard_ai_enabled: company?.hazard_ai_enabled,
    commtrac_enabled: company?.commtrac_enabled,
    is_commtrac: false,
    wifi_enabled: false,
    name: '',
    type_id: null,
    zone_id: null,
    site_id: null,
    status: 'active',
    external_id: null,
    events_flag: 'YES',
    min_voltage: null,
    max_voltage: null,
    input_1_type: 0,
    input_2_type: 0,
    input_3_type: 0,
    input_4_type: 0,
    input_5_type: 0,
    commtrac_external_id: null,
    mac_address: null,
    force_reassign: false,
    is_proximity: false,
  });

  const formik = useFormik<CreateInputBody>({
    initialValues: getFormikValues(),
    validationSchema: assetMachineCreateInputSchema,
    onSubmit: async (values) => {
      if (commtracNodeToReassign && !values.force_reassign) {
        setIsReassignDialogOpened(true);
      } else {
        await submitData(values);
      }
    },
  });

  const isWifiEnabledVisible = getIsWifiEnabledVisible(
    formik.values.is_commtrac
  );
  const isCommtracExternalIdVisible = getIsCommtracExternalIdVisible(
    formik.values.is_commtrac,
    formik.values.wifi_enabled
  );
  const isMacAddressVisible = getIsMacAddressVisible(
    formik.values.is_commtrac,
    formik.values.wifi_enabled
  );
  const isMinVoltageVisible = getIsMinVoltageVisible(
    formik.values.is_commtrac,
    formik.values.wifi_enabled
  );
  const isMaxVoltageVisible = getIsMaxVoltageVisible(
    formik.values.is_commtrac,
    formik.values.wifi_enabled
  );
  const isHazardAIFieldsVisible = getIsHazardAIFieldsVisible(
    company?.hazard_ai_enabled
  );

  const isProximity = formik.values.is_proximity;

  const machineInputs = useAppSelector(({assets}) => assets.machine_inputs);

  /*********************/
  /* commtrac reassign */
  /*********************/

  const [isReassignDialogOpened, setIsReassignDialogOpened] = useState(false);
  const commtracNodes = useAppSelector(({assets}) => assets.commtrac_nodes);

  const commtracNodeToReassign = useMemo(() => {
    if (isCommtracExternalIdVisible && formik.values.commtrac_external_id) {
      return commtracNodes.find(
        (i) => i.commtrac_external_id === formik.values.commtrac_external_id
      );
    } else if (isMacAddressVisible && formik.values.mac_address) {
      return commtracNodes.find(
        (i) => i.mac_address === formik.values.mac_address
      );
    }
  }, [
    formik.values.commtrac_external_id,
    formik.values.mac_address,
    isCommtracExternalIdVisible,
    isMacAddressVisible,
  ]);

  return (
    <>
      <Box
        component="form"
        display="flex"
        flexDirection="column"
        position="relative"
        gap={3}
        p={3}
        onSubmit={formik.handleSubmit}
      >
        <Box display="flex" justifyContent="space-between">
          <Box
            className="dragHandle"
            display="flex"
            alignItems="center"
            width="100%"
            gap={1.5}
            sx={{cursor: 'grab'}}
          >
            <LocalShippingIcon sx={{color: 'primary.main'}} />
            <Box
              fontSize={24}
            >{`Create ${UseGetLabel({label: 'machine'})}`}</Box>
          </Box>
          {onCancel ? (
            <IconButton onClick={() => onCancel()}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </Box>
        <Box display="flex" flexDirection="column" gap={3}>
          {company?.commtrac_enabled ? (
            <FormControl>
              <FormLabel id="demo-form-control-label-placement">
                {`Connect enabled ${UseGetLabel({label: 'machine'})}`}
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                defaultValue="top"
              >
                <FormControlLabel
                  label="Yes"
                  control={
                    <Radio
                      checked={!!formik.values.is_commtrac}
                      onChange={() => {
                        formik.setFieldValue('is_commtrac', true);
                      }}
                      value="true"
                      name="radio-buttons"
                      inputProps={{'aria-label': 'Yes'}}
                    />
                  }
                />
                <FormControlLabel
                  label="No"
                  control={
                    <Radio
                      checked={!formik.values.is_commtrac}
                      onChange={() => {
                        formik.setFieldValue('is_commtrac', false);
                      }}
                      value="true"
                      name="radio-buttons"
                      inputProps={{'aria-label': 'No'}}
                    />
                  }
                />
              </RadioGroup>
            </FormControl>
          ) : null}

          <TextField
            value={formik.values.name}
            label="Name"
            size="small"
            name="name"
            fullWidth
            error={!!formik.touched.name && !!formik.errors.name}
            helperText={formik.touched.name && formik.errors.name}
            onChange={formik.handleChange}
          />

          {isWifiEnabledVisible ? (
            <FormControl>
              <FormLabel id="demo-form-control-label-placement">
                WIFI enabled
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                defaultValue="top"
              >
                <FormControlLabel
                  label="Yes"
                  control={
                    <Radio
                      checked={!!formik.values.wifi_enabled}
                      onChange={() => {
                        formik.setFieldValue('wifi_enabled', true);
                      }}
                      value="true"
                      name="radio-buttons"
                      inputProps={{'aria-label': 'Yes'}}
                    />
                  }
                />
                <FormControlLabel
                  label="No"
                  control={
                    <Radio
                      checked={!formik.values.wifi_enabled}
                      onChange={() => {
                        formik.setFieldValue('wifi_enabled', false);
                      }}
                      value="true"
                      name="radio-buttons"
                      inputProps={{'aria-label': 'No'}}
                    />
                  }
                />
              </RadioGroup>
              <FormHelperText error>
                {formik.errors.wifi_enabled ? 'Field is required' : ''}
              </FormHelperText>
            </FormControl>
          ) : null}

          {isCommtracExternalIdVisible ? (
            <NumberTextField
              value={formik.values.commtrac_external_id}
              min={1}
              max={maxNetworkId}
              label="Network ID"
              size="small"
              name="commtrac_external_id"
              fullWidth
              error={
                !!formik.touched.commtrac_external_id &&
                !!formik.errors.commtrac_external_id
              }
              helperText={
                formik.touched.commtrac_external_id &&
                formik.errors.commtrac_external_id
              }
              onChange={(v) => formik.setFieldValue('commtrac_external_id', v)}
            />
          ) : null}

          {isCommtracExternalIdVisible && commtracNodeToReassign ? (
            <Alert color="warning">
              Network ID {formik.values.commtrac_external_id} is assigned to
              another {commtracNodeToReassign.type}. Are you sure you want to
              reassign it to this asset?
            </Alert>
          ) : null}

          {isMacAddressVisible ? (
            <TextField
              value={formik.values.mac_address}
              label="Mac Address"
              size="small"
              name="mac_address"
              fullWidth
              error={
                !!formik.touched.mac_address && !!formik.errors.mac_address
              }
              helperText={
                formik.touched.mac_address && formik.errors.mac_address
              }
              onChange={formik.handleChange}
            />
          ) : null}

          {isMacAddressVisible && commtracNodeToReassign ? (
            <Alert color="warning">
              Mac Address {formik.values.mac_address} is assigned to another{' '}
              {commtracNodeToReassign.type}. Are you sure you want to reassign
              it to this asset?
            </Alert>
          ) : null}

          <MachineTypeSelect
            value={formik.values.type_id}
            label="Machine Type"
            size="small"
            fullWidth
            error={!!formik.touched.type_id && !!formik.errors.type_id}
            helperText={formik.touched.type_id && formik.errors.type_id}
            onChange={(v) => formik.setFieldValue('type_id', v)}
          />

          {company?.commtrac_enabled || company?.proximity_enabled ? (
            <ZoneSelect
              value={formik.values.zone_id}
              label="Assigned Section"
              size="small"
              fullWidth
              error={!!formik.touched.zone_id && !!formik.errors.zone_id}
              helperText={formik.touched.zone_id && formik.errors.zone_id}
              onChange={(v) => formik.setFieldValue('zone_id', v)}
            />
          ) : null}

          {isHazardAIFieldsVisible ? (
            <SiteSelect
              value={formik.values.site_id}
              size="small"
              label="Assigned Site"
              fullWidth
              error={!!formik.touched.site_id && !!formik.errors.site_id}
              helperText={formik.touched.site_id && formik.errors.site_id}
              onChange={(v) => formik.setFieldValue('site_id', v)}
            />
          ) : null}

          <StatusSelect
            value={formik.values.status}
            fullWidth
            name="status"
            label="Status"
            size="small"
            select
            error={!!formik.touched.status && !!formik.errors.status}
            helperText={formik.touched.status && formik.errors.status}
            onChange={formik.handleChange}
          />

          {isMinVoltageVisible ? (
            <NumberTextField
              value={
                formik.values.min_voltage
                  ? formik.values.min_voltage / 10
                  : formik.values.min_voltage
              }
              decimalPlaces={1}
              min={0}
              max={10}
              step={0.1}
              label="Min Voltage"
              size="small"
              name="min_voltage"
              fullWidth
              error={
                !!formik.touched.min_voltage && !!formik.errors.min_voltage
              }
              helperText={
                formik.touched.min_voltage && formik.errors.min_voltage
              }
              onChange={(v) =>
                formik.setFieldValue('min_voltage', v ? v * 10 : v)
              }
            />
          ) : null}

          {isMaxVoltageVisible ? (
            <NumberTextField
              value={
                formik.values.max_voltage
                  ? formik.values.max_voltage / 10
                  : formik.values.max_voltage
              }
              decimalPlaces={1}
              min={0}
              max={10}
              step={0.1}
              label="Max Voltage"
              size="small"
              name="max_voltage"
              fullWidth
              error={
                !!formik.touched.max_voltage && !!formik.errors.max_voltage
              }
              helperText={
                formik.touched.max_voltage && formik.errors.max_voltage
              }
              onChange={(v) =>
                formik.setFieldValue('max_voltage', v ? v * 10 : v)
              }
            />
          ) : null}

          {company?.proximity_enabled ? (
            <FormControl>
              <FormLabel id="demo-form-control-label-placement">
                {`Proximity enabled ${UseGetLabel({label: 'machine'})}`}
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-form-control-label-placement"
                name="position"
                defaultValue="top"
              >
                <FormControlLabel
                  label="Yes"
                  control={
                    <Radio
                      checked={!!formik.values.is_proximity}
                      onChange={() => {
                        formik.setFieldValue('is_proximity', true);
                      }}
                      value="true"
                      name="radio-buttons"
                      inputProps={{'aria-label': 'Yes'}}
                    />
                  }
                />
                <FormControlLabel
                  label="No"
                  control={
                    <Radio
                      checked={!formik.values.is_proximity}
                      onChange={() => {
                        formik.setFieldValue('is_proximity', false);
                      }}
                      value="true"
                      name="radio-buttons"
                      inputProps={{'aria-label': 'No'}}
                    />
                  }
                />
              </RadioGroup>
            </FormControl>
          ) : null}

          {isProximity ? (
            <TextField
              value={formik.values.events_flag}
              label="Monitor Events"
              size="small"
              name="events_flag"
              select
              fullWidth
              error={
                !!formik.touched.events_flag && !!formik.errors.events_flag
              }
              helperText={
                formik.touched.events_flag && formik.errors.events_flag
              }
              onChange={formik.handleChange}
            >
              {[
                {value: 'YES', name: 'Yes'},
                {value: 'NO', name: 'No'},
              ].map((i) => (
                <MenuItem key={i.name} value={i.value}>
                  {i.name}
                </MenuItem>
              ))}
            </TextField>
          ) : null}

          {isProximity ? (
            <NumberTextField
              value={formik.values.external_id}
              min={1}
              max={maxProximityId}
              label="Proximity ID"
              size="small"
              name="external_id"
              fullWidth
              error={
                !!formik.touched.external_id && !!formik.errors.external_id
              }
              helperText={
                formik.touched.external_id && formik.errors.external_id
              }
              onChange={(v) => formik.setFieldValue('external_id', v)}
            />
          ) : null}

          {isProximity ? (
            <TextField
              value={formik.values.input_1_type ?? ''}
              fullWidth
              name="input_1_type"
              label="Machine Input 1"
              size="small"
              select
              error={
                !!formik.touched.input_1_type && !!formik.errors.input_1_type
              }
              helperText={
                formik.touched.input_1_type && formik.errors.input_1_type
              }
              onChange={formik.handleChange}
            >
              <MenuItem value={0}>Unassigned</MenuItem>
              {machineInputs.map((i) => (
                <MenuItem key={i.id} value={i.id}>
                  {i.name}
                </MenuItem>
              ))}
            </TextField>
          ) : null}

          {isProximity ? (
            <TextField
              value={formik.values.input_2_type ?? ''}
              fullWidth
              name="input_2_type"
              label="Machine Input 2"
              size="small"
              select
              error={
                !!formik.touched.input_2_type && !!formik.errors.input_2_type
              }
              helperText={
                formik.touched.input_2_type && formik.errors.input_2_type
              }
              onChange={formik.handleChange}
            >
              <MenuItem value={0}>Unassigned</MenuItem>
              {machineInputs.map((i) => (
                <MenuItem key={i.id} value={i.id}>
                  {i.name}
                </MenuItem>
              ))}
            </TextField>
          ) : null}

          {isProximity ? (
            <TextField
              value={formik.values.input_3_type ?? ''}
              fullWidth
              name="input_3_type"
              label="Machine Input 3"
              size="small"
              select
              error={
                !!formik.touched.input_3_type && !!formik.errors.input_3_type
              }
              helperText={
                formik.touched.input_3_type && formik.errors.input_3_type
              }
              onChange={formik.handleChange}
            >
              <MenuItem value={0}>Unassigned</MenuItem>
              {machineInputs.map((i) => (
                <MenuItem key={i.id} value={i.id}>
                  {i.name}
                </MenuItem>
              ))}
            </TextField>
          ) : null}

          {isProximity ? (
            <TextField
              value={formik.values.input_4_type ?? ''}
              fullWidth
              name="input_4_type"
              label="Machine Input 4"
              size="small"
              select
              error={
                !!formik.touched.input_4_type && !!formik.errors.input_4_type
              }
              helperText={
                formik.touched.input_4_type && formik.errors.input_4_type
              }
              onChange={formik.handleChange}
            >
              <MenuItem value={0}>Unassigned</MenuItem>
              {machineInputs.map((i) => (
                <MenuItem key={i.id} value={i.id}>
                  {i.name}
                </MenuItem>
              ))}
            </TextField>
          ) : null}

          {isProximity ? (
            <TextField
              value={formik.values.input_5_type ?? ''}
              fullWidth
              name="input_5_type"
              label="Machine Input 5"
              size="small"
              select
              error={
                !!formik.touched.input_5_type && !!formik.errors.input_5_type
              }
              helperText={
                formik.touched.input_5_type && formik.errors.input_5_type
              }
              onChange={formik.handleChange}
            >
              <MenuItem value={0}>Unassigned</MenuItem>
              {machineInputs.map((i) => (
                <MenuItem key={i.id} value={i.id}>
                  {i.name}
                </MenuItem>
              ))}
            </TextField>
          ) : null}

          {isCommtracExternalIdVisible ? (
            <TextField
              value={300}
              label="Report Frequency (s)"
              size="small"
              disabled
              fullWidth
            />
          ) : null}
        </Box>

        <Box display="flex" justifyContent="end" gap={1}>
          {onCancel ? <Button onClick={() => onCancel()}>Cancel</Button> : null}
          <Box>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={submittedInProgress}
            >
              Create
            </LoadingButton>
          </Box>
        </Box>
      </Box>

      {isReassignDialogOpened && (
        <AssetMachineReassignDialog
          open={isReassignDialogOpened}
          wifiEnabled={formik.values.wifi_enabled}
          macAddress={formik.values.mac_address}
          commtracExternalId={formik.values.commtrac_external_id}
          onClose={() => setIsReassignDialogOpened(false)}
          onConfirm={() => {
            submitData({
              ...formik.values,
              force_reassign: true,
            });
            setIsReassignDialogOpened(false);
          }}
        />
      )}
    </>
  );
};

export default AssetMachineItemCreate;
