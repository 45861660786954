import {LoadingButton} from '@mui/lab';
import {Box} from '@mui/material';
import dayjs from 'dayjs';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {saveFile} from '../../utils/file';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import ExportFieldForm from '../common/ExportFieldForm';
import ModalFixed from '../common/ModalFixed';
import SnackbarMessages from '../common/SnackbarMessages';
import {NodesReportData} from '../dashboard-panels/ConnectView/NodesReport';

enum FIELD_LABELS {
  id = 'ID',
  name = 'Name',
  status = 'Status',
  no_heartbeat = 'Connection Status',
  wifi_ip = 'IP',
  wifi_mac_switch = 'Switch',
  wifi_mac_radio_1 = 'Radio 1',
  wifi_mac_radio_2 = 'Radio 2',
  external_id = 'Serial Number',
  node_type = 'Type',
  wifi_last_ping = 'Last Ping',
  wifi_last_response = 'Last Response',
  wifi_last_latency = 'Latency',
  latitude = 'Lat',
  longitude = 'Lon',
  zone = 'Section',
}

interface Props {
  value?: NodesReportData;
}

const WifiPointExport = ({value}: Props) => {
  const {enqueueSnackbar} = useSnackbar();
  const [isFetching, setIsFetching] = useState(false);
  const [showExportFieldModal, setShowExportFieldModal] = useState(false);

  const fieldsVisibility = Object.fromEntries(
    (value?.exportFields || []).map((f) => [f.field, f.hidden])
  );
  const fields = Object.entries(FIELD_LABELS).map(([field, label]) => {
    return {field, label, hidden: fieldsVisibility[field]};
  });

  const fetchData = async (fields: string[]) => {
    setIsFetching(true);
    try {
      const resp = await API.get(`${apiBaseUrl}/node/wifi-point.csv`, {
        params: {
          ...value?.params,
          fields,
        },
        responseType: 'blob',
      });
      saveFile(resp.data, `wifi-points-${dayjs().format('YYYY-MM-DD')}.csv`);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setIsFetching(false);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" justifyContent="end">
        <LoadingButton
          fullWidth
          size="large"
          variant="contained"
          loading={isFetching}
          onClick={() => setShowExportFieldModal(true)}
        >
          Export as CSV
        </LoadingButton>
      </Box>

      <ModalFixed
        open={showExportFieldModal}
        onClose={() => setShowExportFieldModal(false)}
      >
        <Box
          display="flex"
          flexDirection="column"
          position="relative"
          gap={3}
          p={3}
        >
          <ExportFieldForm
            exportFields={fields}
            loading={isFetching}
            onChange={fetchData}
            close={() => setShowExportFieldModal(false)}
          />
        </Box>
      </ModalFixed>
    </Box>
  );
};

export default WifiPointExport;
