/* eslint-disable no-bitwise */
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {useSnackbar} from 'notistack';
import {useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {BeltEditInputBody} from '../../../interfaces/BeltMonitoring';
import {
  beltAddressMask,
  BeltMonitoringNode,
} from '../../../interfaces/BeltMonitoringNode';
import reduxActions from '../../../redux/actions';
import {beltEditSchema} from '../../../scheme/yup/belt-node';
import {CloseSnackbarAction} from '../../common/CloseSnackbarButton';
import {MapLatLangCoordinates} from '../../common/HazardMap';
import SnackbarMessages from '../../common/SnackbarMessages';
import StatusSelect from '../../selectors/StatusSelect';
import {ZoneSelect} from '../../selectors/ZoneSelect';
import BeltNodeItemReset from './BeltNodeItemReset';

interface Props {
  pk: number;
  mode?: 'view' | 'update' | 'ack' | 'update_from_info';
  item?: BeltMonitoringNode;
  prefetch?: boolean;
  locationCoordinates?: MapLatLangCoordinates;
  onCancel?: Function;
  onSubmitted?: (item: BeltMonitoringNode) => void;
}

const BeltNodeItemUpdate = ({
  pk,
  mode,
  item,
  prefetch,
  locationCoordinates,
  onCancel,
  onSubmitted,
}: Props) => {
  const [reset, setReset] = useState<boolean>(false);
  const reduxDispatch = useDispatch();

  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] = useState<
    BeltMonitoringNode | undefined
  >(cloneDeep(item));
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const resp = await API.get<BeltMonitoringNode>(
        `${apiBaseUrl}/belt/${pk}`
      );
      setFetchedData(resp.data);
      formik.setValues(getFormikValues(resp.data));
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (!isEqual(item, fetchedData)) {
      setFetchedData(item);
      formik.setValues(getFormikValues(item));
    }
  }, [item]);

  /**********/
  /* submit */
  /**********/

  const {enqueueSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async (data: BeltEditInputBody) => {
    setSubmittedInProgress(true);
    const payload = {
      name: data.name,
      status: data.status,
      zone_id: data.zone_id,
      latitude: data.latitude,
      longitude: data.longitude,
    };

    try {
      const endpoint = `${apiBaseUrl}/belt/${pk}`;
      const resp = await API.patch<BeltMonitoringNode>(endpoint, payload);

      //Update Network ID
      const networkID = (item?.commtrac_external_id ?? -1) & beltAddressMask;
      if (data.network_id !== networkID) {
        const endpointNetworkID = `${apiBaseUrl}/belt/${pk}/network-id`;
        await API.patch<BeltMonitoringNode>(endpointNetworkID, {
          commtrac_external_id: data.network_id,
        });
      }

      await reduxActions.assets.fetchBeltNodes(reduxDispatch);
      onSubmitted?.(resp.data);

      const message = `BeltNode successfully updated`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });

      if (data.reporting_frequency !== item?.reporting_frequency) {
        if ((data.reporting_frequency ?? 0) < 30) {
          const message = `Reporting Frequency must be at least 30`;
          enqueueSnackbar(message, {
            variant: 'error',
            action: CloseSnackbarAction,
          });
        } else {
          const report_endpoint = `${apiBaseUrl}/belt/${pk}/reporting-frequency`;
          const report_data = {
            value: data.reporting_frequency,
          };
          await API.patch<BeltMonitoringNode>(report_endpoint, report_data);
          const message = `Reporting Frequency successfully updated`;
          enqueueSnackbar(message, {
            variant: 'success',
            action: CloseSnackbarAction,
          });
        }
      }

      if (data.location_frequency !== item?.location_frequency) {
        if ((data.location_frequency ?? 0) < 30) {
          const message = `Location Frequency must be at least 30`;
          enqueueSnackbar(message, {
            variant: 'error',
            action: CloseSnackbarAction,
          });
        } else {
          const location_endpoint = `${apiBaseUrl}/belt/${pk}/location-frequency`;
          const location_data = {
            value: data.location_frequency,
          };
          await API.patch<BeltMonitoringNode>(location_endpoint, location_data);
          const message = `Location Frequency successfully updated`;
          enqueueSnackbar(message, {
            variant: 'success',
            action: CloseSnackbarAction,
          });
        }
      }
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/
  const getFormikValues = (item?: BeltMonitoringNode): BeltEditInputBody => ({
    name: item?.name ?? null,
    status: item?.status ?? null,
    network_id: item?.commtrac_external_id
      ? item?.commtrac_external_id & beltAddressMask
      : null,
    zone_id: item?.zone_id ?? null,
    reporting_frequency: item?.reporting_frequency ?? null,
    location_frequency: item?.location_frequency ?? null,
    latitude: item?.latitude ?? null,
    longitude: item?.longitude ?? null,
  });

  const formik = useFormik<BeltEditInputBody>({
    initialValues: getFormikValues(fetchedData),
    validationSchema: beltEditSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  useEffect(() => {
    const newInput = getFormikValues(fetchedData);
    if (!isEqual(formik.values, newInput)) {
      formik.setValues(newInput);
    }
  }, [fetchedData]);

  useEffect(() => {
    if (locationCoordinates?.lat && locationCoordinates?.lng) {
      const newPosLat = +locationCoordinates.lat.toFixed(6);
      const newPosLong = +locationCoordinates.lng.toFixed(6);
      if (
        formik.values.latitude !== newPosLat ||
        formik.values.longitude !== newPosLong
      ) {
        formik.setFieldValue('latitude', newPosLat);
        formik.setFieldValue('longitude', newPosLong);
      }
    }
  }, [locationCoordinates]);

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      onSubmit={formik.handleSubmit}
    >
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}
      <Box display="flex" flexDirection="column" gap={3}>
        <TextField
          value={formik.values.name ?? ''}
          label="Name"
          name="name"
          size="small"
          fullWidth
          onChange={formik.handleChange}
        />
        <StatusSelect
          value={formik.values.status}
          fullWidth
          name="status"
          label="Status"
          size="small"
          select
          error={!!formik.touched.status && !!formik.errors.status}
          helperText={formik.touched.status && formik.errors.status}
          onChange={formik.handleChange}
        />
        <TextField
          value={formik.values.network_id ?? ''}
          label="Network Id"
          name="network_id"
          size="small"
          fullWidth
          onChange={formik.handleChange}
        />
        <ZoneSelect
          value={formik.values.zone_id}
          label="Section"
          size="small"
          fullWidth
          error={!!formik.touched.zone_id && !!formik.errors.zone_id}
          helperText={formik.touched.zone_id && formik.errors.zone_id}
          onChange={(v) => formik.setFieldValue('zone_id', v)}
        />
        <TextField
          value={formik.values.reporting_frequency ?? 60}
          label="Reporting Frequency"
          name="reporting_frequency"
          type="number"
          size="small"
          fullWidth
          onChange={formik.handleChange}
          error={
            !!formik.touched.reporting_frequency &&
            !!formik.errors.reporting_frequency
          }
          helperText={
            formik.touched.reporting_frequency &&
            formik.errors.reporting_frequency
          }
        />
        <TextField
          value={formik.values.location_frequency ?? ''}
          label="Location Frequency"
          name="location_frequency"
          type="number"
          size="small"
          fullWidth
          error={
            !!formik.touched.location_frequency &&
            !!formik.errors.location_frequency
          }
          helperText={
            formik.touched.location_frequency &&
            formik.errors.location_frequency
          }
          onChange={formik.handleChange}
        />
        <TextField
          value={formik.values.latitude ?? ''}
          label="Latitude"
          name="latitude"
          size="small"
          fullWidth
          onChange={formik.handleChange}
        />
        <TextField
          value={formik.values.longitude ?? ''}
          label="Longitude"
          name="longitude"
          size="small"
          fullWidth
          onChange={formik.handleChange}
        />
      </Box>

      <Box display="flex" justifyContent="end" gap={1.5}>
        {onCancel ? (
          mode === 'update_from_info' ? (
            <Button onClick={() => onCancel()} startIcon={<ArrowBackIcon />}>
              Back
            </Button>
          ) : (
            <Button onClick={() => onCancel()}>Cancel</Button>
          )
        ) : null}
        <Box>
          <LoadingButton
            variant="contained"
            color="warning"
            sx={{
              marginRight: '20px',
            }}
            onClick={() => {
              setReset(true);
            }}
          >
            Reset
          </LoadingButton>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={submittedInProgress}
          >
            Update
          </LoadingButton>
        </Box>
      </Box>
      <BeltNodeItemReset
        open={reset}
        pk={fetchedData?.id ?? -1}
        onSubmitted={() => {
          fetchData();
          setReset(false);
        }}
        onClose={() => setReset(false)}
      />
    </Box>
  );
};

export default BeltNodeItemUpdate;
