import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
} from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {useEffect, useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {SafeyeNode} from '../../interfaces/SafeyeNode';
import {OpenedSafeyeNodeMode} from '../../utils/hazardAIPanel';
import TruncatedText from '../common/TruncantedText';
import SafeyeNodeItemAck from './SafeyeNodeItemAck';
import {SafeyeNodeItemAssign} from './SafeyeNodeItemAssign';
import SafeyeNodeItemUpdate from './SafeyeNodeItemUpdate';
import SafeyeNodeItemView from './SafeyeNodeItemView';

interface Props {
  pk: number;
  item?: SafeyeNode;
  mode?: OpenedSafeyeNodeMode;
  prefetch?: boolean;
  onClose?: () => void;
  onSubmitted?: () => void;
}

const SafeyeNodeItem = ({
  pk,
  item,
  mode: initialMode = 'view',
  prefetch,
  onClose,
  onSubmitted,
}: Props) => {
  const [mode, setMode] = useState(initialMode);

  // Fetch
  const [fetchedData, setFetchedData] = useState<SafeyeNode | undefined>(
    cloneDeep(item)
  );
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);
    setFetchedErrors([]);
    try {
      const resp = await API.get<SafeyeNode>(
        `${apiBaseUrl}/safeye-nano-node/${pk}`
      );
      setFetchedData(resp.data);
      if (!resp.data.acknowledge) {
        setMode('ack');
      }
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (item && !isEqual(item, fetchedData)) {
      setFetchedData(item);
    }
  }, [item]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      p={3}
    >
      <Box display="flex" justifyContent="space-between">
        <Box
          className="dragHandle"
          display="flex"
          alignItems="center"
          width="100%"
          gap={1.5}
          overflow="hidden"
          sx={{cursor: 'grab'}}
        >
          <ShareLocationIcon sx={{fontSize: 40}} />
          {fetchedData ? (
            <TruncatedText fontSize={24}>{fetchedData.name}</TruncatedText>
          ) : null}
        </Box>
        <Box display="flex" gap={0.5}>
          <IconButton onClick={() => fetchData()}>
            <RefreshIcon />
          </IconButton>
          {onClose ? (
            <IconButton onClick={() => onClose()}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </Box>
      </Box>
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}
        </Alert>
      ))}
      {mode === 'view' ? (
        <SafeyeNodeItemView
          pk={pk}
          item={fetchedData}
          prefetch={!prefetch}
          onCancel={onClose}
          onFetched={setFetchedData}
          onSubmitted={() => {
            onSubmitted?.();
            setMode('view');
            fetchData();
          }}
          onEdit={() => setMode('edit')}
        />
      ) : mode === 'edit' ? (
        <SafeyeNodeItemUpdate
          pk={pk}
          item={fetchedData}
          prefetch={!prefetch}
          onCancel={() => setMode('view')}
          onSubmitted={() => {
            onSubmitted?.();
            setMode('view');
            fetchData();
          }}
        />
      ) : mode === 'ack' ? (
        <SafeyeNodeItemAck
          pk={pk}
          item={fetchedData}
          prefetch={!prefetch}
          onCancel={onClose}
          onSubmitted={() => {
            onSubmitted?.();
            fetchData();
            onClose?.();
          }}
        />
      ) : mode === 'assign' ? (
        <SafeyeNodeItemAssign
          pk={pk}
          item={fetchedData}
          onCancel={onClose}
          onSubmitted={() => {
            onSubmitted?.();
            onClose?.();
            fetchData();
          }}
        />
      ) : null}
    </Box>
  );
};

export default SafeyeNodeItem;
