import {LoadingButton} from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  ListItem,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {ReactNode, useState} from 'react';

import API from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useAppDispatch} from '../../../hooks/redux';
import reduxActions from '../../../redux/actions';
import {CloseSnackbarAction} from '../../common/CloseSnackbarButton';

interface GroupItemDeleteButtonProps {
  itemId?: number;
  component?: React.FC;
  componentProps?: any;
  onDeleted?: Function;
  children?: ReactNode;
}

export const GroupItemDeleteButton: React.FC<GroupItemDeleteButtonProps> = ({
  itemId,
  component = ListItem,
  componentProps = {},
  onDeleted,
  children,
}) => {
  const {enqueueSnackbar} = useSnackbar();
  const Component = component;

  const [isOpened, setIsOpened] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const openModal = () => setIsOpened(true);
  const closeModal = () => setIsOpened(false);

  const dispatch = useAppDispatch();

  const submit = async () => {
    setIsSubmitting(true);

    try {
      await API.delete(`${apiBaseUrl}/asset-human-group/${itemId}`);
      dispatch(reduxActions.assets.fetchAssetHumanGroups);
      const message = 'Group successfully deleted';
      onDeleted?.();
      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      closeModal();
    } catch (error: any) {
      const message = error?.response?.data?.message ?? 'There is an error';
      enqueueSnackbar(message, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }

    setIsSubmitting(false);
  };

  return (
    <>
      <Component {...componentProps} onClick={openModal}>
        {children}
      </Component>

      <Dialog open={isOpened} onClose={closeModal}>
        <DialogTitle>Delete Group</DialogTitle>

        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this group?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={closeModal}>Cancel</Button>

          <LoadingButton
            variant="contained"
            loading={isSubmitting}
            autoFocus
            onClick={() => submit()}
            color="error"
          >
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
