/* eslint-disable complexity */

import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
} from '@mui/material';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import Image from 'mui-image';
import {useEffect, useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import SensorIcon from '../../../images/ams/sensors/icon_sensor.svg';
import {GasMonitoringNode} from '../../../interfaces/GasMonitoringNode';
import {MapLatLangCoordinates} from '../../common/HazardMap';
import TruncatedText from '../../common/TruncantedText';
import AMSSensorNodeItemNetworkdIdUpdate from './AMSSensorNodeItemNetworkdIdUpdate';
import AMSSensorNodeItemUpdate from './AMSSensorNodeItemUpdate';
import AMSSensorNodeItemView from './AMSSensorNodeItemView';

interface Props {
  pk: number;
  item?: GasMonitoringNode;
  mode?:
    | 'view'
    | 'update'
    | 'ack'
    | 'active'
    | 'update_from_info'
    | 'update_networkd_id'
    | 'update_networkd_id_from_info'
    | 'disable_events';
  prefetch?: boolean;
  locationCoordinates?: MapLatLangCoordinates;
  isActiveModal?: boolean;
  onClose?: () => void;
  onSubmitted?: () => void;
  onPurged?: () => void;
  onOpenHistory?: (
    id: number,
    type: 'amsShortTerm' | 'amsLongTerm' | 'amsLocation'
  ) => void;
}

const AMSSensorNodeItem = ({
  pk,
  item,
  mode: initialMode = 'view',
  prefetch,
  locationCoordinates,
  isActiveModal,
  onClose,
  onSubmitted,
  onPurged,
  onOpenHistory,
}: Props) => {
  const [mode, setMode] = useState(initialMode);

  /*********/
  /* fetch */
  /*********/

  const [fetchedData, setFetchedData] = useState<GasMonitoringNode | undefined>(
    cloneDeep(item)
  );
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);
    setFetchedErrors([]);
    try {
      const resp = await API.get<GasMonitoringNode>(`${apiBaseUrl}/ams/${pk}`);
      setFetchedData(resp.data);
      if (resp.data.ack !== '1') {
        setMode('ack');
      }
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (item && !isEqual(item, fetchedData)) {
      setFetchedData(item);
    }
  }, [item]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      p={3}
    >
      <Box display="flex" justifyContent="space-between">
        <Box
          className="dragHandle"
          display="flex"
          alignItems="center"
          width="100%"
          gap={1.5}
          overflow="hidden"
          sx={{cursor: 'grab'}}
        >
          <Image src={SensorIcon} alt="heat map" width={26} height={18} />

          {fetchedData ? (
            <TruncatedText fontSize={24}>{fetchedData.name}</TruncatedText>
          ) : null}
        </Box>
        <Box display="flex" gap={0.5}>
          <IconButton onClick={() => fetchData()}>
            <RefreshIcon />
          </IconButton>
          {onClose ? (
            <IconButton onClick={() => onClose()}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </Box>
      </Box>
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}
      {mode === 'view' ? (
        <AMSSensorNodeItemView
          pk={pk}
          item={fetchedData}
          prefetch={!prefetch}
          onCancel={onClose}
          onEdit={() => setMode('update_from_info')}
          onPurged={onPurged}
          onAck={() => setMode('ack')}
          onFetched={setFetchedData}
          onSubmitted={onSubmitted}
          //onEditNetwork={() => setMode('update_networkd_id_from_info')}
          onOpenHistory={onOpenHistory}
        />
      ) : mode === 'update' || mode === 'update_from_info' ? (
        <AMSSensorNodeItemUpdate
          mode={mode}
          pk={pk}
          item={fetchedData}
          prefetch={!prefetch}
          locationCoordinates={locationCoordinates}
          isActiveModal={isActiveModal}
          onCancel={() => {
            mode === 'update' ? onClose?.() : setMode('view');
          }}
          onSubmitted={() => {
            onSubmitted?.();
            mode === 'update' ? onClose?.() : setMode('view');
            fetchData();
          }}
        />
      ) : mode === 'ack' ? (
        <>ACK</>
      ) : mode === 'update_networkd_id' ||
        mode === 'update_networkd_id_from_info' ? (
        <AMSSensorNodeItemNetworkdIdUpdate
          mode={mode}
          pk={pk}
          item={fetchedData}
          prefetch={!prefetch}
          onCancel={() => {
            mode === 'update_networkd_id' ? onClose?.() : setMode('view');
          }}
          onSubmitted={() => {
            onSubmitted?.();
            mode === 'update_networkd_id' ? onClose?.() : setMode('view');
            fetchData();
          }}
        />
      ) : null}
    </Box>
  );
};

export default AMSSensorNodeItem;
