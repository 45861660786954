import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  TextField,
} from '@mui/material';
import {useFormik} from 'formik';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {useSnackbar} from 'notistack';
import {useEffect, useState} from 'react';
import * as yup from 'yup';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppDispatch} from '../../hooks/redux';
import {Zone, ZoneUpsertInputBody} from '../../interfaces/Zone';
import reduxActions from '../../redux/actions';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';
import LocationSelect from '../selectors/LocationSelect';
import StatusSelect from '../selectors/StatusSelect';

interface Props {
  pk?: number;
  item?: Zone;
  prefetch?: boolean;
  onClose?: () => void;
  onSubmitted?: (item: Zone) => void;
}

const ZoneItemUpsert = ({pk, item, prefetch, onClose, onSubmitted}: Props) => {
  /*********/
  /* fetch */
  /*********/

  const [fetchedData, setFetchedData] = useState<Zone | undefined>(
    cloneDeep(item)
  );
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);
    try {
      const resp = await API.get<Zone>(`${apiBaseUrl}/zone/${pk}`);
      setFetchedData(resp.data);
      formik.setValues(getFormikValues(resp.data));
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (!isEqual(item, fetchedData)) {
      setFetchedData(item);
      formik.setValues(getFormikValues(item));
    }
  }, [item]);

  /**********/
  /* submit */
  /**********/

  const {enqueueSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);
  const reduxDispatch = useAppDispatch();

  const submitData = async (data: ZoneUpsertInputBody) => {
    setSubmittedInProgress(true);
    try {
      const endpoint = pk ? `${apiBaseUrl}/zone/${pk}` : `${apiBaseUrl}/zone`;
      const resp = pk
        ? await API.patch<Zone>(endpoint, data)
        : await API.post<Zone>(endpoint, data);
      const message = `Section successfully ${pk ? 'updated' : 'created'}`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      reduxDispatch(reduxActions.assets.fetchZones);
      onSubmitted?.(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/
  const inputValidationSchema = yup.object().shape({
    name: yup.string().nullable().required('Field is required'),
    status: yup.string().nullable().required('Field is required'),
    description: yup.string().nullable(),
    location: yup.string().nullable().required('Field is required'),
  });

  const getFormikValues = (item?: Zone): ZoneUpsertInputBody => ({
    name: item?.name ?? null,
    status: item?.status ?? null,
    description: item?.description ?? null,
    location: item?.location ?? null,
  });

  const formik = useFormik<ZoneUpsertInputBody>({
    initialValues: getFormikValues(fetchedData),
    validationSchema: inputValidationSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  useEffect(() => {
    const newInput = getFormikValues(fetchedData);
    if (!isEqual(formik.values, newInput)) {
      formik.setValues(newInput);
    }
  }, [fetchedData]);

  const ActionIcon = pk ? EditIcon : AddIcon;
  const actionName = pk ? 'Update' : 'Create';

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      p={3}
      onSubmit={formik.handleSubmit}
    >
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" alignItems="center" width="100%" gap={1.5}>
          <ActionIcon sx={{color: 'primary.main'}} />
          <Box fontSize={24}>{actionName} Section</Box>
        </Box>
        {onClose ? (
          <IconButton onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </Box>
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}
      <Box display="flex" flexDirection="column" gap={3}>
        <TextField
          value={formik.values.name ?? ''}
          label="Name"
          size="small"
          name="name"
          fullWidth
          error={!!formik.touched.name && !!formik.errors.name}
          helperText={formik.touched.name && formik.errors.name}
          onChange={formik.handleChange}
        />
        <StatusSelect
          value={formik.values.status}
          fullWidth
          name="status"
          label="Status"
          size="small"
          select
          error={!!formik.touched.status && !!formik.errors.status}
          helperText={formik.touched.status && formik.errors.status}
          onChange={formik.handleChange}
        />
        <LocationSelect
          value={formik.values.location}
          fullWidth
          name="location"
          label="Location"
          size="small"
          select
          error={!!formik.touched.location && !!formik.errors.location}
          helperText={formik.touched.location && formik.errors.location}
          onChange={formik.handleChange}
        />
        <TextField
          value={formik.values.description ?? ''}
          label="Description"
          size="small"
          name="description"
          fullWidth
          multiline
          rows={5}
          error={!!formik.touched.description && !!formik.errors.description}
          helperText={formik.touched.description && formik.errors.description}
          onChange={formik.handleChange}
        />
      </Box>
      <Box display="flex" justifyContent="end" gap={0.5}>
        {onClose ? <Button onClick={() => onClose()}>Cancel</Button> : null}
        <Box>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={submittedInProgress}
            sx={{ml: 1}}
          >
            {actionName}
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default ZoneItemUpsert;
