import {LoadingButton} from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {CommunicationNode} from '../../interfaces/CommunicationNodeV2';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

interface Props extends Omit<DialogProps, 'onClose'> {
  item: CommunicationNode;
  onSubmitted?: () => void;
  onClose?: () => void;
}

const CommunicationNodeItemMakeSecondaryModal = ({
  item,
  onSubmitted,
  onClose,
  ...props
}: Props) => {
  const {enqueueSnackbar} = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submit = async (pk: number) => {
    setIsSubmitting(true);
    try {
      await API.patch(
        `${apiBaseUrl}/node/${pk}/communication-node/make_secondary`
      );
      const message = `Secondary configuration message has been sent to communication node`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      onSubmitted?.();
      onClose?.();
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setIsSubmitting(false);
  };

  return (
    <Dialog {...props} onClose={onClose}>
      <DialogTitle>Made Secondary</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Are you sure you want to make this communication node secondary?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose?.()}>Cancel</Button>
        <LoadingButton
          variant="contained"
          loading={isSubmitting}
          autoFocus
          onClick={() => submit(item.id)}
          color="warning"
        >
          Make Secondary
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CommunicationNodeItemMakeSecondaryModal;
