import {LoadingButton} from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {CommtracNode} from '../../interfaces/CommtracNode';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  pk: number;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  onSubmitted?: (item: CommtracNode) => void;
}

const CommtracNodeItemMinerMoveToAssetButton = <T extends ComponentType>({
  pk,
  component,
  componentProps,
  children,
  onSubmitted,
}: Props<T>) => {
  const Component = component ?? Button;
  const [isOpened, setIsOpened] = useState(false);

  const {enqueueSnackbar} = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submit = async (pk: number | null) => {
    setIsSubmitting(true);
    try {
      const endpoint = `${apiBaseUrl}/commtrac-node/${pk}/miner/move_to_asset`;
      const resp = await API.patch<CommtracNode>(endpoint);
      const message = `Employee moved to asset successfully`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      onSubmitted?.(resp.data);
      setIsOpened(false);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <Component {...componentProps} onClick={() => setIsOpened(true)}>
        {children}
      </Component>

      {isOpened ? (
        <Dialog open={isOpened} onClose={() => setIsOpened(false)}>
          <DialogTitle>Move to Asset</DialogTitle>

          <DialogContent>
            <DialogContentText>
              Are you sure you want to move to assets selected device?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsOpened(false)}>Cancel</Button>
            <LoadingButton
              variant="contained"
              loading={isSubmitting}
              autoFocus
              onClick={() => submit(pk)}
              color="warning"
            >
              Move to Asset
            </LoadingButton>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};

export default CommtracNodeItemMinerMoveToAssetButton;
