import CloseIcon from '@mui/icons-material/Close';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import {LoadingButton} from '@mui/lab';
import {Box, Button, IconButton, TextField} from '@mui/material';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {useMemo, useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppSelector} from '../../hooks/redux';
import {SafeyeNode} from '../../interfaces/SafeyeNode';
import reduxSelectors from '../../redux/selectors';
import {SafeyeNodeAssignInputSchema} from '../../scheme/yup/safeye-node';
import AssetMachineSelect from '../asset-machine/AssetMachineSelect';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

interface Props {
  pk: number;
  item?: SafeyeNode;
  onCancel?: Function;
  onSubmitted?: (item: SafeyeNode) => void;
}

export const SafeyeNodeItemAssign = ({
  pk,
  item,
  onCancel,
  onSubmitted,
}: Props) => {
  // Constants
  const assets = useAppSelector(reduxSelectors.assets.getAssets);
  const {asset_machine: assetMachine} = item ?? {};
  const {name: assetMachineName} = assetMachine ?? {};
  const company = useAppSelector(({assets}) => assets.company);
  const hazard_ai_enabled = !!company?.hazard_ai_enabled;

  const assetMachines = useMemo(
    () =>
      !hazard_ai_enabled
        ? assets.asset_machines.filter((i) => i.external_id)
        : assets.asset_machines,
    [assets.asset_machines, hazard_ai_enabled]
  );

  // Submit
  const {enqueueSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async (data: {asset_machine_id: number | null}) => {
    setSubmittedInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/safeye-nano-node/${pk}/assign`;
      const resp = await API.patch<SafeyeNode>(endpoint, {
        ...data,
      });
      const message = `Camera was successfully assigned to the asset`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      onSubmitted?.(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setSubmittedInProgress(false);
  };

  const getFormikValues = (): {
    asset_machine_id: number | null;
  } => ({
    asset_machine_id: null,
  });

  const formik = useFormik<{
    asset_machine_id: number | null;
  }>({
    initialValues: getFormikValues(),
    validationSchema: SafeyeNodeAssignInputSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  return (
    <>
      <Box
        component="form"
        display="flex"
        flexDirection="column"
        position="relative"
        gap={3}
        p={3}
        onSubmit={formik.handleSubmit}
      >
        <Box display="flex" justifyContent="space-between">
          <Box
            className="dragHandle"
            display="flex"
            alignItems="center"
            width="100%"
            gap={1.5}
            sx={{cursor: 'grab'}}
          >
            <LocalShippingIcon sx={{color: 'primary.main'}} />
            <Box
              fontSize={24}
            >{`${assetMachineName ? 'Reassign' : 'Assign'} camera to Machine`}</Box>
          </Box>
          {onCancel ? (
            <IconButton onClick={() => onCancel()}>
              <CloseIcon />
            </IconButton>
          ) : null}
        </Box>
        <Box display="flex" flexDirection="column" gap={3}>
          <TextField
            value={assetMachineName || ''}
            label="Currently Camera is assigned to the Machine:"
            size="small"
            disabled
            fullWidth
          />

          <AssetMachineSelect
            value={formik.values.asset_machine_id}
            size="small"
            label="Machine"
            fullWidth
            nullLabel=""
            assetMachines={assetMachines}
            error={
              !!formik.touched.asset_machine_id &&
              !!formik.errors.asset_machine_id
            }
            helperText={
              formik.touched.asset_machine_id && formik.errors.asset_machine_id
            }
            onChange={(_, item) => {
              formik.setFieldValue('asset_machine_id', item?.id ?? null);
            }}
          />
        </Box>

        <Box display="flex" justifyContent="end" gap={1}>
          {onCancel ? <Button onClick={() => onCancel()}>Cancel</Button> : null}
          <Box>
            <LoadingButton
              variant="contained"
              type="submit"
              loading={submittedInProgress}
            >
              Assign
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};
