import {LoadingButton} from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

type commtracNodeObj = {
  id: number;
  checked_in: number | null | undefined;
};

interface Props extends Omit<DialogProps, 'onClose'> {
  ids: commtracNodeObj[];
  onSubmitted?: () => void;
  onClose?: () => void;
}

const CommtracNodeItemMinerCheckInOutModal = ({
  ids,
  onSubmitted,
  onClose,
  ...props
}: Props) => {
  const {enqueueSnackbar} = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const actionName = `${ids.length === 1 ? (ids[0].checked_in ? 'Checked Out' : 'Checked In') : 'Checked'}`;
  const submit = async () => {
    setIsSubmitting(true);
    try {
      const checkInIds = {
        id: ids.filter((it) => !it.checked_in).map((it) => it.id),
      };
      const checkOutIds = {
        id: ids.filter((it) => it.checked_in).map((it) => it.id),
      };
      const endpointCheckIn = `${apiBaseUrl}/commtrac-node/force_check_in`;
      if (checkInIds.id.length > 0) {
        await API.patch(endpointCheckIn, checkInIds);
      }
      const endpointCheckOut = `${apiBaseUrl}/commtrac-node/force_check_out`;
      if (checkOutIds.id.length > 0) {
        await API.patch(endpointCheckOut, checkOutIds);
      }

      let message = null;
      if (ids.length === 1) {
        message = `Employee successfully Force${ids[0].checked_in ? 'Checked Out' : 'Checked In'}`;
      } else {
        message = `Employee successfully Force Checked In/Out`;
      }
      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      onSubmitted?.();
      onClose?.();
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setIsSubmitting(false);
  };

  return (
    <Dialog {...props} onClose={onClose}>
      <DialogTitle>
        {actionName}
        {ids.length === 1 ? ' Employee' : ' Employees'}
      </DialogTitle>

      <DialogContent>
        <DialogContentText>
          Are you sure you want to {actionName}{' '}
          {ids.length === 1 ? 'this' : 'these'}{' '}
          {ids.length === 1 ? ' employee' : ' employees'}?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose?.()}>Cancel</Button>
        <LoadingButton
          variant="contained"
          loading={isSubmitting}
          autoFocus
          onClick={() => submit()}
          color="warning"
        >
          {actionName}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CommtracNodeItemMinerCheckInOutModal;
