import {LoadingButton} from '@mui/lab';
import {Alert, Backdrop, Box, Button, CircularProgress} from '@mui/material';
import {useFormik} from 'formik';
import _ from 'lodash';
import {useSnackbar} from 'notistack';
import {useEffect, useMemo, useState} from 'react';
import * as yup from 'yup';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppDispatch} from '../../hooks/redux';
import {Map, MapInputBody, MapItemResponse} from '../../interfaces/Map';
import reduxActions from '../../redux/actions';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

interface MapItemUpsertProps {
  pk?: Map['id'];
  item?: Map;
  prefetch?: boolean;
  onCancel?: Function;
  onSubmitted?: () => void;
}

export const MapItemUpsert: React.FC<MapItemUpsertProps> = ({
  pk,
  item,
  prefetch,
  onCancel,
  onSubmitted,
}) => {
  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] = useState<MapItemResponse | undefined>(
    _.cloneDeep(item ? item : undefined)
  );
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const resp = await API.get<MapItemResponse>(`${apiBaseUrl}/map/${pk}`);
      setFetchedData(resp.data);
    } catch (error: any) {
      const message = error?.response?.data?.message ?? 'There is an error';
      setFetchedErrors([message]);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }

    return () => {
      setFetchedData(undefined);
      setFetchedErrors([]);
    };
  }, [pk, prefetch]);

  useEffect(() => {
    const newFetchedData = item;
    if (!_.isEqual(newFetchedData, fetchedData)) {
      setFetchedData(newFetchedData);
    }
  }, [item]);

  /**********/
  /* submit */
  /**********/
  const {enqueueSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);
  const reduxDispatch = useAppDispatch();

  const submitData = async (data: MapInputBody) => {
    setSubmittedInProgress(true);

    try {
      const endpoint = pk ? `${apiBaseUrl}/map/${pk}` : `${apiBaseUrl}/map`;
      await API.post(endpoint, data);
      const message = `Map has been ${pk ? 'updated' : 'created'}`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      reduxDispatch(reduxActions.assets.fetchMaps);
      onSubmitted?.();
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }

    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/
  const inputValidationSchema = useMemo(() => {
    const fields: any = {
      name: yup.string().nullable().required('Field is required'),
      status: yup.string().nullable().required('Field is required'),
    };

    return yup.object().shape(fields);
  }, [pk]);

  const getFormikValues = (): MapInputBody => ({});

  const formik = useFormik<MapInputBody>({
    initialValues: getFormikValues(),
    validationSchema: inputValidationSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  useEffect(() => {
    const newFormikValues = getFormikValues();
    if (!_.isEqual(newFormikValues, formik.values)) {
      formik.setValues(newFormikValues);
    }
  }, [fetchedData]);

  return (
    <Box component="form" position="relative" onSubmit={formik.handleSubmit}>
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error" sx={{my: 2}}>
          {error}
        </Alert>
      ))}

      <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button onClick={() => onCancel?.()}>Cancel</Button>

        <LoadingButton
          sx={{ml: 1}}
          variant="contained"
          loading={submittedInProgress}
          type="submit"
        >
          Submit
        </LoadingButton>
      </Box>
    </Box>
  );
};
