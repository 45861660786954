export const STATUSES = [
  {
    key: 'active',
    name: 'Active',
  },
  {
    key: 'inactive',
    name: 'Inactive',
  },
] as const;

export const COMMUNICATION_NODE_STATUSES = [
  {key: 'all', name: 'All'},
  {key: 'active', name: 'Active'},
  {key: 'inactive', name: 'Inactive'},
  {key: 'activated', name: 'Activated'},
  {key: 'deactivated', name: 'Deactivated'},
  {key: 'connected', name: 'Connected'},
  {key: 'disconnected', name: 'Disconnected'},
  {key: 'unacknowledged', name: 'Unacknowledged'},
] as const;

export const ASSET_STATUSES = [
  {key: 'all', name: 'All'},
  {key: 'checked_in', name: 'Checked In'},
  {key: 'checked_out', name: 'Checked Out'},
  {key: 'active', name: 'Active'},
  {key: 'inactive', name: 'Inactive'},
  {key: 'activated', name: 'Activated'},
  {key: 'deactivated', name: 'Deactivated'},
  {key: 'unacknowledged', name: 'Unacknowledged'},
  {key: 'surface', name: 'Surface'},
  {key: 'underground', name: 'Underground'},
] as const;

export type Status = (typeof STATUSES)[number]['key'];
export type CommunicationNodeStatus =
  (typeof COMMUNICATION_NODE_STATUSES)[number]['key'];
export type AssetStatus = (typeof ASSET_STATUSES)[number]['key'];
