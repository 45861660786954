import {LoadingButton} from '@mui/lab';
import {Box, Button, TextField} from '@mui/material';
import {useFormik} from 'formik';
import {useSnackbar} from 'notistack';
import {useState} from 'react';
import * as yup from 'yup';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {
  CommunicationNode,
  CommunicationNodeReportFrequencyInputBody,
} from '../../interfaces/CommunicationNodeV2';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

interface Props {
  onCancel?: () => void;
  onSubmitted?: (item: CommunicationNode) => void;
}

const CommunicationNodeBroadcastReportFrequency = ({
  onCancel,
  onSubmitted,
}: Props) => {
  /**********/
  /* submit */
  /**********/

  const {enqueueSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async (
    data: CommunicationNodeReportFrequencyInputBody
  ) => {
    setSubmittedInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/node/communication-node/broadcast_report_frequency`;
      const resp = await API.post<CommunicationNode>(endpoint, data);
      const message = `Broadcast report frequency configuration message has been successfully sent`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      onSubmitted?.(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/
  const inputValidationSchema = yup.object().shape({
    frequency: yup.number().nullable().required('Field is required'),
  });

  const getFormikValues = (): CommunicationNodeReportFrequencyInputBody => ({
    frequency: null,
  });

  const formik = useFormik<CommunicationNodeReportFrequencyInputBody>({
    initialValues: getFormikValues(),
    validationSchema: inputValidationSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });
  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      onSubmit={formik.handleSubmit}
    >
      <Box display="flex" flexDirection="column" gap={3}>
        <TextField
          value={formik.values.frequency ?? ''}
          label="Report Frequency (s)"
          size="small"
          name="frequency"
          type="number"
          fullWidth
          error={!!formik.touched.frequency && !!formik.errors.frequency}
          helperText={formik.touched.frequency && formik.errors.frequency}
          inputProps={{min: 0, step: 1}}
          onChange={(e) =>
            formik.setFieldValue(
              e.target.name,
              e.target.value ? Math.abs(+e.target.value) : null
            )
          }
        />
      </Box>

      <Box display="flex" justifyContent="end" gap={1}>
        {onCancel ? <Button onClick={() => onCancel()}>Cancel</Button> : null}
        <Box>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={submittedInProgress}
          >
            Broadcast
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default CommunicationNodeBroadcastReportFrequency;
