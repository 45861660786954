/* eslint-disable no-bitwise */
export const signalBytesToDbm = (rssiByteValue: number | null) => {
  if (rssiByteValue === null) {
    return 0;
  }

  const sign = rssiByteValue & (1 << 7);
  if (sign) {
    return 0xffffff00 | rssiByteValue; // fill in most significant bits with 1's
  } else {
    return rssiByteValue;
  }
};
