import {Box} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {ExportField} from '../../../interfaces/Export';
import {saveFile} from '../../../utils/file';
import {CloseSnackbarAction} from '../../common/CloseSnackbarButton';
import ExportFieldForm from '../../common/ExportFieldForm';
import ModalFixed from '../../common/ModalFixed';
import SnackbarMessages from '../../common/SnackbarMessages';
import {AmsEmoduleInstallationHistoryTabConfig} from '.';

interface Props {
  opened: boolean;
  value?: AmsEmoduleInstallationHistoryTabConfig;
  onClose: VoidFunction;
  onSubmit: (fields: ExportField[]) => void;
}

export const AmsEmoduleInstallationHistoryExportModal = (props: Props) => {
  const {enqueueSnackbar} = useSnackbar();

  const [isFetching, setIsFetching] = useState(false);

  const fetchData = async (fields: string[]) => {
    setIsFetching(true);
    try {
      const params = {
        ...props.value?.params,
        fields,
        export: 'excel',
      };
      const resp = await API.get(
        `${apiBaseUrl}/ams/emodule/${props.value?.id}/installation-history`,
        {params, responseType: 'blob'}
      );
      const filename =
        resp?.headers['content-disposition']?.split('filename=')?.[1];

      saveFile(resp.data, filename);
      handleSubmit(fields);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setIsFetching(false);
  };

  const handleSubmit = (fields: string[]) => {
    const exportFields = (props.value?.exportFields ?? []).map((f) => ({
      ...f,
      hidden: !fields.includes(f.field),
    }));
    props.onSubmit(exportFields);
  };

  return (
    <ModalFixed open={props.opened} onClose={props.onClose}>
      <Box
        display="flex"
        flexDirection="column"
        position="relative"
        gap={3}
        p={3}
      >
        <ExportFieldForm
          height={150}
          exportFields={props.value?.exportFields}
          loading={isFetching}
          onChange={fetchData}
          close={props.onClose}
        />
      </Box>
    </ModalFixed>
  );
};
