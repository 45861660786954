import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import EditIcon from '@mui/icons-material/Edit';
import {LoadingButton} from '@mui/lab';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  LinearProgress,
  TextField,
  Tooltip,
} from '@mui/material';
import {useFormik} from 'formik';
import _, {kebabCase} from 'lodash';
import {useSnackbar} from 'notistack';
import {useEffect, useMemo, useState} from 'react';
import * as yup from 'yup';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {useAppDispatch} from '../../hooks/redux';
import {MapUploadQuery} from '../../interfaces/Map';
import reduxActions from '../../redux/actions';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

const CHUNK_SIZE = 1048576 * 3; // 3MB

type MapUploadFormikValue = Omit<
  MapUploadQuery,
  'fileName' | 'chunkIndex' | 'totalChunkCount'
> & {
  file: File | null;
};

interface MapItemUploadProps {
  onCancel?: Function;
  onSubmitted?: () => void;
}

export const MapItemUpload: React.FC<MapItemUploadProps> = ({
  onCancel,
  onSubmitted,
}) => {
  /*********/
  /* input */
  /*********/
  const [shouldGeneratePath, setShouldGeneratePath] = useState(true);
  const inputValidationSchema = useMemo(() => {
    const fields: any = {
      file: yup.mixed().nullable().required('Field is reqired'),
      name: yup.string().nullable().required('Field is required'),
      path: yup.string().nullable().required('Field is required'),
    };

    return yup.object().shape(fields);
  }, []);

  const getFormikValues = (): MapUploadFormikValue => ({
    file: null,
    name: null,
    path: null,
  });

  const formik = useFormik<MapUploadFormikValue>({
    initialValues: getFormikValues(),
    validationSchema: inputValidationSchema,
    onSubmit: async () => {
      await startUpload();
    },
  });

  useEffect(() => {
    const newFormikValues = getFormikValues();
    if (!_.isEqual(newFormikValues, formik.values)) {
      formik.setValues(newFormikValues);
    }
  }, []);

  /**********/
  /* upload */
  /**********/
  const {enqueueSnackbar} = useSnackbar();
  const [fileName, setFileName] = useState<string | null>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [chunkIndex, setChunkIndex] = useState<number>(0);

  const totalChunkCount = useMemo(
    () =>
      formik.values.file ? Math.ceil(formik.values.file.size / CHUNK_SIZE) : 0,
    [formik.values.file]
  );
  const progress = useMemo(
    () =>
      formik.values.file
        ? Math.round(
            ((chunkIndex * CHUNK_SIZE) / formik.values.file?.size) * 100
          )
        : 0,
    [formik.values.file?.size, chunkIndex]
  );
  const currentChunk = useMemo(
    () =>
      isSubmitting
        ? formik.values.file?.slice(
            chunkIndex * CHUNK_SIZE,
            (chunkIndex + 1) * CHUNK_SIZE
          )
        : null,
    [formik.values.file, chunkIndex, isSubmitting]
  );

  const uploadChunk = async () => {
    const actualFileName = fileName;

    const form = new FormData();
    if (currentChunk) {
      form.append('map', currentChunk, 'map');
    }

    try {
      await API.post(`${apiBaseUrl}/map`, form, {
        params: {
          name: formik.values.name,
          path: formik.values.path,
          fileName: actualFileName,
          chunkIndex,
          totalChunkCount,
        },
      });
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
      finishUpload();
      return;
    }

    if (isSubmitting && actualFileName === fileName) {
      setChunkIndex(chunkIndex + 1);
    }
  };

  const startUpload = async () => {
    setFileName(Math.random().toString(36).slice(2));
    setChunkIndex(0);
    setIsSubmitting(true);
  };

  const finishUpload = () => {
    setIsSubmitting(false);
    setChunkIndex(0);
    formik.setFieldValue('file', null);
  };

  const reduxDispatch = useAppDispatch();

  useEffect(() => {
    if (currentChunk?.size) {
      uploadChunk();
    } else if (isSubmitting) {
      const message = `Map uploaded and is being deployed...`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      finishUpload();
      reduxDispatch(reduxActions.assets.fetchMaps);
      onSubmitted?.();
    }
  }, [currentChunk]);

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      gap={4}
      position="relative"
      onSubmit={formik.handleSubmit}
    >
      <TextField
        value={formik.values.name ?? ''}
        fullWidth
        name="name"
        label="Name"
        size="small"
        disabled={isSubmitting}
        error={!!formik.touched.name && !!formik.errors.name}
        helperText={formik.touched.name && formik.errors.name}
        onChange={(event) => {
          formik.setFieldValue('name', event.target.value || null);
          if (shouldGeneratePath) {
            formik.setFieldValue('path', kebabCase(event.target.value) || null);
          }
        }}
      />

      <TextField
        value={formik.values.path ?? ''}
        fullWidth
        name="path"
        label="Path"
        size="small"
        disabled={isSubmitting || shouldGeneratePath}
        error={!!formik.touched.path && !!formik.errors.path}
        helperText={formik.touched.path && formik.errors.path}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {shouldGeneratePath ? (
                <Tooltip title="Enter Manually">
                  <IconButton onClick={() => setShouldGeneratePath(false)}>
                    <EditIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Auto Generate">
                  <IconButton
                    onClick={() => {
                      setShouldGeneratePath(true);
                      formik.setFieldValue(
                        'path',
                        kebabCase(formik.values.name ?? '') || null
                      );
                    }}
                  >
                    <AutoFixHighIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </InputAdornment>
          ),
        }}
        onChange={(event) => {
          formik.setFieldValue('path', event.target.value || null);
        }}
      />

      <Box position="relative" border="1px solid" borderColor="grey.600">
        <Box
          p={2}
          textAlign="center"
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Box display="flex" alignItems="center" height="100px">
            <Box>
              <Box>
                <CloudUploadIcon fontSize="large" />
              </Box>

              <Box>
                {formik.values.file
                  ? formik.values.file.name
                  : 'Choose map to upload'}
              </Box>
            </Box>

            {!formik.values.file && (
              <TextField
                type="file"
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  opacity: 0,
                }}
                InputProps={{
                  inputProps: {sx: {height: '100%', cursor: 'pointer'}},
                  sx: {height: '100%'},
                }}
                onChange={(event: any) =>
                  formik.setFieldValue('file', event.target.files[0])
                }
              />
            )}
          </Box>

          {isSubmitting || progress >= 100 ? (
            <Box width="100%">
              <Box mb={1}>{progress}%</Box>

              <Box mb={3}>
                <LinearProgress variant="determinate" value={progress ?? 0} />
              </Box>

              <Box>
                {progress < 100 ? (
                  <Button
                    color="warning"
                    variant="contained"
                    onClick={() => finishUpload()}
                  >
                    Cancel
                  </Button>
                ) : (
                  <Button
                    color="success"
                    variant="contained"
                    onClick={() => finishUpload()}
                  >
                    Finish
                  </Button>
                )}
              </Box>
            </Box>
          ) : (
            <Box>
              {!!formik.values.file && (
                <Box textAlign="center">
                  <Box>
                    <Button onClick={() => formik.setFieldValue('file', null)}>
                      Clear
                    </Button>
                  </Box>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>

      <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button onClick={() => onCancel?.()}>Cancel</Button>

        <LoadingButton
          sx={{ml: 1}}
          variant="contained"
          loading={isSubmitting}
          type="submit"
        >
          Upload
        </LoadingButton>
      </Box>
    </Box>
  );
};
