import FileDownloadIcon from '@mui/icons-material/FileDownload';
import {Box} from '@mui/material';
import dayjs from 'dayjs';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {saveFile} from '../../../utils/file';
import {CloseSnackbarAction} from '../../common/CloseSnackbarButton';
import ExportFieldForm from '../../common/ExportFieldForm';
import ModalFixed from '../../common/ModalFixed';
import SnackbarMessages from '../../common/SnackbarMessages';
import {BeltHistoryReportDataTab} from './BeltHistoryReport';

interface Props {
  value?: BeltHistoryReportDataTab | null;
  commtrac_external_id?: number;
}

const BeltHistoryReportExportExcel = ({value, commtrac_external_id}: Props) => {
  const {enqueueSnackbar} = useSnackbar();
  const [isFetching, setIsFetching] = useState(false);

  const [isOpened, setIsOpened] = useState(false);

  const exportFieldOptions = [
    {field: 'commtrac_external_id', label: 'Network ID', hidden: false},
    {field: 'date', label: 'Timestamp', hidden: false},
    {field: 'run_status', label: 'Belt Status', hidden: false},
    {field: 'motor1', label: 'Motor 1 Current', hidden: false},
    {field: 'motor2', label: 'Motor 2 Current', hidden: false},
    {field: 'Power Fault', label: 'Power Fault', hidden: false},
    {
      field: 'Overload Motor 1 Fault',
      label: 'Overload Motor 2 Fault',
      hidden: false,
    },
    {
      field: 'Overload Motor 2 Fault',
      label: 'Overload Motor 2 Fault',
      hidden: false,
    },
    {field: 'Remote Switch Fault', label: 'Remote Switch Fault', hidden: false},
    {field: 'Fire Switch Fault', label: 'Fire Switch Fault', hidden: false},
    {field: 'Sequence Fault', label: 'Sequence Fault', hidden: false},
    {field: 'Dust Input Fault', label: 'Dust Input Fault', hidden: false},
    {field: 'Belt Slip Fault', label: 'Belt Slip Fault', hidden: false},
    {field: 'Spill Switch Fault', label: 'Spill Switch Fault', hidden: false},
    {field: 'Misalignment Fault', label: 'Misalignment Fault', hidden: false},
    {field: 'Take-Up Input Fault', label: 'Take-Up Input Fault', hidden: false},
    {
      field: 'Motor 1 High Amp Fault',
      label: 'Motor 1 High Amp Fault',
      hidden: false,
    },
    {
      field: 'Motor 2 High Amp Fault',
      label: 'Motor 2 High Amp Fault',
      hidden: false,
    },
    {
      field: 'Motor 1 Low Amp Fault',
      label: 'Motor 1 Low Amp Fault',
      hidden: false,
    },
    {
      field: 'Motor 2 Low Amp Fault',
      label: 'Motor 2 Low Amp Fault',
      hidden: false,
    },
    {field: 'Fail to Start Fault', label: 'Fail to Start Fault', hidden: false},
    {field: 'Belt Stop', label: 'Belt Stop', hidden: false},
    {field: 'Belt Start', label: 'Belt Start', hidden: false},
    {field: 'Fire Alarm Light', label: 'Fire Alarm Light', hidden: false},
    {field: 'Dust Alarm Light', label: 'Dust Alarm Light', hidden: false},
    {field: 'Audible Alarm', label: 'Audible Alarm', hidden: false},
    {field: 'High Amps Motor 1', label: 'High Amps Motor 1', hidden: false},
    {field: 'High Amps Motor 2', label: 'High Amps Motor 2', hidden: false},
    {field: 'Auto Mode', label: 'Auto Mode', hidden: false},
    {field: 'Horn Startup Active', label: 'Horn Startup Active', hidden: false},
    {field: 'Command Received', label: 'Command Received', hidden: false},
    {field: 'Misc Output 1 On', label: 'Misc Output 1 On', hidden: false},
    {field: 'Misc Output 2 On', label: 'Misc Output 2 On', hidden: false},
    {field: 'Misc Output 3 On', label: 'Misc Output 3 On', hidden: false},
    {field: 'Misc Output 4 On', label: 'Misc Output 4 On', hidden: false},
  ];

  const checkFields = () => {
    setIsOpened(true);
  };

  const fetchData = async (fields: string[]) => {
    setIsOpened(false);
    setIsFetching(true);

    try {
      const params = {
        export: 'excel',
        fields,
        date_start: value?.params?.date_start,
        date_end: value?.params?.date_end,
        order: value?.params?.order,
        dir: value?.params?.dir,
      };

      const resp = await API.get(
        `${apiBaseUrl}/belt/${commtrac_external_id}/history`,
        {
          params,
          responseType: 'blob',
        }
      );
      saveFile(
        resp.data,
        `belt_${commtrac_external_id}_history_${params.date_start ?? dayjs().format('YYYY-MM-DD')}_${params.date_end ?? dayjs().format('YYYY-MM-DD')}.xlsx`
      );
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setIsFetching(false);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" justifyContent="end">
        <FileDownloadIcon onClick={() => checkFields()}></FileDownloadIcon>
      </Box>
      <ModalFixed open={isOpened} onClose={() => setIsOpened(false)}>
        <Box
          display="flex"
          flexDirection="column"
          position="relative"
          gap={3}
          p={3}
        >
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center" width="100%" gap={1.5}>
              <ExportFieldForm
                exportFields={exportFieldOptions}
                onChange={fetchData}
                close={() => setIsOpened(false)}
                loading={isFetching}
                columns={3}
              ></ExportFieldForm>
            </Box>
          </Box>
        </Box>
      </ModalFixed>
    </Box>
  );
};

export default BeltHistoryReportExportExcel;
