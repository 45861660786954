import {LoadingButton} from '@mui/lab';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import {useSnackbar} from 'notistack';
import {useState} from 'react';

import API, {getMessagesFromApiError} from '../../api/axios';
import {apiBaseUrl} from '../../api/urls';
import {Company} from '../../interfaces/Company';
import {CloseSnackbarAction} from '../common/CloseSnackbarButton';
import SnackbarMessages from '../common/SnackbarMessages';

interface Props {
  item: Company;
  open: boolean;
  onSubmitted?: () => void;
  onClose?: () => void;
}

export const CompanyItemStatusDialog = ({
  item,
  open,
  onSubmitted,
  onClose,
}: Props) => {
  const {enqueueSnackbar} = useSnackbar();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isActive = item.status === 'active';

  const submit = async () => {
    setIsSubmitting(true);

    try {
      await API.patch(`${apiBaseUrl}/company/${item.id}`, {
        status: isActive ? 'inactive' : 'active',
      });
      const message = `Company successfully ${isActive ? 'deactivated' : 'activated'}`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });
      onSubmitted?.();
      onClose?.();
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }

    setIsSubmitting(false);
  };

  return (
    <Dialog open={open} onClose={() => onClose?.()}>
      <DialogTitle>{isActive ? 'Deactivate' : 'Activate'} Company</DialogTitle>

      <DialogContent>
        <DialogContentText>
          Are you sure you want to {isActive ? 'deactivate' : 'activate'}{' '}
          company {item.name}?
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button size="small" onClick={() => onClose?.()}>
          Cancel
        </Button>

        <LoadingButton
          variant="contained"
          loading={isSubmitting}
          autoFocus
          size="small"
          onClick={() => submit()}
          color="warning"
        >
          {isActive ? 'Deactivate' : 'Activate'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
