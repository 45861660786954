import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {LoadingButton} from '@mui/lab';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  TextField,
} from '@mui/material';
import dayjs from 'dayjs';
import {useFormik} from 'formik';
import _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import {useSnackbar} from 'notistack';
import {useEffect, useMemo, useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useAppSelector} from '../../../hooks/redux';
import {AMSEModuleUpdateInputBody} from '../../../interfaces/AMSEModule';
import {AMSEModuleNode} from '../../../interfaces/AMSEModuleNode';
import {SentroType} from '../../../interfaces/GasMonitoringNode';
import {amsEModuleEditSchema} from '../../../scheme/yup/ams-node';
import {isSentro8} from '../../../utils/ams';
import {CloseSnackbarAction} from '../../common/CloseSnackbarButton';
import NumberTextField from '../../common/NumberTextField';
import SnackbarMessages from '../../common/SnackbarMessages';
import {DateSelect} from '../../selectors/DateSelect';
import StatusSelect from '../../selectors/StatusSelect';

interface Props {
  pk: number;
  mode?: 'view' | 'update' | 'ack' | 'update_from_info';
  item?: AMSEModuleNode;
  prefetch?: boolean;
  onCancel?: Function;
  onSubmitted?: (item: AMSEModuleNode) => void;
}

const AMSEModuleNodeItemUpdate = ({
  pk,
  mode,
  item,
  prefetch,
  onCancel,
  onSubmitted,
}: Props) => {
  const ams_nodes = useAppSelector(({assets}) => assets.ams_nodes);
  const ams_emodules = useAppSelector(({assets}) => assets.ams_emodules);
  const sentro_1_nodes = useMemo(() => {
    return ams_nodes.filter((el) => el.sentro_type !== SentroType.SENTRO_8);
  }, [ams_nodes]);

  const ams_nodes_enable_selected = useMemo(
    () =>
      _.concat(
        sentro_1_nodes.filter(
          (it) =>
            ams_emodules.filter(
              (em) =>
                em.serial_number === it.sensor_serial_number &&
                em.commtrac_external_id === it.commtrac_external_id
            ).length === 0
        ),
        sentro_1_nodes.filter(
          (it) =>
            item?.serial_number === it.sensor_serial_number &&
            item?.commtrac_external_id === it.commtrac_external_id
        )
      ),
    [sentro_1_nodes, ams_emodules, item]
  );

  const can_assign_sensor = useMemo(() => {
    return !isSentro8(item?.commtrac_external_id);
  }, [item]);

  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] = useState<AMSEModuleNode | undefined>(
    cloneDeep(item)
  );
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);

    try {
      const resp = await API.get<AMSEModuleNode>(
        `${apiBaseUrl}/ams/emodule/${pk}`
      );
      setFetchedData(resp.data);

      formik.setValues(getFormikValues(resp.data));
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }

    setFetchedInProgress(false);
  };

  useEffect(() => {
    if (prefetch) {
      fetchData();
    }
  }, [pk, prefetch]);

  useEffect(() => {
    if (!isEqual(item, fetchedData)) {
      setFetchedData(item);
      formik.setValues(getFormikValues(item));
    }
  }, [item]);

  /**********/
  /* submit */
  /**********/

  const {enqueueSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async (data: AMSEModuleUpdateInputBody) => {
    setSubmittedInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/ams/emodule/${pk}`;
      const resp = await API.patch<AMSEModuleNode>(endpoint, {
        ...data,
        date_calibration: data?.date_calibration
          ? dayjs(data?.date_calibration).format('YYYY-MM-DD')
          : null,
        date_installation: data?.date_installation
          ? dayjs(data?.date_installation).format('YYYY-MM-DD')
          : null,
      });
      const message = `e-Module successfully updated`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: CloseSnackbarAction,
      });

      onSubmitted?.(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: CloseSnackbarAction,
      });
    }
    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/
  const getFormikValues = (
    item?: AMSEModuleNode
  ): AMSEModuleUpdateInputBody => ({
    commtrac_external_id: item?.commtrac_external_id ?? null,
    date_calibration: item?.date_calibration
      ? new Date(item?.date_calibration)
      : null,
    calibration_value: item?.calibration_value ?? null,
    date_installation: item?.date_installation
      ? new Date(item?.date_installation)
      : null,
    status: item?.status ?? null,
  });

  const formik = useFormik<AMSEModuleUpdateInputBody>({
    initialValues: getFormikValues(fetchedData),
    validationSchema: amsEModuleEditSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });

  useEffect(() => {
    const newInput = getFormikValues(fetchedData);
    if (!isEqual(formik.values, newInput)) {
      formik.setValues(newInput);
    }
  }, [fetchedData]);

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      position="relative"
      gap={3}
      onSubmit={formik.handleSubmit}
    >
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}
      {can_assign_sensor ? (
        <Grid item xs={12} lg={12} xl={12}>
          <TextField
            value={formik.values.commtrac_external_id}
            fullWidth
            label="Associated Sensor"
            select
            size="small"
            SelectProps={{
              multiple: false,
            }}
            onChange={(el) => {
              formik.setFieldValue('commtrac_external_id', el.target.value);
            }}
            error={
              !!formik.touched.commtrac_external_id &&
              !!formik.errors.commtrac_external_id
            }
            helperText={
              formik.touched.commtrac_external_id &&
              formik.errors.commtrac_external_id
            }
            InputLabelProps={{
              shrink: !!formik.values.commtrac_external_id, // Ensures label is always floated if there's a value
            }}
          >
            {ams_nodes_enable_selected
              ?.map((it) => ({
                id: it?.commtrac_external_id,
                name: it?.name,
              }))
              .map((option) => (
                <MenuItem
                  key={option.id ?? ''}
                  value={option.id ?? ''}
                  selected={option.id === item?.commtrac_external_id}
                >
                  {option.name}
                </MenuItem>
              ))}
          </TextField>
        </Grid>
      ) : null}
      <Grid spacing={3} container>
        <Grid item xs={12} lg={12} xl={12}>
          <DateSelect
            value={formik.values.date_calibration}
            renderInput={{
              label: 'Calibration Date',
              size: 'small',
              fullWidth: true,
              error:
                !!formik.touched.date_calibration &&
                !!formik.errors.date_calibration,
              helperText:
                formik.touched.date_calibration &&
                formik.errors.date_calibration,
            }}
            onChange={(value) =>
              formik.setFieldValue(
                'date_calibration',
                dayjs(value ?? undefined).format('YYYY-MM-DD')
              )
            }
          />
        </Grid>
        <Grid item xs={12} lg={12} xl={12}>
          <NumberTextField
            value={formik.values.calibration_value ?? null}
            label="Calibration Value"
            size="small"
            name="calibration_value"
            fullWidth
            error={
              !!formik.touched.calibration_value &&
              !!formik.errors.calibration_value
            }
            helperText={
              formik.touched.calibration_value &&
              formik.errors.calibration_value
            }
            onChange={(v) => formik.setFieldValue('calibration_value', v)}
          />
        </Grid>

        <Grid item xs={12} lg={12} xl={12}>
          <DateSelect
            value={formik.values.date_installation}
            renderInput={{
              label: 'Installation Date',
              size: 'small',
              fullWidth: true,
              error:
                !!formik.touched.date_installation &&
                !!formik.errors.date_installation,
              helperText:
                formik.touched.date_installation &&
                formik.errors.date_installation,
            }}
            onChange={(value) =>
              formik.setFieldValue(
                'date_installation',
                dayjs(value ?? undefined).format('YYYY-MM-DD')
              )
            }
          />
        </Grid>

        <Grid item xs={12} lg={12} xl={12}>
          <StatusSelect
            value={formik.values.status}
            fullWidth
            name="status"
            label="Status"
            size="small"
            select
            error={!!formik.touched.status && !!formik.errors.status}
            helperText={formik.touched.status && formik.errors.status}
            onChange={formik.handleChange}
          />
        </Grid>
      </Grid>

      <Box display="flex" justifyContent="end" gap={1.5}>
        {onCancel ? (
          mode === 'update_from_info' ? (
            <Button onClick={() => onCancel()} startIcon={<ArrowBackIcon />}>
              Back
            </Button>
          ) : (
            <Button onClick={() => onCancel()}>Cancel</Button>
          )
        ) : null}
        <Box>
          <LoadingButton
            variant="contained"
            type="submit"
            loading={submittedInProgress}
          >
            Update
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};

export default AMSEModuleNodeItemUpdate;
